const historiesVectorY = ['100', '1000', '10 000', '50 000', '200 000', '1 000 000'];
const historiesVectorX = ['Сырой камень', 'Отбор камней', 'Огранка', 'Отлежка', 'Реализация'];

const historiesList = [
  {
    vectorYvalue: historiesVectorY[1],
    tooltipText:
      'В своей работе мы вынуждены мириться с тем, что на месторождениях готовы продавать только партии сырья',
  },
  {
    vectorYvalue: historiesVectorY[0],
    tooltipText: 'Большой объем сырых камней приходится банально «выбрасывать»',
  },
  {
    vectorYvalue: historiesVectorY[2],
    tooltipText:
      'Из отобранного «сырья» создаются полноценные активы, которые имеют не только инвестиционную, но и ювелирную ценность',
  },
  {
    vectorYvalue: historiesVectorY[3],
    tooltipText:
      'После огранки мы не спешим продавать камень, а ожидаем идеальный момент для наиболее выгодной реализации.',
  },
  {
    vectorYvalue: historiesVectorY[4],
    tooltipText:
      'Драгоценный камень, который был отобран и прошел полный жизненный цикл Gemline, дает доходность от х100 и выше',
  },
];

historiesVectorY.reverse();

export { historiesVectorY, historiesVectorX, historiesList };

import { FC } from 'react';
import styled from '@emotion/styled';
// import { css } from 'styled-components';
import { Box, Button } from '@mui/material';
import { colorFetch, media, theme } from '@styles/index';
import { getPath, View } from '@routes/index';
import { useNavigate } from 'react-router-dom';

const ContentWrapper = styled(Box)`
  &.home_page {
    .introWrapper {
      margin-top: 8px;
    }
    .imageWrapper {
      .image {
        margin: 48px 0 0;

        ${media.tablet} {
          margin: 0 auto;
        }
      }
    }
  }
  &.about_us {
    .introWrapper {
      margin-top: -5px;
    }
    .imageWrapper {
      max-width: 430px;
    }
  }

  &.partners {
    .content {
      margin-top: -12px;
    }
    .imageWrapper {
      flex: 0 0 367px;
      margin: 0 52px -20px;
      align-self: flex-end;

      ${media.tablet} {
        margin: -76px auto 0;

        .imageInner {
          &:after {
            position: absolute;
            content: '';
            display: block;
            width: 100%;
            height: 200px;
            background: linear-gradient(180deg, transparent 0%, ${colorFetch('graybg')} 100%);
            left: 0;
            bottom: 0;
          }
        }
      }

      .image {
        margin: 0;

        ${media.tablet} {
          margin: 0 auto;
        }
      }
    }
  }

  ${media.tablet} {
    display: flex;
    min-height: calc(100vh - 76px);
    margin-top: 0;
  }
`;

const ImageWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  max-width: 400px;
  width: 100%;

  ${media.laptop} {
    flex: 0 1 auto;
  }

  ${media.tablet} {
    height: 100%;
    align-items: center;
  }
`;

const ImageInner = styled.div`
  position: relative;
`;

const Image = styled.img<{
  theme?: string;
}>`
  display: block;
  width: 100%;
  margin: 0;

  ${media.tablet} {
    position: relative;
    max-width: 270px;
    margin: 60px 0;
  }
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  max-width: 1220px;
  width: 100%;
  padding: 20px;
  margin: 0 auto;

  ${media.tablet} {
    padding-top: 0;
  }
`;

const IntroWrapper = styled.div<{
  theme?: string;
}>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 0 30px;
  height: 580px;

  ${media.tablet} {
    flex-direction: column-reverse;
    height: 100%;
    margin-top: 0;
    gap: 20px 0;
  }
`;

const Content = styled.div<{
  theme?: string;
}>`
  max-width: 780px;
  width: 100%;
  /* height: 50%; */
  margin-top: 0;

  ${media.tablet} {
    display: flex;
    height: fit-content;
    flex-direction: column;
    margin-top: 0;
  }
`;

const Title = styled.h1<{
  theme?: string;
}>`
  position: relative;
  max-width: 780px;
  width: 100%;
  color: ${colorFetch('altblack')};
  font-family: Gilroy600, sans-serif;
  font-size: 64px;
  line-height: 110%;
  letter-spacing: -1.28px;
  z-index: 2;

  span {
    display: block;
  }

  ${media.laptop} {
    font-size: 36px;
    line-height: 120%;
    letter-spacing: -0.72px;
  }

  ${media.tablet} {
    margin-top: auto;
  }
`;
const Description = styled.div`
  max-width: 736px;
  margin-top: 40px;
  color: ${colorFetch('gray')};
  font-family: Gilroy600, sans-serif;
  font-size: 24px;
  line-height: 130%;

  span {
    display: block;
  }

  ${media.laptop} {
    font-size: 16px;
  }

  ${media.tablet} {
    font-family: Gilroy500, sans-serif;
    margin-top: 20px;
    line-height: 140%;
  }
`;

const StyledButton = styled(Button)<{
  backgroundColor?: string;
}>`
  font-family: Gilroy600, sans-serif;
  font-size: 20px;
  line-height: 120%;
  color: white;
  letter-spacing: normal;
  text-transform: none;
  background: ${colorFetch('green')};
  border-radius: 16px;
  padding: 24px 40px;
  margin-top: 50px;

  ${media.tablet} {
    margin-top: 20px;
  }

  ${media.phone} {
    width: 100%;
  }

  &:hover {
    background: ${colorFetch('green')};
    color: ${colorFetch('white')};
  }
`;

interface IntroProps {
  className?: string;
  title: string;
  description: string;
  boxHeight?: string;
  theme?: string;
  image: string;
  imagePosition?: string;
}

export const Intro: FC<IntroProps> = ({ title, description, theme, boxHeight, image, ...props }) => {
  const navigate = useNavigate();
  return (
    <ContentWrapper {...props}>
      <Container>
        <IntroWrapper theme={theme} className="introWrapper">
          <Content theme={theme} className="content">
            <Title theme={theme} dangerouslySetInnerHTML={{ __html: title }} />
            <Description dangerouslySetInnerHTML={{ __html: description }} />
            <StyledButton onClick={() => navigate(getPath(View.AUTHENTICATION_REGISTRATION))}>
              Начать инвестировать
            </StyledButton>
          </Content>
          <ImageWrapper theme={theme} className="imageWrapper">
            <ImageInner className="imageInner">
              <Image src={image} theme={theme} className="image" />
            </ImageInner>
          </ImageWrapper>
        </IntroWrapper>
      </Container>
    </ContentWrapper>
  );
};

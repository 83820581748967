import { FC } from 'react';
import styled from '@emotion/styled';
import { getPath, View } from '@routes/index';
import { useNavigate } from 'react-router-dom';
import { Box, Button } from '@mui/material';
import { colorFetch, media, useMediaType } from '@styles/index';
import { NumberedGrid } from '@components/numberedGrid/NumberedGrid';

const ContentWrapper = styled(Box)`
  width: 100%;
  max-width: 1220px;
  margin: 40px auto 0;

  &.collateral {
    .contentBox {
      flex-direction: row;
    }
    .image {
      max-width: 210px;
      margin: 0 auto;
    }
    .textBottomWrapper {
      max-width: 850px;

      .text {
        max-width: unset;

        &:first-of-type {
          color: ${colorFetch('black')};
          font-family: Gilroy600, sans-serif;
          font-size: 20px;
          line-height: 130%;
          letter-spacing: -0.4px;
          margin-top: 32px;
        }
        &:last-of-type {
          color: ${colorFetch('gray2')};
          font-family: Gilroy500, sans-serif;
          font-size: 18px;
          line-height: 150%;
          margin-top: 20px;
        }
      }
    }

    ${media.tablet} {
      .image {
        display: none;
      }
      .textBottomWrapper {
        .text {
          max-width: unset;

          &:first-of-type {
            font-size: 18px;
            letter-spacing: -0.36px;
            margin-top: 20px;
          }
          &:last-of-type {
            font-size: 15px;
          }
        }
      }
    }
  }
  &.guaranteedIncome {
    padding-bottom: 100px;

    .text {
      margin-top: 40px;

      > p:not(:last-of-type) {
        margin-bottom: 15px;
      }
    }

    ${media.tablet} {
      padding-bottom: 60px;
      .text {
        font-size: 15px;
        margin-top: 32px;
      }
    }
  }

  ${media.tablet} {
    margin: 30px auto 0;
  }
`;

const Container = styled.div`
  width: 100%;
  margin: 0 auto;
  background: ${colorFetch('white')};
  padding: 40px;
  border-radius: 30px;

  ${media.tablet} {
    padding: 20px;
  }
`;

const Image = styled.img`
  width: 100%;
  max-width: 340px;

  ${media.tablet} {
    margin-top: 20px;
  }
`;

const Text = styled.div`
  color: ${colorFetch('gray2')};
  font-family: Gilroy500, sans-serif;
  font-size: 18px;
  line-height: 150%;

  ${media.tablet} {
    font-size: 15px;
  }
`;

const TextsBottomWrapper = styled.div`
  .text {
    &:first-of-type {
      color: ${colorFetch('gray2')};
      font-family: Gilroy500, sans-serif;
      font-size: 18px;
      line-height: 150%;
      margin-top: 24px;
    }

    &:last-of-type {
      max-width: 640px;
      color: ${colorFetch('black')};
      font-family: Gilroy600, sans-serif;
      font-size: 20px;
      line-height: 130%;
      letter-spacing: -0.4px;
      margin-top: 40px;

      > span {
        background-color: ${colorFetch('green')};
        color: ${colorFetch('white')};
        padding: 2px 5px;
        border-radius: 5px;
      }
    }
  }

  ${media.tablet} {
    .text {
      &:first-of-type {
        font-size: 15px;
        margin-top: 20px;
      }

      &:last-of-type {
        font-size: 18px;
        margin-top: 20px;
      }
    }
  }
`;

const Subject = styled.div`
  color: ${colorFetch('green')};
  font-family: Gilroy600, sans-serif;
  font-size: 20px;
  line-height: 140%;
  letter-spacing: -0.4px;
  margin-bottom: 24px;

  ${media.tablet} {
    font-size: 18px;
    letter-spacing: -0.36px;
    margin-bottom: 20px;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 32px;
  margin-top: 40px;

  .buttonText {
    color: ${colorFetch('black')};
    font-family: Gilroy600, sans-serif;
    font-size: 20px;
    line-height: 130%;
    letter-spacing: -0.4px;
  }

  ${media.tablet} {
    flex-direction: column;
    margin-top: 20px;
    gap: 16px;

    .buttonText {
      font-size: 18px;
      letter-spacing: -0.36px;
    }
    .button {
      padding: 15px;
    }
  }
`;

const ButtonStyled = styled(Button)`
  max-width: 157px;
  width: 100%;
  min-width: 340px;
  display: flex;
  font-family: Gilroy600, sans-serif;
  font-size: 20px;
  line-height: 120%;
  color: ${colorFetch('white')};
  letter-spacing: normal;
  text-transform: none;
  background: ${colorFetch('green')};
  border-radius: 16px;
  padding: 25px 15px;

  &:hover {
    background: ${colorFetch('green_hover')};
  }

  ${media.laptop} {
    max-width: 150px;
    font-size: 16px;
  }

  ${media.tablet} {
    max-width: unset;
    min-width: unset;
    border-radius: 8px;
    background: ${colorFetch('green')};
    color: ${colorFetch('white')};
  }
`;

const Title = styled.h2<{
  maxtitlewidth?: string;
}>`
  max-width: ${({ maxtitlewidth }) => (maxtitlewidth ? maxtitlewidth : 'unset')};
  color: ${colorFetch('black')};
  font-family: Gilroy600, sans-serif;
  font-size: 32px;
  line-height: 120%;
  letter-spacing: -0.64px;

  ${media.tablet} {
    font-size: 24px;
    letter-spacing: -0.48px;
  }
`;

const TextBold = styled.div`
  color: ${colorFetch('black')};
  font-family: Gilroy600, sans-serif;
  font-size: 32px;
  line-height: 120%;
  letter-spacing: -0.64px;
  margin-top: 32px;

  ${media.tablet} {
    font-size: 24px;
    letter-spacing: -0.48px;
  }
`;

const ListTitle = styled.div`
  margin-top: auto;
  color: ${colorFetch('black')};
  font-family: Gilroy600, sans-serif;
  font-size: 20px;
  line-height: 130%;
  letter-spacing: -0.4px;
  margin-bottom: 24px;

  ${media.laptop} {
    margin: 30px 0 0;
  }
`;

const ContentBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;

  ${media.laptop} {
    flex-direction: column;
  }
`;

const Description = styled.div`
  display: flex;
  flex-direction: column;
`;

const NumberedGridStyled = styled(NumberedGrid)`
  margin-top: 16px;

  ${media.laptop} {
    margin-top: 30px;
  }
`;

interface DataListType {
  icon?: string;
  title?: string;
  text?: string;
}

interface DataButtonType {
  text?: string;
  label?: string;
  url: string;
}

interface DataType {
  image?: string;
  subject?: string;
  title?: string;
  text?: string;
  button?: DataButtonType;
  texts?: string[];
  textBold?: string;
  listTitle?: string;
  list: DataListType[];
  bottomTexts?: string[];
}

interface PropsType {
  className?: string;
  maxtitlewidth?: string;
  data: DataType;
}

export const BusinessModel: FC<PropsType> = ({ data, className, maxtitlewidth, ...props }) => {
  const { tablet: isTablet } = useMediaType();
  const navigate = useNavigate();

  return (
    <ContentWrapper className={className} {...props}>
      <Container>
        <ContentBox className="contentBox">
          <Description>
            {data.subject && <Subject>{data.subject}</Subject>}
            {data.title && <Title maxtitlewidth={maxtitlewidth}>{data.title}</Title>}
            {data.button && (
              <ButtonWrapper>
                <Text className="buttonText">{data.button.text}</Text>
                <ButtonStyled onClick={() => navigate(getPath(View['INVESTORS']))} className="button">
                  {data.button.label}
                </ButtonStyled>
              </ButtonWrapper>
            )}
            {data.text && <Text className="text" dangerouslySetInnerHTML={{ __html: data.text }} />}
            {data.textBold && <TextBold>{data.textBold}</TextBold>}
            {isTablet && data.image && <Image src={data.image} className="image" />}
            {data.listTitle && <ListTitle>{data.listTitle}</ListTitle>}
          </Description>
          {!isTablet && data.image && <Image src={data.image} className="image" />}
        </ContentBox>
        <NumberedGridStyled list={data.list} className={className} />
        {data.bottomTexts && (
          <TextsBottomWrapper className="textBottomWrapper">
            {data.bottomTexts.map((el, i) => {
              return <Text key={i} className="text" dangerouslySetInnerHTML={{ __html: el }} />;
            })}
          </TextsBottomWrapper>
        )}
      </Container>
    </ContentWrapper>
  );
};

import styled from 'styled-components';
import { Box, Button } from '@mui/material';

import { styled as muiStyled } from '@mui/system';
import { colorFetch, media } from '@styles/index';

import { ReactComponent as BackgroundDekor } from '@assets/images/cta-line/background-dekor.svg';
import gemDekor from '@assets/images/cta-line/gem-dekor.png';
import gemDekorRed from '@assets/images/cta-line/gem-dekor-red.png';

const Content = styled.div`
  padding: 40px 0;
  z-index: 2;

  ${media.tablet} {
    padding: 0;
  }
`;

const LineWrapper = styled.div<{
  theme?: string;
}>`
  position: relative;
  width: 100%;

  ${({ theme }) =>
    theme === 'centeredRed' &&
    `
      ${Content}{
        max-width: 424px;
        ${media.tablet} {
          max-width: 300px;

          ${Title}, ${Description}{
            max-width: unset;
          }
        }
      }
    `}

  &:after {
    position: absolute;
    color: ${({ theme }) => theme};
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: ${colorFetch('green')};
    ${({ theme }) => (theme === 'red' || theme === 'centeredRed') && `background-color: ${colorFetch('red')};`}
    ${({ theme }) =>
      theme === 'blue' &&
      `
      background-color: ${colorFetch('blue')};
    `};
  }
`;

const ContentWrapper = styled(Box)<{
  containerwrapper?: boolean;
}>`
  ${({ containerwrapper }) =>
    containerwrapper
      ? `
      max-width: 1180px;
      margin: 115px auto 0;
      border-radius: 24px;
      margin-top: 150px;

        ${LineWrapper} {
          &:after {
            border-radius: 24px;
          }
        }
    `
      : `
      max-width: 100%;
      margin: 115px 0 0;
      border-radius: 0;
      margin-top: 115px;
    `}

  ${media.tablet} {
    margin-top: 50px;
    ${LineWrapper} {
      &:after {
        border-radius: 0;
      }
    }
  }
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin: 0 auto;
  ${media.laptop} {
    padding-top: 30px;
    overflow: hidden;
  }
`;

const ButtonWrapper = styled.div`
  z-index: 2;

  ${media.laptop} {
    margin: 35px auto 0;
  }

  ${media.tablet} {
    margin: 30px auto 0;
  }

  ${media.phone} {
    width: 100%;
  }
`;

const CtaTitle = styled.div`
  color: ${colorFetch('white')};
  font-family: Gilroy600, sans-serif;
  font-size: 24px;
  line-height: 150%;
  margin-bottom: 16px;
  text-align: center;

  ${media.tablet} {
    font-size: 18px;
  }
`;

const StyledButton = muiStyled(Button)<{
  backgroundColor?: string;
  textcolor: string;
}>`
  min-width: 280px;
  font-family: Gilroy600, sans-serif;
  font-size: 20px;
  line-height: 120%;
  color: ${({ textcolor }) => colorFetch(textcolor)};
  letter-spacing: normal;
  text-transform: none;
  background: ${colorFetch('white')};
  border-radius: 16px;
  padding: 24px 40px;

  &:hover {
    background: ${colorFetch('white')};
  }

  ${media.laptop} {
    padding: 24px;
  }

  ${media.phone} {
    width: 100%;
  }
`;

const Line = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 1260px;
  margin: 0 auto;
  padding: 0 40px;

  ${media.laptop} {
    gap: 40px;
  }

  ${media.tablet} {
    flex-direction: column;
    align-items: flex-start;
    padding: 30px 20px;
    gap: 0;
  }
`;

const Title = styled.h3`
  max-width: 531px;
  color: ${colorFetch('white')};
  font-family: Gilroy600, sans-serif;
  font-size: 32px;
  line-height: 130%;
  letter-spacing: -0.64px;

  ${media.tablet} {
    font-size: 24px;
    letter-spacing: -0.48px;
  }
`;

const Description = styled.div<{
  theme?: string;
}>`
  max-width: 424px;
  color: ${colorFetch('white')};
  font-family: Gilroy500, sans-serif;
  font-size: 18px;
  line-height: 150%;
  margin-top: 24px;

  ${media.tablet} {
    font-family: Gilroy500, sans-serif;
    font-size: 15px;
    margin-top: 20px;
    /* max-width: ${({ theme }) => (theme === 'blue' ? 'unset' : '210px')}; */
  }
`;

const GemDekorStyled = styled.img`
  max-width: 249px;
  margin-top: -28px;
  z-index: 2;

  ${media.laptop} {
    position: absolute;
    right: -57px;
    top: 12px;
    max-width: 200px;
  }
`;

const GemDekorRedStyled = styled.img`
  max-width: 282px;
  margin-top: -47px;
  z-index: 2;

  ${media.laptop} {
    position: absolute;
    right: -57px;
    top: 12px;
    max-width: 200px;
  }
`;

const GemDekorBlueStyled = styled.img`
  max-width: 282px;
  margin-top: -40px;
  z-index: 2;

  ${media.laptop} {
    position: absolute;
    right: -57px;
    top: 12px;
    max-width: 200px;
  }

  ${media.tablet} {
    height: 157px;
    width: 166px;
    transform: rotate(5deg) scale(-1, 1);
    right: -30px;
    top: 26px;
  }
`;

const GemDekorRedCentered = styled.img`
  margin: -30px auto 0 7px;
  z-index: 2;

  ${media.laptop} {
    position: absolute;
    right: -57px;
    top: 12px;
    max-width: 200px;
  }
`;

const BackgroundDekorStyled = styled(BackgroundDekor)`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;

  ${media.phone} {
    display: none;
  }
`;

interface ChildComponentProps {
  themeMod?: string;
  containerwrapper?: boolean;
  image?: string;
  title: string;
  description?: string;
  ctaTitle?: string;
  buttonLabel: string;
  onClick: () => void;
}

export const CallToActionLine: React.FC<ChildComponentProps> = ({
  themeMod,
  containerwrapper,
  image,
  title,
  description,
  ctaTitle,
  buttonLabel,
  onClick,
  ...props
}) => {
  return (
    <ContentWrapper containerwrapper={containerwrapper} {...props}>
      <Container>
        <LineWrapper theme={themeMod}>
          <Line>
            <BackgroundDekorStyled />
            <Content>
              <Title dangerouslySetInnerHTML={{ __html: title }} />
              {description && <Description theme={themeMod}>{description}</Description>}
            </Content>
            {!themeMod && <GemDekorStyled src={image ? image : gemDekor} />}
            {themeMod === 'red' && <GemDekorRedStyled src={image ? image : gemDekorRed} />}
            {themeMod === 'blue' && <GemDekorBlueStyled src={image ? image : gemDekorRed} />}
            {themeMod === 'centeredRed' && <GemDekorRedCentered src={image ? image : gemDekorRed} />}
            <ButtonWrapper>
              {ctaTitle && <CtaTitle>{ctaTitle}</CtaTitle>}
              <StyledButton
                textcolor={themeMod === 'centeredRed' ? 'red' : themeMod ? themeMod : 'green'}
                onClick={onClick}
              >
                {buttonLabel}
              </StyledButton>
            </ButtonWrapper>
          </Line>
        </LineWrapper>
      </Container>
    </ContentWrapper>
  );
};

import axios from 'axios';

export enum ApiCallType {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  PATCH = 'PATCH',
  DELETE = 'DELETE',
}

export class HttpService {
  static get(url: string, options?: any) {
    return HttpService.makeRequest({
      url,
      method: 'get',
      ...options,
    });
  }

  static post(url: string, data: any, options?: any) {
    return HttpService.makeRequest({
      url,
      method: 'post',
      data,
      ...options,
    });
  }

  static put(url: string, data: any, options?: any) {
    return HttpService.makeRequest({
      url,
      method: 'put',
      data,
      ...options,
    });
  }

  static patch(url: string, data: any, options?: any) {
    return HttpService.makeRequest({
      url,
      method: 'patch',
      data,
      ...options,
    });
  }

  static delete(url: string, data: any, options?: any) {
    return HttpService.makeRequest({
      url,
      method: 'delete',
      data,
      ...options,
    });
  }

  static makeRequest(config: any) {
    return axios
      .request({ ...config })
      .then((res) => {
        return res;
      })
      .catch((er) => {
        throw er;
      });
  }
}

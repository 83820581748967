import { FC, HTMLAttributes } from 'react';
import styled from 'styled-components';
import { Box } from '@mui/material';
import { colorFetch, media, useMediaType } from '@styles/index';

import offerImg from '@assets/images/offer/offer.png';

const OfferContentWrapper = styled(Box)`
  position: relative;
  margin-top: 80px;

  &.aboutProject {
    .title {
      font-size: 48px;
      letter-spacing: -0.96px;
    }
    .description {
      color: ${colorFetch('gray2')};
    }
    .excerpt {
      font-size: 24px;
      letter-spacing: -0.48px;
    }
    .imageWrapper {
      .image {
        max-width: 220px !important;
      }
    }
    ${media.tablet} {
      .title {
        font-size: 32px;
        letter-spacing: -0.64px;
      }
      .excerpt {
        font-size: 18px;
        line-height: 120%;
        letter-spacing: -0.36px;
      }
    }
  }

  ${media.laptop} {
    .excerpt {
      max-width: unset;
    }
  }
`;

const Container = styled.div`
  max-width: 1180px;
  width: 100%;
  margin: 0 auto;
`;

const Image = styled.img`
  flex: 0;
  max-width: 500px;
  width: 100%;

  ${media.laptop} {
    max-width: 108px;
    width: 100%;
  }
`;

const Content = styled.div<{
  contentmaxwidth?: string;
  contentgap?: string;
}>`
  display: flex;
  flex-direction: column;
  gap: ${({ contentgap }) => (contentgap ? contentgap : '32px')};
  position: relative;
  width: 100%;
  z-index: 1;
  max-width: ${({ contentmaxwidth }) => (contentmaxwidth ? contentmaxwidth : 'unset')};

  ${media.laptop} {
    max-width: 100%;
    gap: 20px 0;
  }
`;

const ImageWrapper = styled.div<{
  theme?: string;
  centeredimage?: boolean;
  imagekeepbottom?: boolean;
}>`
  position: relative;
  display: flex;
  align-items: ${({ imagekeepbottom }) => (imagekeepbottom ? 'flex-end' : 'center')};
  justify-content: ${({ centeredimage }) => (centeredimage ? 'center' : 'flex-end')};
  width: calc(100% - 600px);
  margin-bottom: ${({ imagekeepbottom }) => (imagekeepbottom ? '-40px' : '0')};

  ${media.laptop} {
    max-width: 100%;
    margin-bottom: ${({ imagekeepbottom }) => (imagekeepbottom ? '-30px' : '0')};
  }

  ${media.tablet} {
    position: absolute;
    width: 114px;
    height: 136px;
    right: -20px;
    bottom: 0;
    margin: 0;
  }

  ${({ theme }) =>
    theme === 'simple' &&
    `
      ${media.tablet} {
        position: static;
        order: 2;
      }
    `}
`;

const OfferBoxWrapper = styled.div<{
  rowreverse: boolean;
  theme?: string;
}>`
  display: flex;
  justify-content: space-between;
  flex-direction: ${({ rowreverse }) => (rowreverse ? 'row-reverse' : 'row')};
  gap: 0 10px;
  border-radius: 24px;
  background: ${colorFetch('graybg')};
  flex: 1 1 100%;
  padding: 40px;
  min-width: 380px;

  ${media.laptop} {
    flex-direction: column;
  }

  ${media.tablet} {
    position: relative;
    padding: 30px 20px;
    min-width: unset;
    overflow: hidden;
  }

  ${({ rowreverse }) =>
    rowreverse &&
    `
    ${ImageWrapper}{
      flex-direction: ${rowreverse ? 'row-reverse' : 'row'};
    }
  `}

  ${({ theme }) =>
    theme === 'simple' &&
    `
    align-items: center;

    ${Content}{
      gap: 32px 0;

      >*{
        margin: 0;
      }

      > ${ImageWrapper} {
        display: none;
      }
    }
    ${media.laptop} {
      padding: 20px;

      > ${ImageWrapper} {
        display: none;
      }
      ${Content} {
        gap: 20px 0;

        ${Title}{
          order: 1;
        }
        ${ImageWrapper} {
          display: block;
          width: 100%;
          height: auto;
          order: 2;

          ${Image}{
            max-width: unset;
          }
        }
        ${Description}{
          order: 3;
        }
        ${Excerpt}{
          max-width: unset;
          order: 4;
        }
      }
    }
  `}
`;

const Title = styled.h3<{
  marginTop?: number;
}>`
  color: ${colorFetch('altblack')};
  font-family: Gilroy600, sans-serif;
  font-size: 32px;
  line-height: 120%;
  letter-spacing: -0.64px;
  margin-top: ${({ marginTop }) => (marginTop ? `${marginTop}px` : '0px')};

  > span {
    display: inline-block;
    background-color: ${colorFetch('green')};
    color: white;
    line-height: 1;
    padding: 2px 4px;
    border-radius: 5px;
  }

  ${media.laptop} {
    max-width: 500px;
  }

  ${media.tablet} {
    color: #35343d;
    font-family: Gilroy600, sans-serif;
    font-size: 24px;
    line-height: 120%;
    letter-spacing: -0.48px;
    margin: 0;

    > span {
      display: inline-block;
    }
  }
`;

const Description = styled.div`
  width: 100%;
  color: ${colorFetch('dark_gray')};
  font-family: Gilroy500, sans-serif;
  font-size: 18px;
  line-height: 150%;

  > p {
    margin-bottom: 20px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  ${media.tablet} {
    font-family: Gilroy500, sans-serif;
    font-size: 15px;
  }
`;

const Excerpt = styled.div`
  color: ${colorFetch('altblack')};
  font-family: Gilroy600, sans-serif;
  font-size: 18px;
  line-height: 120%;
  letter-spacing: -0.4px;

  span:first-of-type {
    display: block;
  }

  span:last-of-type {
    display: inline-block;
    background-color: ${colorFetch('green')};
    color: white;
    line-height: 1;
    padding: 2px 4px;
    border-radius: 5px;
  }

  ul {
    display: flex;
    gap: 0 10px;
    list-style: none;

    li {
      display: flex;
      gap: 10px;
      flex: 1;

      &:first-of-type {
        flex: 0 1 44%;
      }

      &:before {
        content: '';
        aspect-ratio: 1;
        display: block;
        width: 9px;
        height: 9px;
        background-color: ${colorFetch('green')};
        transform: translateY(8px) rotate(45deg);
      }
    }
  }

  ${media.tablet} {
    font-family: Gilroy600, sans-serif;
    font-size: 15px;
    max-width: 220px;

    ul {
      flex-direction: column;
      gap: 12px 0;

      li,
      li:first-of-type {
        flex: 1;
      }
    }
  }
`;

interface Data extends HTMLAttributes<HTMLDivElement> {
  offerTheme?: string;
  rowreverse?: boolean;
  contentmaxwidth?: string;
  centeredimage?: boolean;
  imagekeepbottom?: boolean;
  contentgap?: string;
  image?: string;
  mobileImage?: string;
  title: string;
  description: string;
  excerpt?: string;
}

export const OfferBox: FC<Data> = ({
  offerTheme,
  rowreverse,
  contentmaxwidth,
  centeredimage,
  imagekeepbottom,
  contentgap,
  image,
  mobileImage,
  title,
  description,
  excerpt,
  ...props
}) => {
  const { tablet: isTablet } = useMediaType();

  return (
    <OfferContentWrapper {...props}>
      <Container>
        <OfferBoxWrapper theme={offerTheme} rowreverse={!!rowreverse} className="offerBoxWrapper">
          <Content contentmaxwidth={contentmaxwidth} contentgap={contentgap} className="content">
            <Title dangerouslySetInnerHTML={{ __html: title }} className="title" />
            {offerTheme === 'simple' && (
              <ImageWrapper
                theme={offerTheme}
                centeredimage={!!centeredimage}
                imagekeepbottom={!!imagekeepbottom}
                className="imageWrapper"
              >
                {mobileImage && isTablet ? (
                  <Image src={mobileImage} className="image" />
                ) : (
                  <Image src={image ? image : offerImg} className="image" />
                )}
              </ImageWrapper>
            )}
            <Description dangerouslySetInnerHTML={{ __html: description }} className="description" />
            {excerpt && <Excerpt dangerouslySetInnerHTML={{ __html: excerpt }} className="excerpt" />}
          </Content>
          <ImageWrapper theme={offerTheme} centeredimage={!!centeredimage} imagekeepbottom={!!imagekeepbottom}>
            <Image src={image ? image : offerImg} className="image" />
          </ImageWrapper>
        </OfferBoxWrapper>
      </Container>
    </OfferContentWrapper>
  );
};

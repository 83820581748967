export const UNIQUE_PREFIX = (window as any)._env_
  ? (window as any)._env_.REACT_APP_UNIQUE_PREFIX
  : process.env.REACT_APP_UNIQUE_PREFIX || 'gemline-';

export const API_URL = (window as any)._env_
  ? (window as any)._env_.REACT_APP_API_URL
  : process.env.REACT_APP_API_URL || 'https://svc.gemline.pp.ua';

export const PLATFORM_URL = (window as any)._env_
  ? (window as any)._env_.REACT_APP_PLATFORM_URL
  : process.env.REACT_APP_PLATFORM_URL || 'https://app.gemline.pp.ua';

export const DOMAIN_URL = (window as any)._env_
  ? (window as any)._env_.REACT_APP_DOMAIN_URL
  : process.env.REACT_APP_DOMAIN_URL || '.gemline.pp.ua';

import { FC } from 'react';
import styled from '@emotion/styled';
import { StyledModal } from '../StyledModal';

const VideoModalStyled = styled(StyledModal)`
  .modalContentWrapper {
    display: flex;
    align-items: flex-end;
    width: 100%;
    max-width: 860px;
    gap: 10px;
    background-color: unset;
    border-radius: 0;
    padding: 0 20px;

    .modalContentBox {
      display: -webkit-box;
      padding: 0;
      overflow: hidden;
    }
  }

  .videoFrame {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

const Video = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

interface PropsType {
  isModalOpen: boolean;
  onClose: () => void;
  videoSource: string;
}

export const VideoModal: FC<PropsType> = ({ isModalOpen, onClose, videoSource }) => {
  return (
    <VideoModalStyled open={isModalOpen} onClose={onClose} closeBtnOut>
      <Video controls autoPlay>
        <source src={videoSource} type="video/mp4" />
        Your browser does not support the video tag.
      </Video>
    </VideoModalStyled>
  );
};

import React from 'react';
import styled from '@emotion/styled';
import { Box } from '@mui/material';
import { colorFetch, media, useMediaType } from '@styles/index';
import { investingBenefitsList, ourBenefitsList, joinUsList } from './data';

import gemDekor from '@assets/images/benefits/gem_dekor.png';
import gemDekorRed from '@assets/images/benefits/gem_dekor_red.png';
import gemDekorRedMobile from '@assets/images/benefits/gem_dekor_red_mobile.png';

const ContentWrapper = styled(Box)`
  margin-top: 113px;

  ${media.laptop} {
    margin-top: 45px;
    padding: 35px 20px 0;
    overflow: hidden;
  }
`;

const Container = styled.div`
  position: relative;
  max-width: 1180px;
  width: 100%;
  margin: 0 auto;
  border-radius: 30px;
  background: ${colorFetch('graybg')};
  box-shadow: 0px -2px 4px 0px ${colorFetch('greyShadow')} inset;
  padding: 40px;

  ${media.laptop} {
    padding: 30px 20px;
  }
`;

const Title = styled.h3`
  width: 100%;
  max-width: 380px;
  color: ${colorFetch('black')};
  font-family: Gilroy600, sans-serif;
  font-size: 48px;
  line-height: 120%;
  letter-spacing: -0.96px;

  ${media.laptop} {
    font-size: 32px;
    letter-spacing: -0.64px;
  }

  ${media.tablet} {
    max-width: 230px;
  }
`;

const Description = styled.div`
  color: ${colorFetch('gray2')};
  font-family: Gilroy500, sans-serif;
  font-size: 18px;
  line-height: 150%;
  margin-top: 20px;
`;

const GemDekorStyled = styled.img`
  position: absolute;
  max-width: 495px;
  width: 100%;
  top: -54px;
  right: 0;

  ${media.laptop} {
    max-width: 340px;
  }

  ${media.tablet} {
    max-width: 195px;
    transform: rotate(53deg);
    top: -15px;
    right: -86px;
  }
`;

const GemDekorRedStyled = styled.img`
  position: absolute;
  max-width: 414px;
  width: 100%;
  top: -54px;
  right: 95px;

  ${media.laptop} {
    max-width: 340px;
    right: 50px;
  }

  ${media.tablet} {
    max-width: 256px;
    transform: rotate(362deg);
    top: -86px;
    right: -89px;
  }
`;

const TilesWrapper = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  margin-top: 57px;

  ${media.tablet} {
    gap: 0;
    margin-top: 30px;
  }
`;

const Tile = styled.div`
  border-radius: 16px;
  background-color: #fafafb;
  flex: 1 1 calc(100% / 3 - 16px * 2 / 3);
  padding: 23px;
  min-height: 190px;

  ${media.laptop} {
    display: flex;
    gap: 20px;
    min-width: 356px;
    background-color: unset;
    padding: 0;
    min-height: unset;
  }

  ${media.tablet} {
    padding: 16px 0;
    border-bottom: 1px solid ${colorFetch('white')};
    flex: 1 1 100%;

    &:first-of-type {
      padding-top: 0;
    }

    &:last-of-type {
      border: none;
      padding-bottom: 0;
    }
  }

  ${media.phone} {
    min-width: unset;
  }
`;

const IconWrapper = styled.div``;

const TileDescription = styled.div`
  margin-top: 21px;
  color: ${colorFetch('dark_gray')};
  font-family: Gilroy500, sans-serif;
  font-size: 18px;
  line-height: 150%;

  ${media.tablet} {
    margin: 0;
    font-size: 15px;
  }
`;

interface ChildComponentProps {
  type?: string;
  title: string;
  description?: string;
}

export const Benefits: React.FC<ChildComponentProps> = ({ type, title, description, ...props }) => {
  const { tablet: isTablet, phone: isPhone } = useMediaType();
  return (
    <ContentWrapper {...props}>
      <Container>
        <Title className="title">{title}</Title>
        {description && <Description className="description">{description}</Description>}
        {type === 'out-benefits' ? (
          <GemDekorRedStyled src={isTablet ? gemDekorRedMobile : gemDekorRed} />
        ) : (
          <GemDekorStyled src={gemDekor} />
        )}
        <TilesWrapper>
          {!type &&
            investingBenefitsList.map(({ Icon, description }, index: number) => {
              return (
                <Tile key={index}>
                  <IconWrapper>{React.createElement(Icon as React.FC)}</IconWrapper>
                  <TileDescription dangerouslySetInnerHTML={{ __html: description }} />
                </Tile>
              );
            })}
          {type === 'out-benefits' &&
            ourBenefitsList.map(({ Icon, description }, index: number) => {
              return (
                <Tile key={index}>
                  <IconWrapper>{React.createElement(Icon as React.FC)}</IconWrapper>
                  <TileDescription dangerouslySetInnerHTML={{ __html: description }} />
                </Tile>
              );
            })}
          {type === 'join-us' &&
            joinUsList.map(({ Icon, description }, index: number) => {
              return (
                <Tile key={index}>
                  <IconWrapper>{React.createElement(Icon as React.FC)}</IconWrapper>
                  <TileDescription dangerouslySetInnerHTML={{ __html: description }} />
                </Tile>
              );
            })}
        </TilesWrapper>
      </Container>
    </ContentWrapper>
  );
};

import { CallbackData, UseApiMutationOptions } from '@api/types';
import { useMutation } from '@tanstack/react-query';
import { HttpService } from '@utils/http';
import { API_URL } from 'src/consts';

interface LoginData {
  Login: string;
  Password: string;
}

export const useLogin = ({ onSuccess, onError }: UseApiMutationOptions) => {
  const { mutate } = useMutation<CallbackData, CallbackData, LoginData, unknown>({
    mutationFn: (data) => {
      return HttpService.post(`${API_URL}/login`, data);
    },
    onSuccess,
    onError,
  });

  return mutate;
};

interface ConfirmRegistrationData {
  ConfirmationHash: string;
}

export const useConfirmRegistration = ({ onSuccess, onError }: UseApiMutationOptions) => {
  const { mutate } = useMutation<CallbackData, CallbackData, ConfirmRegistrationData, unknown>({
    mutationFn: (data) => {
      return HttpService.post(`${API_URL}/confirm-registration`, data);
    },
    onSuccess,
    onError,
  });

  return mutate;
};

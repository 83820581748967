import { ReactNode, FC } from 'react';
import styled from '@emotion/styled';
import { Box } from '@mui/material';
import { colorFetch, media } from '@styles/index';

const ContentWrapper = styled(Box)`
  width: 100%;
  max-width: 1220px;
  padding: 0 20px;
  margin: 0 auto;

  &.smallTitle {
    .title {
      font-size: 32px;
      letter-spacing: -0.64px;
    }

    ${media.tablet} {
      .title {
        color: ${colorFetch('black')};
        font-size: 24px;
        letter-spacing: -0.48px;
      }
    }
  }

  &.white {
    .container {
      background-color: ${colorFetch('white')};
      padding: 40px;
      border-radius: 30px;
    }

    ${media.tablet} {
      .container {
        padding: 20px;
      }
    }
  }

  &.functionsSeparation {
    .container {
      padding: 40px;
      border-radius: 24px;
    }
    .description {
      max-width: 740px;
      color: ${colorFetch('black')};
      font-family: Gilroy600, sans-serif;
      font-size: 20px;
      line-height: 130%;
      letter-spacing: -0.4px;
    }
    ${media.tablet} {
      .container {
        padding: 20px;
      }
      .description {
        font-size: 18px;
        margin-top: 20px;
      }
    }
  }

  &.snippet {
    .container {
      height: 100%;
      padding: 40px;
      border-radius: 24px;
    }
    .title {
      color: ${colorFetch('black')};
      font-size: 32px;
      letter-spacing: -0.64px;
    }
    ${media.tablet} {
      .container {
        padding: 20px;
      }
      .title {
        font-size: 24px;
      }
    }
  }

  &.globalActivities {
    .title {
      max-width: 580px;
    }
    .description {
      max-width: 680px;
    }
    ${media.tablet} {
      margin-top: 40px;

      .card {
        padding: 20px;

        .imageWrapper {
          flex: 1;
          margin: -90px 0 0;
        }
      }

      .description {
        font-size: 15px;
      }
    }
  }
`;

const Container = styled.div<{
  backgroundColor?: string;
}>`
  background-color: ${({ backgroundColor }) => (backgroundColor ? colorFetch(backgroundColor) : 'unset')};
  width: 100%;
  margin: 0 auto;
`;

const Title = styled.h2<{
  maxtitlewidth?: string;
}>`
  max-width: ${({ maxtitlewidth }) => (maxtitlewidth ? maxtitlewidth : 'unset')};
  color: ${colorFetch('black')};
  font-family: Gilroy600, sans-serif;
  font-size: 48px;
  line-height: 120%;
  letter-spacing: -0.96px;

  ${media.tablet} {
    font-size: 32px;
    line-height: 120%;
    letter-spacing: -0.64px;
  }
`;

const Subject = styled.div`
  color: ${colorFetch('green')};
  font-family: Gilroy600, sans-serif;
  font-size: 20px;
  line-height: 140%;
  letter-spacing: -0.4px;
  margin-bottom: 24px;

  ${media.tablet} {
    font-size: 18px;
    letter-spacing: -0.36px;
    margin-bottom: 20px;
  }
`;

const Description = styled.div`
  color: ${colorFetch('dark_gray')};
  font-family: Gilroy500, sans-serif;
  font-size: 18px;
  line-height: 150%;
  margin-top: 32px;

  ${media.tablet} {
    font-size: 16px;
    margin-top: 16px;
  }
`;

const ChildrenContainer = styled.div``;

interface PropsType {
  className?: string;
  backgroundColor?: string;
  maxtitlewidth?: string;
  subject?: string;
  title?: string;
  text?: string;
  children: ReactNode;
}

export const BoxContainer: FC<PropsType> = ({
  subject,
  title,
  text,
  children,
  backgroundColor,
  maxtitlewidth,
  ...props
}) => {
  return (
    <ContentWrapper {...props}>
      <Container className="container" backgroundColor={backgroundColor}>
        {subject && <Subject>{subject}</Subject>}
        <Title maxtitlewidth={maxtitlewidth} className="title">
          {title}
        </Title>
        {text && <Description className="description">{text}</Description>}
        <ChildrenContainer>{children}</ChildrenContainer>
      </Container>
    </ContentWrapper>
  );
};

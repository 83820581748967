import React, { FC } from 'react';
import styled from '@emotion/styled';
import { Box } from '@mui/material';
import { colorFetch, media } from '@styles/index';

const ContentWrapper = styled(Box)`
  margin-top: 36px;

  &.allwhite {
    .box-item {
      background-color: ${colorFetch('white')};

      .num {
        color: ${colorFetch('green')};
      }
      .description {
        color: ${colorFetch('black')};
      }
    }
    &.lastGreen {
      .box-item:last-of-type {
        background-color: ${colorFetch('green')};

        .description {
          color: ${colorFetch('white')};
        }
      }
    }
  }

  &.controlAllStages {
    .box-item:last-of-type {
      .num {
        display: none;
      }
      .description {
        color: ${colorFetch('gray')};
        font-size: 18px;
        line-height: 150%;
        margin: 0;
      }
    }
  }

  &.futurePlans {
    margin-top: 40px;

    .box-item {
      padding: 32px;

      .description {
        font-size: 18px;
        line-height: 150%;
      }
    }
    .num {
      > span:first-of-type {
        opacity: 0.4;
      }
      > span:last-of-type {
        position: absolute;
        right: 17px;
        top: 33px;
        display: block;
        color: ${colorFetch('white')};
        font-family: Gilroy700, sans-serif;
        font-size: 120px;
        letter-spacing: -2.4px;
      }
    }

    ${media.tablet} {
      margin-top: 30px;

      .description {
        font-size: 18px;
        line-height: 140%;
        letter-spacing: -0.36px;
      }
      .box-item {
        padding: 20px;
      }
    }
  }

  &.businessModel {
    .boxesWrapper {
      gap: 16px;
    }
    .box-item {
      background-color: ${colorFetch('bright_grey')};
      border-radius: 16px;

      .title {
        font-size: 20px;
        line-height: 140%;
        letter-spacing: -0.4px;
        margin-top: 24px;
      }
    }
    .num {
      span {
        color: ${colorFetch('green')};
        opacity: 1;
      }
    }

    ${media.tablet} {
      margin-top: 20px;
      .boxesWrapper {
        gap: 12px;
      }
      .box-item {
        padding: 12px;

        .title {
          margin-top: 12px;
          font-size: 15px;
        }
      }
    }
  }

  &.collateral {
    padding: 0;

    .box-item {
      background-color: ${colorFetch('bright_grey')};
      flex: 1 0 calc(100% / 2 - 20px);
      padding: 12px 20px;
    }
    .num {
      display: none;
    }
    .description {
      color: ${colorFetch('gray2')};
      font-size: 18px;
      line-height: 150%;
      margin: 0;
    }

    ${media.tablet} {
      margin-top: 20px;
      padding: 0;

      .boxesWrapper {
        flex-direction: column;
        background-color: ${colorFetch('bright_grey')};
        gap: 0;
        padding: 12px;
      }
      .box-item {
        padding: 0;
        border-radius: 0;
        background-color: unset;

        .description {
          font-size: 15px;
        }
        &:not(:last-of-type):after {
          content: '';
          display: block;
          background-color: ${colorFetch('border')};
          height: 1px;
          margin: 16px 0;
        }
      }
    }
  }

  &.guaranteedIncome {
    margin-top: 40px;
    padding: 0;

    .boxesWrapper {
      gap: 16px;
    }

    .description {
      color: ${colorFetch('white')};
      font-family: Gilroy500, sans-serif;
      font-size: 18px;
      line-height: 150%;
      margin-top: 20px;
      letter-spacing: 0;
    }

    ${media.tablet} {
      .boxesWrapper {
        background-color: ${colorFetch('green')};
        border-radius: 16px;
        padding: 20px;
      }
      .box-item {
        display: flex;
        flex-direction: row;
        padding: 0;
        gap: 0 20px;
        flex-wrap: wrap;

        .description {
          margin: 0;
          max-width: calc(100% - 60px);
          font-size: 15px;
        }

        &:not(:last-of-type):after {
          content: '';
          display: block;
          width: 100%;
          background-color: ${colorFetch('border')};
          height: 1px;
          margin: 16px 0;
        }
      }
      margin-top: 32px;
    }
  }

  ${media.tablet} {
    margin-top: 30px;
  }
`;

const Container = styled.div`
  max-width: 1180px;
  width: 100%;
  margin: 0 auto;
`;

const BoxesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;

  ${media.tablet} {
    gap: 12px;
  }
`;

const Image = styled.img`
  width: 40px;
  height: 40px;
`;

const BoxItem = styled.div<{
  isSetIcon: boolean;
}>`
  position: relative;
  flex: 1 1 calc(100% / 3 - 20px);
  background-color: ${colorFetch('graybg')};
  color: ${colorFetch('black')};
  padding: 24px;
  overflow: hidden;
  border-radius: 24px;

  ${({ isSetIcon }) =>
    isSetIcon &&
    `
    background-color: ${colorFetch('green')};
    color: ${colorFetch('white')};

    .description{
      color: ${colorFetch('white')};
      font-family: Gilroy600, sans-serif;
      font-size: 24px;
      line-height: 120%;
      letter-spacing: -0.48px;

      ${media.tablet} {
        font-size: 18px;
        letter-spacing: -0.36px;
      }
    }
  `}

  ${media.tablet} {
    padding: 20px;
    flex: 1 1 100%;
  }
`;

const Num = styled.div`
  font-family: Gilroy600, sans-serif;
  font-size: 32px;
  line-height: 120%;
  letter-spacing: -0.64px;

  > span {
    :last-of-type {
      display: none;
      opacity: 0.1;
    }
  }

  ${media.tablet} {
    font-size: 24px;
    letter-spacing: -0.48px;
  }
`;

const IconWrapper = styled.div``;

const Title = styled.h3`
  margin-top: 24px;
  color: ${colorFetch('black')};
  font-family: Gilroy600, sans-serif;
  font-size: 32px;
  letter-spacing: -0.64px;

  ${media.tablet} {
    margin-top: 12px;
    font-size: 24px;
    line-height: 140%;
    letter-spacing: -0.48px;
  }
`;

const Description = styled.div`
  font-family: Gilroy500, sans-serif;
  font-size: 20px;
  line-height: 140%;
  letter-spacing: -0.4px;
  margin-top: 24px;

  ${media.tablet} {
    font-size: 15px;
    letter-spacing: -0.3px;
    margin-top: 12px;
    line-height: 150%;
  }
`;

interface PropsType {
  list: Object;
  className?: string;
}

export const NumberedGrid: FC<PropsType> = ({ list, ...props }) => {
  return (
    <ContentWrapper {...props}>
      <Container>
        <BoxesWrapper className="boxesWrapper">
          {Object.values(list).map(({ icon, title, text }, index: number) => {
            return (
              <BoxItem className="box-item" key={index} isSetIcon={!!icon}>
                {icon ? (
                  <IconWrapper className="icon">
                    {typeof icon === 'object' ? React.createElement(icon as React.FC) : <Image src={icon} />}
                  </IconWrapper>
                ) : (
                  <Num className="num">
                    <span>{index < 9 ? `0${++index}` : ++index}</span>
                    <span>{index < 9 ? `0${index}` : index}</span>
                  </Num>
                )}
                {title && <Title className="title">{title}</Title>}
                {text && <Description className="description">{text}</Description>}
              </BoxItem>
            );
          })}
        </BoxesWrapper>
      </Container>
    </ContentWrapper>
  );
};

import CommunityIcon from '@assets/images/mission/community.png';
import AssetClassIcon from '@assets/images/mission/asset_class.png';
import ContributionIcon from '@assets/images/mission/contribution.png';
import AvailableMarketIcon from '@assets/images/mission/available_market.png';

export const missionList = [
  {
    icon: AvailableMarketIcon,
    title: 'Демократизация',
    description:
      'Демократизировать доступ к уникальному активу GEMLINE делает инвестиции в драгоценные камни инклюзивными для миллионов людей',
  },
  {
    icon: AssetClassIcon,
    title: 'Истинная ценность',
    description:
      'Раскрыть истинную ценность камней GEMLINE доказывает, что драгоценные камни — эталон подлинных ценностей в течение нескольких тысяч лет',
  },
  {
    icon: ContributionIcon,
    title: 'Создание платформы',
    description:
      'Создать инвестиционную платформу нового поколения GEMLINE внедряет новые решения, чтобы кардинально упростить инвестирование в камни',
  },
  {
    icon: CommunityIcon,
    title: 'Глобальное сообщество',
    description:
      'Построить глобальное сообщество единомышленников GEMLINE создает новую экосистему для инвесторов без привязок к географии и юрисдикциям',
  },
];

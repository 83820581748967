import { FC } from 'react';
import styled from 'styled-components';
import { Box } from '@mui/material';
import { colorFetch, media } from '@styles/index';
import { MarketCollapsesExamples } from '@components/marketCollapsesExamples/MarketCollapsesExamples';
import { GemSpecifications } from '@components/gemSpecifications/GemSpecifications';
import { NumberedGrid } from '@components/numberedGrid/NumberedGrid';

const ContentWrapper = styled(Box)`
  margin-top: 100px;
  padding-top: 100px;

  ${media.tablet} {
    margin-top: 60px;
    padding-top: 60px;
  }
`;

const Container = styled.div`
  max-width: 1180px;
  width: 100%;
  margin: 0 auto;

  ${media.laptop} {
    padding: 0 20px;
  }
`;

const Subject = styled.div`
  color: ${colorFetch('green')};
  font-family: Gilroy600, sans-serif;
  font-size: 20px;
  line-height: 140%;
  letter-spacing: -0.4px;
  margin-bottom: 24px;

  ${media.tablet} {
    font-size: 18px;
    letter-spacing: -0.36px;
    margin-bottom: 20px;
  }
`;

const Title = styled.h2`
  max-width: 800px;
  color: ${colorFetch('black')};
  font-family: Gilroy600, sans-serif;
  font-size: 48px;
  line-height: 120%;
  letter-spacing: -0.96px;

  ${media.tablet} {
    font-size: 32px;
    line-height: 120%;
    letter-spacing: -0.64px;
  }
`;

const Description = styled.div`
  max-width: 680px;
  color: ${colorFetch('light_text_grey')};
  font-family: Gilroy500, sans-serif;
  font-size: 18px;
  line-height: 150%;
  margin-top: 32px;

  ${media.tablet} {
    margin-top: 30px;
  }
`;

const GemsStabilityWrapper = styled.div`
  padding: 40px;
  border-radius: 30px;
  margin-top: 40px;

  background: ${colorFetch('white')};

  .title {
    max-width: 892px;
    color: ${colorFetch('altblack')};
    font-family: Gilroy600, sans-serif;
    font-size: 32px;
    line-height: 120%;
    letter-spacing: -0.64px;
    margin-top: 0;

    ${media.tablet} {
      font-size: 24px;
      letter-spacing: -0.48px;
    }
  }

  ${media.laptop} {
    padding: 20px;
  }

  ${media.tablet} {
    padding: 30px 20px 20px;
  }
`;

const NumberedGridStyled = styled(NumberedGrid)`
  .box-item {
    background-color: ${colorFetch('green')};
  }
  .num {
    color: ${colorFetch('white')};
  }
  .description {
    font-family: Gilroy600, sans-serif;
    color: ${colorFetch('white')};
  }
`;

interface PropsType {
  numberedGridList: Object;
}

export const PriceGrowth: FC<PropsType> = ({ numberedGridList }) => {
  return (
    <ContentWrapper>
      <Container>
        <Subject>Планы</Subject>
        <Title className="title">Дальнейшие планы GEMLINE для реализации в 2024-2030 годах:</Title>
        <NumberedGridStyled list={numberedGridList} className="futurePlans" />
        {/* <Title>Планы GEMLINE для реализации в 2024-2030 годах:</Title>
        <MarketCollapsesExamples />
        <GemsStabilityWrapper>
          <Title className="title">
            По сравнению с традиционными финансовыми активами, рынок драгоценных камней демонстрирует стабильность и
            устойчивость к кризисам
          </Title>
          <NumberedGridStyled list={numberedGridList} />
        </GemsStabilityWrapper>
        <GemSpecifications /> */}
      </Container>
    </ContentWrapper>
  );
};

import { useConfirmRegistration, useLogin } from '@api/index';
import {
  AuthFormsBox,
  EndAdornment,
  FormsFieldsBox,
  FormsFooterBox,
  FormsFooterText,
  FormsHeading,
  FormsSubmitButton,
  Input,
  InputWithEndAdornment,
  LinkTo,
  Logo,
} from '@components/index';
import { View } from '@routes/index';
import { useGetQueryFromURL } from '@utils/index';
import { sha256 } from 'js-sha256';
import { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { PLATFORM_URL } from 'src/consts';
import { colorFetch } from '@styles/index';
import styled from '@emotion/styled';

import { ReactComponent as ShowHidePass } from '@assets/images/common/show_hide_password.svg';

const defaultValues = {
  email: '',
  password: '',
};

export const ErrorInfoText = styled.span`
  display: inline-block;
  margin-top: 20px;
  width: 100%;
  text-align: center;
  color: ${colorFetch('red')};
  font-family: Gilroy400;
  font-size: 16px;
`;

const StyledShowHidePass = styled(ShowHidePass)`
  cursor: pointer;

  & g path {
    fill: ${colorFetch('gray1')};
    transition: all 0.2s linear;
  }

  &.active {
    & g {
      opacity: 1;
    }

    & g path {
      fill: ${colorFetch('green')};
    }
  }

  &:hover {
    & g path {
      fill: ${colorFetch('green')};
    }
  }
`;

interface IFormInput {
  email: string;
  password: string;
}

interface AuthorizationFormProps {}

export const AuthorizationForm: FC<AuthorizationFormProps> = ({ ...props }) => {
  const queryParams = useGetQueryFromURL('confirmation_hash');

  const [commonError, setCommonError] = useState<string>('');
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const { handleSubmit, register, setError, formState } = useForm<IFormInput>({ defaultValues });

  const { errors } = formState;

  const loginSuccess = ({ data }: any) => {
    const { UserId, UserAuthToken } = data;

    const queryParams = new URLSearchParams({ UserId, UserAuthToken }).toString();

    window.location.href = `${PLATFORM_URL}?${queryParams}`;
  };

  const loginError = ({ response }: any) => {
    if (response.data === 'invalid login') {
      setError('email', {
        type: 'custom',
        message: 'Неверный e-mail',
      });
    }

    if (response.data === 'invalid password') {
      setError('password', {
        type: 'custom',
        message: 'Неверный пароль',
      });
    }
  };

  const login = useLogin({
    onSuccess: loginSuccess,
    onError: loginError,
  });

  const onSubmit = (data: IFormInput) => {
    const { email, password } = data;

    const encryptedPassword = sha256(password);

    login({
      Login: email,
      Password: encryptedPassword,
    });
  };

  const confirmRegistrationSuccess = ({ data }: any) => {
    const urlWithoutQueryParams = window.location.protocol + '//' + window.location.host + window.location.pathname;

    window.location.href = urlWithoutQueryParams;
  };

  const confirmRegistrationError = ({ response }: any) => {
    if (response.data === "request doesn't exist") {
      setCommonError('Вы воспользовались некорректной ссылкой для подтверждения регистрации. Попробуйте повторно.');
    }
  };

  const confirmRegistration = useConfirmRegistration({
    onSuccess: confirmRegistrationSuccess,
    onError: confirmRegistrationError,
  });

  useEffect(() => {
    if (queryParams?.confirmation_hash) {
      confirmRegistration({ ConfirmationHash: queryParams?.confirmation_hash });
    }
  }, [queryParams]);

  return (
    <AuthFormsBox onSubmit={handleSubmit(onSubmit)} noValidate {...props}>
      <Logo />
      <FormsHeading>Вход в систему</FormsHeading>
      <FormsFieldsBox className="login_fields">
        <Input
          label={'E-mail'}
          placeholder={'E-mail'}
          name={'email'}
          type={'email'}
          rules={{
            ...register('email', {
              required: 'Обязательное поле',
              pattern: {
                value:
                  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
                message: 'Введите корректный адрес e-mail',
              },
            }),
          }}
          error={!!errors.email}
          helperText={errors.email?.message}
        />
        <InputWithEndAdornment
          label={'Пароль'}
          placeholder={'Введите пароль'}
          type={showPassword ? 'text' : 'password'}
          name={'password'}
          error={!!errors.password}
          helperText={errors.password?.message}
          rules={{
            ...register('password', {
              required: 'Обязательное поле',
              minLength: {
                value: 8,
                message: 'Пароль должен быть длиннее 8 символов',
              },
              maxLength: {
                value: 64,
                message: 'Пароль должен быть меньше 64 символов',
              },
              pattern: {
                value: /^[A-Za-z0-9~`!@#$%^&*()_\-+={[}\]|:;"'<,>.?/]+$/,
                message: 'Пароль может содержать латинские буквы, цифры и некоторые специальные символы',
              },
            }),
          }}
          endAdornment={
            <EndAdornment
              state={showPassword}
              handleClickIcon={handleClickShowPassword}
              icon1={<StyledShowHidePass className={showPassword && 'active'} />}
              icon2={<StyledShowHidePass className={showPassword && 'active'} />}
            />
          }
        />
      </FormsFieldsBox>
      <LinkTo view={View.AUTHENTICATION_FORGOT_PASSWORD} text="Забыл пароль" className={'login_link'} />
      <FormsSubmitButton type={'submit'} variant={'contained'}>
        Войти
      </FormsSubmitButton>
      {commonError && <ErrorInfoText>{commonError}</ErrorInfoText>}
      <FormsFooterBox>
        <FormsFooterText>Нет аккаунта?</FormsFooterText>
        <LinkTo view={View.AUTHENTICATION_REGISTRATION} text="Регистрация" />
      </FormsFooterBox>
    </AuthFormsBox>
  );
};

import styled from '@emotion/styled';
import { Box, Modal, Typography } from '@mui/material';
import { colorFetch, media } from '@styles/index';
import { FC, ReactNode } from 'react';

import { ReactComponent as CloseIcon } from '@assets/images/common/close_icon.svg';

const ModalContentWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 600px;
  max-height: 85%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: none;
  border-radius: 24px;
  background: ${colorFetch('white')};
  flex-shrink: 0;
  outline: none;

  ${media.tablet} {
    width: 100%;
    max-width: 600px;
  }
`;

export const ModalContentHeader = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px;

  ${media.tablet} {
    padding: 20px;
  }
`;

export const ModalContentHeaderTitle = styled(Typography)`
  color: ${colorFetch('black')};
  font-family: Gilroy600;
  font-size: 32px;
  line-height: normal;

  ${media.phone} {
    font-size: 18px;
  }
`;

const StyledCloseIcon = styled(CloseIcon)`
  width: 24px;
  height: 24px;
  cursor: pointer;
  flex-shrink: 0;

  &:hover {
    path {
      stroke: ${colorFetch('black')};
    }
  }
`;

const OuterCloseIcon = styled(CloseIcon)`
  width: 24px;
  height: 24px;
  cursor: pointer;
  flex-shrink: 0;
  path {
    stroke: ${colorFetch('white')};
  }
`;

export const ModalContentBox = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 0 30px 30px 30px;
  height: 100%;
  /* max-height: 85vh; */
  overflow: auto;

  &.without-padding {
    padding: 0 0 30px 0;
  }
`;

interface StyledModalProps {
  open: boolean;
  onClose: () => void;
  title?: string;
  children: ReactNode;
  closeBtnOut?: boolean;
}

export const StyledModal: FC<StyledModalProps> = ({ open, onClose, title, children, closeBtnOut, ...props }) => {
  return (
    <Modal open={open} onClose={onClose} {...props}>
      <ModalContentWrapper className="modalContentWrapper">
        {!!closeBtnOut && <OuterCloseIcon onClick={onClose} />}
        {title && (
          <ModalContentHeader>
            <ModalContentHeaderTitle>{title}</ModalContentHeaderTitle>
            <StyledCloseIcon onClick={onClose} />
          </ModalContentHeader>
        )}
        <ModalContentBox className="modalContentBox">
          <div>{children}</div>
        </ModalContentBox>
      </ModalContentWrapper>
    </Modal>
  );
};

import styled from '@emotion/styled';
import { Box } from '@mui/material';
import { colorFetch, media, theme } from '@styles/index';

import { ReactComponent as NameBg } from '@assets/images/ceo/name_bg.svg';
import gemDekor from '@assets/images/ceo/ceo_photo.jpg';

const ContentWrapper = styled(Box)`
  position: relative;
  width: 100%;
  max-width: 1220px;
  margin: 130px auto 0;
  padding: 0 20px;

  ${media.tablet} {
    margin-top: 80px;
  }
`;

const Container = styled.div`
  width: 100%;
  margin: 0 auto;
`;

const Profile = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 40px;

  ${media.laptop} {
    flex-wrap: wrap;
  }

  ${media.tablet} {
    margin-top: 30px;
    gap: 12px;
  }
`;

const Name = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1 1 280px;
  padding: 32px;
  color: ${colorFetch('altblack')};
  font-family: Gilroy600, sans-serif;
  font-size: 32px;
  line-height: 130%;
  letter-spacing: -0.64px;
  border-radius: 24px;
  background: ${colorFetch('graybg')};
  box-shadow: 0px -2px 4px 0px ${colorFetch('greyShadow')} inset;

  ${media.laptop} {
    flex: 1 1 100%;
  }

  ${media.tablet} {
    order: 1;
    overflow: hidden;
    font-size: 24px;
    letter-spacing: -0.48px;
    padding: 20px;
  }
`;

const Experience = styled.div`
  display: flex;
  align-items: center;
  gap: 13px;
  color: ${colorFetch('light_blue')};
  margin-top: auto;

  > span:first-of-type {
    font-family: Gilroy600, sans-serif;
    font-size: 42px;
    line-height: 130%;
    letter-spacing: -0.84px;
  }
  > span:last-of-type {
    color: #379ff2;
    font-family: Gilroy600, sans-serif;
    font-size: 15px;
    line-height: 1;
  }

  ${media.tablet} {
    margin-top: 27px;
    > span:last-of-type {
      max-width: 153px;
    }
  }
`;

const NameBgStyled = styled(NameBg)`
  position: absolute;
  top: 0;
  left: 0;

  ${media.tablet} {
    top: -100px;
    width: 100%;
  }
`;

const PhotoWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  flex: 0 1 280px;
  min-height: 340px;
  background-color: #e1dfe0;
  background: url(${gemDekor}) center -160px/170% no-repeat;
  border-radius: 24px;
  overflow: hidden;

  ${media.laptop} {
    background-position: center -60px;
  }

  ${media.tablet} {
    background-position: center center;
    background-size: 100%;
    min-height: 500px;
    order: 0;
    flex: 1;
    max-height: 340px;
    justify-content: center;
  }
  ${media.phone} {
    min-height: 340px;
    background-size: 150%;
  }
`;

const Photo = styled.img`
  width: 100%;

  ${media.laptop} {
    flex: 1 1 50%;
  }

  ${media.tablet} {
    height: 100%;
    flex: 0;
  }
`;

const Description = styled.div`
  flex: 1;
  min-width: 580px;
  width: 100%;
  background: ${colorFetch('graybg')};
  border-radius: 24px;
  color: ${colorFetch('dark_gray')};
  font-family: Gilroy500, sans-serif;
  font-size: 18px;
  line-height: 150%;
  padding: 32px;

  p:not(:first-of-type) {
    margin-top: 20px;
  }
  p:nth-last-of-type(-n + 2) {
    color: ${colorFetch('black_landing')};
    font-family: Gilroy600, sans-serif;
    font-size: 20px;
    line-height: 130%;
    letter-spacing: -0.4px;
  }

  ${media.laptop} {
    flex: 1 1 50%;
    max-width: 100%;
    min-width: unset;
  }

  ${media.tablet} {
    order: 2;
    flex: 1 1 100%;
    padding: 20px;

    p:nth-last-of-type(-n + 2) {
      font-size: 18px;
      line-height: 140%;
      letter-spacing: -0.36px;
    }
  }
`;

const Title = styled.h2`
  color: ${colorFetch('altblack')};
  font-family: Gilroy600, sans-serif;
  font-size: 48px;
  line-height: 120%;
  letter-spacing: -0.96px;

  ${media.tablet} {
    font-size: 32px;
    letter-spacing: -0.64px;
  }
`;

export const Founder = () => {
  return (
    <ContentWrapper>
      <Container>
        <Title>Основатель и CEO</Title>
        <Profile>
          <Name>
            <NameBgStyled />
            <span>Владимир Игоревич Бродский</span>
            <Experience>
              <span>15</span>
              <span>
                <span>лет в бизнесе</span> драгоценных камней
              </span>
            </Experience>
          </Name>
          <PhotoWrapper>{/* <Photo src={gemDekor} /> */}</PhotoWrapper>
          <Description>
            <p>Опыт и экспертиза основателя GEMLINE Владимира Бродского играют ключевую роль в успехе компании.</p>
            <p>
              Владимир посвятил изучению драгоценных камней 15 лет, пройдя путь от геммолога до успешного
              предпринимателя в этой сфере, с нуля создавшего бизнес с оборотом в десятки миллионов долларов.
            </p>
            <p>
              Богатейший практический опыт позволяет Владимиру безошибочно определять наиболее перспективные камни для
              инвестиций и выстраивать эффективную систему работы с ними.
            </p>
            <p>
              Осознав потенциал отрасли, Владимир поставил целью революционный переворот — открыть рынок ценных камней
              для миллионов инвесторов по всему миру.
            </p>
          </Description>
        </Profile>
      </Container>
    </ContentWrapper>
  );
};

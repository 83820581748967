import { Dispatch, FC, SetStateAction } from 'react';
import { Tooltip, tooltipClasses, TooltipProps } from '@mui/material';
import { colorFetch, media, useMediaType } from '@styles/index';
import { historiesList } from './data';

import styled from '@emotion/styled';

const ChartLinesSvg = styled.svg`
  position: relative;
  width: 100%;
  height: auto;
  z-index: 2;

  ${media.laptop} {
  }
`;

const HelpTooltip = styled(({ className, color, ...props }: TooltipProps & { color?: string }) => (
  <Tooltip {...props} classes={{ popper: className }} color={color} />
))(({ color, ...props }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 340,
    backgroundColor: colorFetch(color ? color : 'white'),
    color: colorFetch(color ? 'white' : 'gray1'),
    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.15)',
    borderRadius: '16px',
    padding: '12px 16px',
    fontFamily: 'Gilroy500',
    fontSize: '16px',
    letterSpacing: 'normal',
    lineHeight: '140%',
  },
}));

const PointGroup = styled.g`
  opacity: 0.4;
  transition: opacity 0.3s ease;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }

  ${media.tablet} {
    opacity: 1;
  }
`;

interface ChildComponentProps {
  setHoveredIndex: Dispatch<SetStateAction<number | null>>;
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
}

export const ChartLines: FC<ChildComponentProps> = ({ setHoveredIndex, setIsModalOpen }) => {
  const { tablet: isTablet } = useMediaType();
  const handlePointHover = (index: number) => setHoveredIndex(index);
  const handlePointLeave = () => setHoveredIndex(null);

  const pointersMap = [
    {
      color: 'red',
      pointer: (
        <PointGroup filter="url(#filter4_d_3621_695)">
          <circle cx="112" cy="394" r="12" fill="#E3505C" />
        </PointGroup>
      ),
    },
    {
      color: 'black',
      pointer: (
        <PointGroup filter="url(#filter3_d_3621_695)">
          <circle cx="331" cy="483" r="12" fill="#565B68" />
        </PointGroup>
      ),
    },
    {
      color: 'blue',
      pointer: (
        <PointGroup filter="url(#filter0_d_3621_695)">
          <circle cx="550" cy="303" r="12" fill="#428FCD" />
        </PointGroup>
      ),
    },
    {
      color: 'blue',
      pointer: (
        <PointGroup filter="url(#filter1_d_3621_695)">
          <circle cx="769" cy="215" r="12" fill="#428FCD" />
        </PointGroup>
      ),
    },
    {
      color: 'green',
      pointer: (
        <PointGroup filter="url(#filter2_d_3621_695)">
          <circle cx="982" cy="126" r="12" fill="#2DBB6E" />
        </PointGroup>
      ),
    },
  ];

  return (
    <>
      {!isTablet ? (
        <ChartLinesSvg width="1097" height="535" viewBox="0 0 1097 535" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_3621_695)">
            <path
              d="M111 432.628C111 412.735 128.958 397.662 148.55 401.113L183.701 407.303C188.182 408.092 192.443 409.828 196.201 412.394L259.515 455.644C260.837 456.547 262.224 457.349 263.665 458.044L317.796 484.15C326.751 488.469 337.205 488.38 346.085 483.909L400.573 456.48C403.834 454.839 406.789 452.652 409.312 450.014L493 362.5L548.423 305.132C549.473 304.045 550.599 303.034 551.791 302.107L595.599 268.034C601.053 263.792 607.733 261.43 614.641 261.299L676.15 260.139C680.987 260.047 685.74 258.861 690.052 256.668L766.014 218.036C769.313 216.358 772.878 215.265 776.551 214.806L838.849 207.019C844.196 206.35 849.288 204.342 853.653 201.181L907.983 161.823C909.657 160.611 911.445 159.563 913.321 158.695L980.834 127.464C982.938 126.491 984.931 125.292 986.776 123.888L1048.08 77.2466C1051.33 74.7718 1054.08 71.6968 1056.17 68.1878L1096 1.5V503C1096 520.673 1081.67 535 1064 535H143C125.327 535 111 520.673 111 503V432.628Z"
              fill="url(#paint10_linear_3621_695)"
            />
            <path
              d="M112 395L187.737 407.775C192.517 408.581 197.05 410.462 200.996 413.277L260.205 455.506C262.063 456.832 264.058 457.954 266.155 458.855L317.759 481.026C325.888 484.518 335.1 484.49 343.207 480.946L400.218 456.027C404.036 454.359 407.491 451.96 410.39 448.967L482.5 374.5L550.5 303.5L594.612 266.879C600.356 262.11 607.586 259.5 615.052 259.5H679.5C684.753 259.5 689.924 258.207 694.559 255.735L764.659 218.349C768.188 216.466 772.039 215.262 776.013 214.799L842.436 207.056C848.361 206.366 853.974 204.033 858.643 200.322L906.85 162.004C908.942 160.341 911.234 158.947 913.672 157.854L979.094 128.527C981.357 127.512 983.494 126.238 985.463 124.731L1048.09 76.7607C1051.33 74.2766 1054.07 71.1944 1056.15 67.6807L1096 0.5"
              stroke="#ECEBF0"
              stroke-width="4"
              stroke-linecap="round"
            />

            {pointersMap.map((g, i) => {
              return (
                <HelpTooltip
                  onMouseEnter={() => handlePointHover(i)}
                  onMouseLeave={handlePointLeave}
                  color={g.color}
                  placement="top"
                  title={historiesList[i]?.tooltipText}
                >
                  {g.pointer}
                </HelpTooltip>
              );
            })}
          </g>
          <defs>
            <filter
              id="filter0_d_3621_695"
              x="530"
              y="287"
              width="40"
              height="40"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="4" />
              <feGaussianBlur stdDeviation="4" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix type="matrix" values="0 0 0 0 0.258824 0 0 0 0 0.560784 0 0 0 0 0.803922 0 0 0 0.2 0" />
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3621_695" />
              <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3621_695" result="shape" />
            </filter>
            <filter
              id="filter1_d_3621_695"
              x="749"
              y="199"
              width="40"
              height="40"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="4" />
              <feGaussianBlur stdDeviation="4" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix type="matrix" values="0 0 0 0 0.258824 0 0 0 0 0.560784 0 0 0 0 0.803922 0 0 0 0.2 0" />
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3621_695" />
              <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3621_695" result="shape" />
            </filter>
            <filter
              id="filter2_d_3621_695"
              x="962"
              y="112"
              width="40"
              height="40"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="6" />
              <feGaussianBlur stdDeviation="4" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix type="matrix" values="0 0 0 0 0.176471 0 0 0 0 0.733333 0 0 0 0 0.431373 0 0 0 0.2 0" />
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3621_695" />
              <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3621_695" result="shape" />
            </filter>
            <filter
              id="filter3_d_3621_695"
              x="311"
              y="469"
              width="40"
              height="40"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="6" />
              <feGaussianBlur stdDeviation="4" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix type="matrix" values="0 0 0 0 0.337255 0 0 0 0 0.356863 0 0 0 0 0.407843 0 0 0 0.2 0" />
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3621_695" />
              <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3621_695" result="shape" />
            </filter>
            <filter
              id="filter4_d_3621_695"
              x="92"
              y="378"
              width="40"
              height="40"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="4" />
              <feGaussianBlur stdDeviation="4" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix type="matrix" values="0 0 0 0 0.890196 0 0 0 0 0.313726 0 0 0 0 0.360784 0 0 0 0.2 0" />
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3621_695" />
              <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3621_695" result="shape" />
            </filter>
            <linearGradient
              id="paint0_linear_3621_695"
              x1="0"
              y1="481"
              x2="1097"
              y2="483"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#EFEEF3" stop-opacity="0" />
              <stop offset="0.0592602" stop-color="#EFEEF3" />
              <stop offset="0.930384" stop-color="#EFEEF3" />
              <stop offset="1" stop-color="#EFEEF3" stop-opacity="0" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_3621_695"
              x1="0"
              y1="392"
              x2="1097"
              y2="394"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#EFEEF3" stop-opacity="0" />
              <stop offset="0.0592602" stop-color="#EFEEF3" />
              <stop offset="0.930384" stop-color="#EFEEF3" />
              <stop offset="1" stop-color="#EFEEF3" stop-opacity="0" />
            </linearGradient>
            <linearGradient
              id="paint2_linear_3621_695"
              x1="0"
              y1="303"
              x2="1097"
              y2="305"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#EFEEF3" stop-opacity="0" />
              <stop offset="0.0592602" stop-color="#EFEEF3" />
              <stop offset="0.930384" stop-color="#EFEEF3" />
              <stop offset="1" stop-color="#EFEEF3" stop-opacity="0" />
            </linearGradient>
            <linearGradient
              id="paint3_linear_3621_695"
              x1="0"
              y1="214"
              x2="1097"
              y2="216"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#EFEEF3" stop-opacity="0" />
              <stop offset="0.0592602" stop-color="#EFEEF3" />
              <stop offset="0.930384" stop-color="#EFEEF3" />
              <stop offset="1" stop-color="#EFEEF3" stop-opacity="0" />
            </linearGradient>
            <linearGradient
              id="paint4_linear_3621_695"
              x1="0"
              y1="125"
              x2="1097"
              y2="127"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#EFEEF3" stop-opacity="0" />
              <stop offset="0.0592602" stop-color="#EFEEF3" />
              <stop offset="0.930384" stop-color="#EFEEF3" />
              <stop offset="1" stop-color="#EFEEF3" stop-opacity="0" />
            </linearGradient>
            <linearGradient
              id="paint5_linear_3621_695"
              x1="111"
              y1="35"
              x2="111"
              y2="535"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#EFEEF3" stop-opacity="0" />
              <stop offset="0.142725" stop-color="#EFEEF3" />
              <stop offset="0.857725" stop-color="#EFEEF3" />
              <stop offset="1" stop-color="#EFEEF3" stop-opacity="0" />
            </linearGradient>
            <linearGradient
              id="paint6_linear_3621_695"
              x1="330"
              y1="35"
              x2="330"
              y2="535"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#EFEEF3" stop-opacity="0" />
              <stop offset="0.142725" stop-color="#EFEEF3" />
              <stop offset="0.857725" stop-color="#EFEEF3" />
              <stop offset="1" stop-color="#EFEEF3" stop-opacity="0" />
            </linearGradient>
            <linearGradient
              id="paint7_linear_3621_695"
              x1="550"
              y1="35"
              x2="550"
              y2="535"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#EFEEF3" stop-opacity="0" />
              <stop offset="0.142725" stop-color="#EFEEF3" />
              <stop offset="0.857725" stop-color="#EFEEF3" />
              <stop offset="1" stop-color="#EFEEF3" stop-opacity="0" />
            </linearGradient>
            <linearGradient
              id="paint8_linear_3621_695"
              x1="768"
              y1="35"
              x2="768"
              y2="535"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#EFEEF3" stop-opacity="0" />
              <stop offset="0.142725" stop-color="#EFEEF3" />
              <stop offset="0.857725" stop-color="#EFEEF3" />
              <stop offset="1" stop-color="#EFEEF3" stop-opacity="0" />
            </linearGradient>
            <linearGradient
              id="paint9_linear_3621_695"
              x1="981"
              y1="35"
              x2="981"
              y2="535"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#EFEEF3" stop-opacity="0" />
              <stop offset="0.142725" stop-color="#EFEEF3" />
              <stop offset="0.857725" stop-color="#EFEEF3" />
              <stop offset="1" stop-color="#EFEEF3" stop-opacity="0" />
            </linearGradient>
            <linearGradient
              id="paint10_linear_3621_695"
              x1="572"
              y1="389.5"
              x2="572"
              y2="489"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#E4E3EA" stop-opacity="0.2" />
              <stop offset="1" stop-color="#EFEEF3" stop-opacity="0" />
            </linearGradient>
            <clipPath id="clip0_3621_695">
              <rect width="1097" height="534.5" fill="white" transform="translate(0 0.5)" />
            </clipPath>
          </defs>
        </ChartLinesSvg>
      ) : (
        <ChartLinesSvg width="291" height="316" viewBox="0 0 291 316" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_3632_722)">
            <path
              d="M32 258.134C32 250.245 39.5253 244.531 47.1248 246.65C50.1547 247.495 52.7279 249.503 54.2838 252.237L71.7453 282.917C72.9055 284.955 74.4259 286.766 76.2328 288.262L76.5555 288.529C83.2682 294.084 92.9136 294.315 99.8843 289.087C102.579 287.066 104.683 284.36 105.977 281.25L127.403 229.776L140.964 200.665C143.597 195.012 147.825 190.251 153.128 186.969L155.5 185.5L175.801 173.626C179.883 171.238 183.385 167.976 186.055 164.073L198.603 145.734C200.522 142.929 202.875 140.448 205.575 138.384L216.207 130.254C217.733 129.087 219.36 128.06 221.07 127.185L232.384 121.397C237.374 118.844 241.605 115.025 244.656 110.323L252 99L260 82.5L271.777 60.1636C273.578 56.7494 274.745 53.0376 275.224 49.2076L278 27V266C278 283.673 263.673 298 246 298H55.9824C42.7373 298 32 287.263 32 274.018V258.134Z"
              fill="url(#paint11_linear_3632_722)"
            />
            <path
              d="M32 242.852L39.9319 244.652C50.2245 246.987 58.7037 254.247 62.5959 264.057L68.6663 279.358C70.503 283.987 73.8286 287.873 78.1186 290.403L81.7415 292.54C87.3476 295.846 94.3922 295.456 99.5989 291.551V291.551C101.828 289.879 103.586 287.658 104.7 285.104L142.027 199.572C144.923 192.935 149.985 187.476 156.385 184.089L175.278 174.088C180.333 171.412 184.579 167.429 187.573 162.555L199.336 143.407C202.7 137.93 207.637 133.592 213.502 130.961L232.107 122.612C239.433 119.325 245.256 113.403 248.419 106.022L258.5 82.5L270.336 62.3795C272.425 58.8267 273.809 54.9035 274.411 50.8256L278 26.5"
              stroke="#ECEBF0"
              stroke-width="4"
              stroke-linecap="round"
            />
            <PointGroup
              filter="url(#filter0_d_3632_722)"
              onClick={() => {
                setIsModalOpen(true);
                handlePointHover(0);
              }}
            >
              <circle cx="32" cy="243" r="12" fill="#E3505C" />
            </PointGroup>
            <PointGroup
              filter="url(#filter1_d_3632_722)"
              onClick={() => {
                setIsModalOpen(true);
                handlePointHover(1);
              }}
            >
              <circle cx="89" cy="297" r="12" fill="#565B68" />
            </PointGroup>
            <PointGroup
              filter="url(#filter2_d_3632_722)"
              onClick={() => {
                setIsModalOpen(true);
                handlePointHover(2);
              }}
            >
              <circle cx="147" cy="189" r="12" fill="#379FF2" />
            </PointGroup>
            <PointGroup
              filter="url(#filter3_d_3632_722)"
              onClick={() => {
                setIsModalOpen(true);
                handlePointHover(3);
              }}
            >
              <circle cx="203" cy="135" r="12" fill="#379FF2" />
            </PointGroup>
            <PointGroup
              filter="url(#filter4_d_3632_722)"
              onClick={() => {
                setIsModalOpen(true);
                handlePointHover(4);
              }}
            >
              <circle cx="259" cy="82" r="12" fill="#2DBB6E" />
            </PointGroup>
          </g>
          <defs>
            <filter
              id="filter0_d_3632_722"
              x="12"
              y="227"
              width="40"
              height="40"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="4" />
              <feGaussianBlur stdDeviation="4" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix type="matrix" values="0 0 0 0 0.890196 0 0 0 0 0.313726 0 0 0 0 0.360784 0 0 0 0.2 0" />
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3632_722" />
              <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3632_722" result="shape" />
            </filter>
            <filter
              id="filter1_d_3632_722"
              x="69"
              y="283"
              width="40"
              height="40"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="6" />
              <feGaussianBlur stdDeviation="4" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix type="matrix" values="0 0 0 0 0.337255 0 0 0 0 0.356863 0 0 0 0 0.407843 0 0 0 0.2 0" />
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3632_722" />
              <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3632_722" result="shape" />
            </filter>
            <filter
              id="filter2_d_3632_722"
              x="127"
              y="175"
              width="40"
              height="40"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="6" />
              <feGaussianBlur stdDeviation="4" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix type="matrix" values="0 0 0 0 0.337255 0 0 0 0 0.356863 0 0 0 0 0.407843 0 0 0 0.2 0" />
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3632_722" />
              <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3632_722" result="shape" />
            </filter>
            <filter
              id="filter3_d_3632_722"
              x="183"
              y="121"
              width="40"
              height="40"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="6" />
              <feGaussianBlur stdDeviation="4" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix type="matrix" values="0 0 0 0 0.337255 0 0 0 0 0.356863 0 0 0 0 0.407843 0 0 0 0.2 0" />
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3632_722" />
              <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3632_722" result="shape" />
            </filter>
            <filter
              id="filter4_d_3632_722"
              x="239"
              y="68"
              width="40"
              height="40"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="6" />
              <feGaussianBlur stdDeviation="4" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix type="matrix" values="0 0 0 0 0.337255 0 0 0 0 0.356863 0 0 0 0 0.407843 0 0 0 0.2 0" />
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3632_722" />
              <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3632_722" result="shape" />
            </filter>
            <linearGradient id="paint0_linear_3632_722" x1="32" y1="0" x2="32" y2="316" gradientUnits="userSpaceOnUse">
              <stop stop-color="#EFEEF3" stop-opacity="0" />
              <stop offset="0.142725" stop-color="#EFEEF3" />
              <stop offset="0.857725" stop-color="#EFEEF3" />
              <stop offset="1" stop-color="#EFEEF3" stop-opacity="0" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_3632_722"
              x1="88.75"
              y1="0"
              x2="88.75"
              y2="316"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#EFEEF3" stop-opacity="0" />
              <stop offset="0.142725" stop-color="#EFEEF3" />
              <stop offset="0.857725" stop-color="#EFEEF3" />
              <stop offset="1" stop-color="#EFEEF3" stop-opacity="0" />
            </linearGradient>
            <linearGradient
              id="paint2_linear_3632_722"
              x1="145.5"
              y1="0"
              x2="145.5"
              y2="316"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#EFEEF3" stop-opacity="0" />
              <stop offset="0.142725" stop-color="#EFEEF3" />
              <stop offset="0.857725" stop-color="#EFEEF3" />
              <stop offset="1" stop-color="#EFEEF3" stop-opacity="0" />
            </linearGradient>
            <linearGradient
              id="paint3_linear_3632_722"
              x1="202.25"
              y1="0"
              x2="202.25"
              y2="316"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#EFEEF3" stop-opacity="0" />
              <stop offset="0.142725" stop-color="#EFEEF3" />
              <stop offset="0.857725" stop-color="#EFEEF3" />
              <stop offset="1" stop-color="#EFEEF3" stop-opacity="0" />
            </linearGradient>
            <linearGradient
              id="paint4_linear_3632_722"
              x1="259"
              y1="0"
              x2="259"
              y2="316"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#EFEEF3" stop-opacity="0" />
              <stop offset="0.142725" stop-color="#EFEEF3" />
              <stop offset="0.857725" stop-color="#EFEEF3" />
              <stop offset="1" stop-color="#EFEEF3" stop-opacity="0" />
            </linearGradient>
            <linearGradient
              id="paint5_linear_3632_722"
              x1="292"
              y1="26"
              x2="292"
              y2="317"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#EFEEF3" stop-opacity="0" />
              <stop offset="0.142725" stop-color="#EFEEF3" />
              <stop offset="0.857725" stop-color="#EFEEF3" />
              <stop offset="1" stop-color="#EFEEF3" stop-opacity="0" />
            </linearGradient>
            <linearGradient
              id="paint6_linear_3632_722"
              x1="292"
              y1="80"
              x2="292"
              y2="371"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#EFEEF3" stop-opacity="0" />
              <stop offset="0.142725" stop-color="#EFEEF3" />
              <stop offset="0.857725" stop-color="#EFEEF3" />
              <stop offset="1" stop-color="#EFEEF3" stop-opacity="0" />
            </linearGradient>
            <linearGradient
              id="paint7_linear_3632_722"
              x1="292"
              y1="134"
              x2="292"
              y2="425"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#EFEEF3" stop-opacity="0" />
              <stop offset="0.142725" stop-color="#EFEEF3" />
              <stop offset="0.857725" stop-color="#EFEEF3" />
              <stop offset="1" stop-color="#EFEEF3" stop-opacity="0" />
            </linearGradient>
            <linearGradient
              id="paint8_linear_3632_722"
              x1="292"
              y1="188"
              x2="292"
              y2="479"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#EFEEF3" stop-opacity="0" />
              <stop offset="0.142725" stop-color="#EFEEF3" />
              <stop offset="0.857725" stop-color="#EFEEF3" />
              <stop offset="1" stop-color="#EFEEF3" stop-opacity="0" />
            </linearGradient>
            <linearGradient
              id="paint9_linear_3632_722"
              x1="292"
              y1="242"
              x2="292"
              y2="533"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#EFEEF3" stop-opacity="0" />
              <stop offset="0.142725" stop-color="#EFEEF3" />
              <stop offset="0.857725" stop-color="#EFEEF3" />
              <stop offset="1" stop-color="#EFEEF3" stop-opacity="0" />
            </linearGradient>
            <linearGradient
              id="paint10_linear_3632_722"
              x1="292"
              y1="296"
              x2="292"
              y2="587"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#EFEEF3" stop-opacity="0" />
              <stop offset="0.142725" stop-color="#EFEEF3" />
              <stop offset="0.857725" stop-color="#EFEEF3" />
              <stop offset="1" stop-color="#EFEEF3" stop-opacity="0" />
            </linearGradient>
            <linearGradient
              id="paint11_linear_3632_722"
              x1="147.133"
              y1="240.455"
              x2="147.133"
              y2="279.807"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#E4E3EA" stop-opacity="0.2" />
              <stop offset="1" stop-color="#EFEEF3" stop-opacity="0" />
            </linearGradient>
            <clipPath id="clip0_3632_722">
              <rect width="291" height="316" fill="white" transform="translate(-0.000244141)" />
            </clipPath>
          </defs>
        </ChartLinesSvg>
      )}
    </>
  );
};

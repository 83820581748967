import { useForgotPassword } from '@api/index';
import {
  AuthFormsBox,
  ErrorInfoText,
  FormsFieldsBox,
  FormsFooterBox,
  FormsFooterText,
  FormsHeading,
  FormsSubHeading,
  FormsSubmitButton,
  Input,
  LinkTo,
  Logo,
} from '@components/index';
import { View } from '@routes/routeInterfaces';
import { FC, useState } from 'react';
import { useForm } from 'react-hook-form';

const defaultValues = {
  email: '',
};

interface IFormInput {
  email: string;
}

interface ForgotPasswordFormProps {
  setIsComplete: (data: boolean) => void;
}

export const ForgotPasswordForm: FC<ForgotPasswordFormProps> = ({ setIsComplete }) => {
  const [commonError, setCommonError] = useState<string>('');

  const { handleSubmit, register, setError, formState } = useForm<IFormInput>({ defaultValues });

  const { errors } = formState;

  const forgotPasswordSuccess = (data: any) => {
    setIsComplete(true);
  };

  const forgotPasswordError = ({ response }: any) => {
    if (response.data === "user doesn't exist") {
      setCommonError('Пользователя с таким e-mail не существует');
    }
  };

  const registration = useForgotPassword({
    onSuccess: forgotPasswordSuccess,
    onError: forgotPasswordError,
  });

  const onSubmit = (data: IFormInput) => {
    const { email } = data;

    setCommonError('');

    registration({
      Login: email,
      Host: window.location.host + '/authentication/reset-password',
    });
  };

  return (
    <AuthFormsBox onSubmit={handleSubmit(onSubmit)} noValidate>
      <Logo />
      <FormsHeading>Забыл пароль</FormsHeading>
      <FormsSubHeading>
        Введите ваш E-mail, по которому вы регистрировались и мы отправим инструкцию по восстановлению пароля
      </FormsSubHeading>
      <FormsFieldsBox>
        <Input
          label={'E-mail'}
          placeholder={'E-mail'}
          name={'email'}
          type={'email'}
          rules={{
            ...register('email', {
              required: 'Обязательное поле',
              pattern: {
                value:
                  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
                message: 'Введите корректный адрес e-mail',
              },
            }),
          }}
          error={!!errors.email}
          helperText={errors.email?.message}
        />
      </FormsFieldsBox>
      <FormsSubmitButton type={'submit'} variant={'contained'}>
        Восстановить пароль
      </FormsSubmitButton>
      {commonError && <ErrorInfoText>{commonError}</ErrorInfoText>}
      <FormsFooterBox>
        <FormsFooterText>Вспомнили пароль?</FormsFooterText>
        <LinkTo view={View.AUTHENTICATION_LOGIN} text="Вход" />
      </FormsFooterBox>
    </AuthFormsBox>
  );
};

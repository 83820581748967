export const theme = {
  colors: {
    // old colors
    white_seashell: '#F1F1F1', //src\components\form\common\universalInput\UniversalInput.tsx
    light_gray: '#A3A3A3', //src\components\form
    light_pale_sky_gray: '#6C717F', //src\components\form
    mid_gray: '#5A5D6B', //src\components\form
    dark_trout_gray: '#4A4D57', //src\components\form

    // new theme
    white: '#FFFFFF',
    background: '#F3F2F7',
    background_hover: '#E7E5EE',
    border: '#E0DEE9',
    border1: '#EBE8F7',
    black: '#35343D',
    gray: '#565B68',
    gray1: '#979EA5',
    gray2: '#7E828E',
    gray3: '#ACA8BD',
    gray4: '#D8DDE2',
    green: '#2DBB6E',
    light_green_hover: '#48D88A',
    green_hover: '#31CA77',
    green_transparent_hover: '#2dbb6e4d',
    green_background: '#E0F5E9',
    red: '#FF4B5A',
    red_background: '#F5E0E0',
    blue: '#379FF2',
    blue1: '#4DAAF3',
    blue2: '#63B4F5',
    blue3: '#7ABFF6',
    blue_background: '#E1F1FD',
    purple: '#A15BC2',
    purple_background: '#F1E7F6',
    black_landing: '#323232',
    altblack: '#35343D',
    light_bg_red: '#FBE5E7',
    light_blue: '#379FF2',
    light_grey: '#FBFAFE',
    bright_grey: '#FAFAFB',
    graybg: '#EFEEF3',
    gray_border: '#E0DEE9',
    light_text_grey: '#7E828E',
    dark_gray: '#565B68',
    dark_mako_gray: '#454956',
    dark_text_gray: '#979EA5',
    greyShadow: '#E8E6EF',
  },
  borderRadius: {
    radius8: '8px',
  },
};

import { FC } from 'react';
import styled from '@emotion/styled';
import { Box } from '@mui/material';
import { colorFetch, media } from '@styles/index';
import { GridBoxes } from '@components/gridBoxes/GridBoxes';
import { GreenTextBox } from '@components/greenTextBox/GreenTextBox';

import gemHandsImage from '@assets/images/common/lightning.png';

const ContentWrapper = styled(Box)`
  width: 100%;
  max-width: 1220px;
  margin: 0 auto;
`;

const Container = styled.div`
  width: 100%;
  margin: 0 auto;
  background: ${colorFetch('white')};
  padding: 40px;
  border-radius: 30px;

  ${media.tablet} {
    padding: 20px;
  }
`;

const Image = styled.img`
  width: 100%;
  max-width: 160px;

  ${media.tablet} {
    display: none;
  }
`;

const DescriptionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const BoldText = styled.div`
  color: ${colorFetch('black')};
  font-family: Gilroy600, sans-serif;
  font-size: 32px;
  line-height: 130%;
  letter-spacing: -0.64px;
  margin-top: 40px;

  ${media.tablet} {
    margin-top: 32px;
    font-size: 24px;
    line-height: 120%;
    letter-spacing: -0.48px;
  }
`;

const Text = styled.div`
  color: ${colorFetch('black')};
  font-family: Gilroy600, sans-serif;
  font-size: 20px;
  line-height: 130%;
  letter-spacing: -0.4px;
  margin-top: 24px;

  ${media.tablet} {
    margin-top: 20px;
    font-size: 18px;
    letter-spacing: -0.36px;
  }
`;

const Description = styled.div`
  max-width: 728px;
  color: ${colorFetch('gray2')};
  font-family: Gilroy500, sans-serif;
  font-size: 18px;
  line-height: 150%;

  ${media.tablet} {
    font-size: 15px;
  }
`;

const GridWrapper = styled.div``;

const GridBoxesStyled = styled(GridBoxes)``;

const GreenTextBoxStyled = styled(GreenTextBox)`
  padding: 0;
`;

interface DataListProps {
  image?: string;
  title: string;
  text?: string;
}

interface DataProps {
  text: string;
  boldText?: string;
  list: DataListProps[];
}

interface PropsType {
  maxtitlewidth?: string;
  subject?: string;
  title?: string;
  text?: string;
  data: DataProps;
}

export const WhyGem: FC<PropsType> = ({ data, ...props }) => {
  return (
    <ContentWrapper {...props}>
      <Container>
        <GridWrapper>
          <GridBoxesStyled data={data.list} className="small whyGem" itemBg="bright_grey" />
        </GridWrapper>
        <BoldText>{data.boldText}</BoldText>
        <Text>{data.text}</Text>
      </Container>
    </ContentWrapper>
  );
};

import { ReactComponent as ChartIcon } from '@assets/images/common/chart.svg';
import brilliantInBox from '@assets/images/common/brilliant_in_box.png';
import keyImage from '@assets/images/common/key.png';
import brilliantGreen from '@assets/images/common/brilliant_green.png';
import chart from '@assets/images/common/chart_2.png';
import gemTimeLimit from '@assets/images/about_us/gem_time_limit.png';
import limitedOffer from '@assets/images/common/limited_offer_2x.png';
import downgradeChart from '@assets/images/common/downgrade_chart.png';
import inclusiveInvesting from '@assets/images/about_us/inclusive_investing.png';
import stableIncome from '@assets/images/about_us/stable_income.png';
import financialFreedom from '@assets/images/about_us/financial_freedom.png';
import rings from '@assets/images/about_us/rings.png';
import currencyFall from '@assets/images/about_us/currency_fall.png';
import money from '@assets/images/about_us/money.png';
import diamondBright from '@assets/images/about_us/diamond_bright.png';
import diamond from '@assets/images/about_us/diamond.png';
import investmentModel from '@assets/images/about_us/investment_model.png';
import safeIcon from '@assets/images/about_us/safe.png';
import suitcaseImage from '@assets/images/common/suitcase.png';
import prizeCup from '@assets/images/about_us/prize_сup.png';
import suitcaseWhiteIcon from '@assets/images/common/suitcase_white_icon.png';
import secondarySalesWhiteIcon from '@assets/images/common/secondary_sales_white_icon.png';
import chartsWhiteIcon from '@assets/images/common/charts_white_icon.png';
import safeImage from '@assets/images/common/safe.png';
import boxImage from '@assets/images/common/box.png';
import brilliantInBoxFullImage from '@assets/images/common/brilliant_in_box_full.png';
import stackCoinsImage from '@assets/images/common/stack_of_coins.png';

export const keyPhrasesList = [
  {
    image: brilliantInBox,
    subject: 'Специализация',
    title: 'Драгоценные камни',
  },
  {
    image: keyImage,
    subject: 'Миссия',
    title: 'Сделать актив доступным для розничных инвесторов',
  },
  {
    image: brilliantGreen,
    subject: 'Бизнес-модель',
    title: 'Паевое инвестирование',
  },
  {
    image: chart,
    subject: 'Подход',
    title: 'Расширенный жизненный цикл камня с целью увеличения потенциальной доходности',
  },
];

export const limitedOfferList = [
  {
    icon: gemTimeLimit,
    title:
      'Сформированный GEMLINE единый портфель выступает в качестве залогового обеспечения привлеченных от инвесторов средств.',
    description:
      '<ul><li>Пополняя депозит инвестор заключает с GEMLINE договор.</li><li>Со стороны GEMLINE инвестору предоставляется в залог часть портфеля компании</li><li>На срок договора инвестор получает статус созалогодержателем пропорционально размерам депозита.</li></ul>',
  },
  {
    icon: limitedOffer,
    title:
      'Привлеченные от инвесторов средства обеспечиваются залогом реальных активов в виде драгоценных камней из портфеля GEMLINE.',
    description: '<p>Это позволяет минимизировать риски инвесторов и гарантировать возврат их вложений.</p>',
  },
];

export const missionTilesList = [
  {
    icon: downgradeChart,
    text: 'Крах криптобиржи FTX с падением капитализации с $32 млрд до 0 всего за несколько дней',
  },
  {
    icon: downgradeChart,
    text: 'Обрушение курса Bitcoin и других альткоинов на 80-90%',
  },
  {
    icon: downgradeChart,
    text: 'Обвал компании Celsius Network с заморозкой активов инвесторов на $4,7 млрд',
  },
  {
    icon: downgradeChart,
    text: 'Крах высокооцененных технологических компаний вроде Netflix, потерявшей за год 70% стоимости',
  },
];

export const risingPricesList = [
  {
    icon: inclusiveInvesting,
    title: 'Инклюзивность инвестирования',
    description:
      '<p>Компания GEMLINE ставит своей миссией кардинально изменить ситуацию и сделать инвестиции в цветные драгоценные камни  по-настоящему инклюзивными. Мы разработали специальную бизнес-модель и технологические решения, чтобы предоставить возможность инвестировать в этот актив уже от $50!</p>',
  },
  {
    icon: stableIncome,
    title: 'Стабильный доход',
    description:
      '<p>Теперь каждый желающий может стать совладельцем уникальных сокровищ планеты и получать стабильный доход от роста их стоимости. Наша экспертиза, технологии и инфраструктура позволяют с легкостью масштабировать эту уникальную модель на миллионы клиентов по всему миру.</p>',
  },
  {
    icon: financialFreedom,
    title: 'Финансовая свобода',
    description:
      '<p>Мы верим, что открыли еще один путь к финансовой свободе, защищенный от шоков финансовых рынков и при этом способный обеспечить инвесторам высокий стабильный доход. Присоединяйтесь к нашему глобальному сообществу и станьте частью этого захватывающего путешествия!</p>',
  },
];

export const projectHistoryList = [
  {
    title: '2013',
    list: ['Основание компании по геммологической экспертизе и сертификации'],
  },
  {
    title: '2016',
    list: [
      'Покупка собственного оборудования',
      'Расширение возможностей лаборатории',
      'Участие в выставках, поиск новых клиентов',
      'Зарождение идеи торговли камнями',
    ],
  },
  {
    title: '2017',
    list: ['Поездка в Мьянму, изучение рынка', 'Налаживание контактов с местными поставщиками'],
  },
  {
    title: '2019',
    list: [
      'Первые закупки сырья в Мьянме на $750 000',
      'Продажа части камней как сырья, огранка лучших экземпляров',
      'Прибыль с первой сделки $480 000',
    ],
  },
  {
    title: '2020',
    list: [
      'Заключение эксклюзивных контрактов с приисками по всему миру',
      'Объем инвестиций $5 млн, 25 сотрудников',
      'Решение о запуске платформы GEMLINE',
    ],
  },
  {
    title: '2021',
    list: ['Старт разработки платформы GEMLINE'],
  },
  {
    title: '2022',
    list: [
      'Открытие и налаживание работы сети юрлиц',
      'Запуск МВП платформы в тестовом режиме',
      'Старт пилотного этапа, первые инвестиции',
    ],
  },
  {
    title: '2023',
    list: ['Выплата первой прибыли пилотным инвесторам'],
  },
  {
    title: '2024',
    list: ['Полноценный публичный запуск платформы GEMLINE'],
  },
];

export const futurePlansList = [
  {
    text: 'Услуга полноценного выкупа драгоценных камней из каталога доступных образцов, с курьерской доставкой',
  },
  {
    text: 'Услуга заказа конкретного драгоценного камня под дальнейший выкуп с дисконтом до 25% от рыночной цены',
  },
  {
    text: 'Новые инвестиционные продукты — краткосрочные депозиты, долгосрочное инвестирование на несколько лет',
  },
  {
    text: 'Открытие выставочного центра драгоценных камней GEMLINE в городе Санкт-Петербург — малой родине CEO Владимира Бродского',
  },
  {
    text: 'Открытие образовательного центра GEMLINE для обучения геммологов, маклеров, менеджеров и других специалистов',
  },
  {
    text: 'Запуск ювелирного направления бизнеса GEMLINE с производством линейки украшений с натуральными драгоценностями',
  },
];

export const controlAllStages = [
  {
    text: 'GEMLINE привлекает средства инвесторов на условиях выплаты фиксированного процента доходности, заранее оговоренного в договорах.',
  },
  {
    text: 'Средства используются для обеспечения операционной деятельности GEMLINE.',
  },
  {
    text: 'Применяемая бизнес-модель позволяет гарантировать инвесторам получение оговоренного фиксированного уровня доходности на вложенные средства при минимальных рисках.',
  },
  {
    text: 'Диверсифицированный портфель снижает риски и обеспечивает стабильный рост стоимости активов.',
  },
  {
    text: 'Залоговый механизм гарантирует инвесторам возврат средств и получение фиксированного процента прибыли.',
  },
  {
    text: 'Расширение портфеля за счет новых перспективных активов увеличивает потенциал для извлечения дохода.',
  },
];

export const investmentСapital = {
  title: 'В чем заключается инвестиционный потенциал драгоценных камней? ',
  text: 'Предложение многих видов природных драгоценных камней строго ограничено запасами месторождений. По мере истощения запасов, с каждым годом найти крупные качественные экземпляры становится все сложнее. Это ведет к росту цен.',
};

export const whyGemList = {
  boldText:
    'По сравнению с традиционными финансовыми активами, рынок драгоценных камней демонстрирует стабильность и устойчивость к кризисам',
  text: 'Высокая эстетическая ценность сочетается с функцией сохранения и приумножения капитала',
  list: [
    {
      image: rings,
      title: 'Реальное применение в ювелирной индустрии',
    },
    {
      image: currencyFall,
      title: 'Ограниченность предложения — защита от инфляции',
    },
    {
      image: money,
      title: 'Растущий спрос — гарантия дальнейшего роста стоимости',
    },
  ],
};

export const uniqueAdvantages = [
  {
    image: ChartIcon,
    text: 'Стоимость настоящих драгоценных камней со временем только растет, защищая капитал от инфляции.',
  },
  {
    image: diamondBright,
    text: 'Каждый натуральный камень имеет уникальные свойства. Компьютерную геммологическую экспертизу невозможно обмануть.',
  },
  {
    image: diamond,
    text: 'Камни не подвержены физическому износу, в отличие от элементов роскоши, предметов искусства и недвижимости.',
  },
  {
    image: safeIcon,
    text: 'Это компактный высоколиквидный актив. Его можно хранить дома в сейфе, легко перевозить или носить как украшение. ',
  },
];

export const businessModelList = {
  image: investmentModel,
  text: 'GEMLINE привлекает средства инвесторов для формирования оборотного капитала. Это нужно чтобы обеспечить достаточный объем денег на ведение операционной деятельности.',
  textBold:
    'GEMLINE предлагает новый подход работы на рынке драгоценных камней, в основе которой лежит модель паевого финансирования.',
  listTitle: 'Постоянный приток денег от инвесторов позволяет:',
  list: [
    {
      title: 'Быстро реагировать на поступающую от инсайдеров информацию',
    },
    {
      title: 'Покупать наиболее перспективные партии сырья по выгодным ценам',
    },
    {
      title: 'Финансировать сертификацию, огранку, хранение и продвижение камней',
    },
  ],
  bottomTexts: [
    'Эти процессы — источник постоянных расходов, которые необходимо покрывать.',
    '<span>Привлечение инвестиций</span>  — ключевой фактор успеха и фундамент бесперебойной работы бизнес-модели GEMLINE',
  ],
};

export const investmentPortfolio = {
  image: suitcaseImage,
  title: 'Формирование единого инвестиционного портфеля',
  texts: [
    'В распоряжении GEMLINE постоянно находятся драгоценные камни на разных этапах жизненного цикла — как только приобретенное сырье, так и прошедшие сертификацию, огранку и готовые к продаже изделия.',
    'Все имеющиеся в наличии в каждый конкретный момент времени драгоценные камни составляют единый общий фонд активов GEMLINE.',
    'Этот портфель включает широкий спектр камней различных видов, размеров, месторождений — как только приобретенные, в виде необработанного сырья, так и прошедшие этапы обработки и огранки.',
  ],
};

export const collateralData = {
  image: prizeCup,
  subject: 'Инвестиционный портфель как залоговое обеспечение',
  title:
    'Сформированный GEMLINE единый портфель выступает в качестве залогового обеспечения привлеченных от инвесторов средств.',
  listTitle: 'Правовой механизм:',
  list: [
    {
      text: 'Внося на расчетный счет компании депозит, инвестор автоматически заключает с GEMLINE договор.',
    },
    {
      text: 'В обеспечение исполнения обязательств со стороны GEMLINE инвестору автоматически предоставляется в залог часть портфеля компании, пропорциональная объемам инвестиций.',
    },
    {
      text: 'На срок действия договора (1, 3, 6 или 12 месяцев) инвестор получает статус залогодержателя в отношении заложенной части портфеля и имеет право обратить взыскание на данные активы в случае неисполнения обязательств со стороны GEMLINE.',
    },
    {
      text: 'По факту инвестор становится созалогодержателем портфеля наряду с другими инвесторами, пропорционально размерам их вложений.',
    },
  ],
  bottomTexts: [
    'Привлеченные от инвесторов средства обеспечиваются залогом реальных активов в виде драгоценных камней из портфеля GEMLINE.',
    'Это позволяет минимизировать риски инвесторов и гарантировать возврат их вложений.',
  ],
};

export const guaranteedIncome = {
  title: 'Гарантии фиксированного дохода для инвесторов',
  button: {
    text: 'GEMLINE привлекает средства инвесторов на условиях выплаты фиксированного процента доходности, заранее оговоренного в договорах.',
    label: 'Подробнее',
    url: 'INVESTORS',
  },
  text: '<p>Средства используются для обеспечения операционной деятельности GEMLINE.</p><p>Применяемая бизнес-модель позволяет гарантировать инвесторам получение оговоренного фиксированного уровня доходности на вложенные средства при минимальных рисках.</p>',
  list: [
    {
      icon: suitcaseWhiteIcon,
      text: 'Диверсифицированный портфель снижает риски и обеспечивает стабильный рост стоимости активов.',
    },
    {
      icon: secondarySalesWhiteIcon,
      text: 'Залоговый механизм гарантирует инвесторам возврат средств и получение фиксированного процента прибыли.',
    },
    {
      icon: chartsWhiteIcon,
      text: 'Расширение портфеля за счет новых перспективных активов увеличивает потенциал для извлечения дохода.',
    },
  ],
};

export const operationalActivities = [
  {
    image: safeImage,
    text: 'Закупка, оформление и регистрация сырья для его дальнейшей транспортировки',
  },
  {
    image: boxImage,
    text: 'Логистические операции',
  },
  {
    image: brilliantInBoxFullImage,
    text: 'Обработка, огранка и сертификация камней',
  },
  {
    image: stackCoinsImage,
    text: 'Реализация готовых изделий',
  },
];

import { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Box, Button } from '@mui/material';
import { colorFetch, media, theme, useMediaType } from '@styles/index';

import gemDekorImage from '@assets/images/common/gem_dekor.png';
import latticeDecor from '@assets/images/common/lattice_decor_2.png';

const ContentWrapper = styled(Box)``;

const Container = styled.div`
  max-width: 1220px;
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;

  ${media.laptop} {
  }
`;

const Title = styled.h2`
  color: ${colorFetch('black')};
  font-family: Gilroy600, sans-serif;
  font-size: 48px;
  line-height: 120%;
  letter-spacing: -0.96px;

  ${media.tablet} {
    font-size: 32px;
    letter-spacing: -0.64px;
  }
`;

const CalculatorWrapper = styled.div`
  display: flex;
  gap: 20px;
  margin-top: 40px;

  ${media.tablet} {
    flex-direction: column;
    margin-top: 30px;
  }
`;

const InputBox = styled.div`
  flex: 1;
  background-color: ${colorFetch('white')};
  border-radius: 24px;
  padding: 32px;

  .title {
    color: ${colorFetch('gray2')};
    font-family: Gilroy500, sans-serif;
    font-size: 18px;

    &:last-of-type {
      margin-top: 40px;

      ${media.tablet} {
        margin-top: 30px;
      }
    }
  }

  ${media.laptop} {
    flex: 0 0 380px;
  }

  ${media.tablet} {
    padding: 20px;

    .title {
      font-size: 16px;
    }
  }
`;

const OutputBox = styled.div`
  flex: 0 0 480px;
  background: url(${gemDekorImage}) center right/contain no-repeat, url(${latticeDecor}) center right/contain no-repeat;
  background-color: ${colorFetch('green')};
  color: ${colorFetch('white')};
  border-radius: 24px;
  padding: 32px;

  ${media.laptop} {
    flex: 0 0 calc(100% - 400px);
  }

  ${media.tablet} {
    flex: 1;
    padding: 20px;
  }
`;

const Result = styled.div`
  display: flex;
  flex-direction: column;

  .title {
    color: ${colorFetch('white')};
    font-family: Gilroy500, sans-serif;
    font-size: 18px;
  }

  &:last-of-type {
    .title {
      margin-top: 42px;
    }
  }

  ${media.tablet} {
    gap: 8px;

    .title {
      font-size: 16px;

      &:last-of-type {
        margin-top: 20px;
      }
    }
  }
`;

const Value = styled.div`
  color: ${colorFetch('white')};
  font-family: Gilroy700, sans-serif;
  font-size: 48px;

  ${media.tablet} {
    font-size: 32px;
  }
`;

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid ${colorFetch('border')};
  background: ${colorFetch('white')};
  border-radius: 16px;
  padding: 20px;
  margin-top: 16px;
  line-height: 0;

  ${media.tablet} {
    padding: 14px 16px;
    margin-top: 10px;
  }
`;

const Input = styled.input`
  all: unset;
  width: 100%;
  color: ${colorFetch('black')};
  font-family: Gilroy600, sans-serif;
  font-size: 24px;

  ${media.tablet} {
    font-family: Gilroy500, sans-serif;
    font-size: 18px;
  }
`;

const Label = styled.div`
  color: ${colorFetch('gray2')};
  font-family: Gilroy500, sans-serif;
  font-size: 24px;
`;

const PeriodTriggers = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 20px;
  margin-top: 16px;

  ${media.tablet} {
    flex-wrap: wrap;
    gap: 12px;
  }
`;

const PeriodTrigger = styled(Button)`
  border-radius: 30px;
  background-color: ${colorFetch('green_background')};
  color: ${colorFetch('green')};
  padding: 14px 24px;
  box-shadow: unset;
  font-family: Gilroy500, sans-serif;
  font-size: 18px;
  line-height: 117%;
  text-transform: initial;

  &:hover {
    background-color: ${colorFetch('green')};
    color: ${colorFetch('white')};
  }

  &.active {
    background-color: ${colorFetch('green')};
    color: ${colorFetch('white')};
  }

  ${media.tablet} {
    padding: 12px 16px;
  }
`;

export const ProfitCalculator = ({ ...props }) => {
  const [calcPeriod, setCalcPeriod] = useState<number>(1);
  const [interestRate, setInterestRate] = useState<number>(0);
  const [investmentSum, setInvestmentSum] = useState<number>(1500);
  const [profitSum, setProfitSum] = useState<number>(0);

  const getInterestRate = (investment: number = 0, period: number = 1) => {
    switch (true) {
      case investment < 500:
        return ({ 1: 0.5, 3: 0.6, 6: 0.8, 12: 1 } as Record<number, number>)[period]; // 1 package
      case investment >= 500 && investment < 2500:
        return ({ 1: 0.7, 3: 0.8, 6: 1, 12: 1.2 } as Record<number, number>)[period]; // 2 package
      case investment >= 2500 && investment < 10000:
        return ({ 1: 0.8, 3: 0.9, 6: 1.1, 12: 1.4 } as Record<number, number>)[period]; // 3 package
      case investment >= 10000:
        return ({ 1: 0.9, 3: 1, 6: 1.2, 12: 1.6 } as Record<number, number>)[period]; // 4 package
      default:
        return 0;
    }
  };

  const investmentSumHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const re = /^[0-9\b]+$/;

    if (e.target.value === '' || re.test(e.target.value)) {
      setInvestmentSum(Number(e.target.value));
    }
  };

  const filterInvestmentHandler = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const re = /^[0-9\b]+$/;

    // Allow list of keys
    if (['Backspace', 'Delete', 'ArrowUp', 'ArrowRight', 'ArrowDown', 'ArrowLeft'].includes(e.key)) {
      return;
    }

    // Prevent the input if the key is not a number
    if (!re.test(e.key)) {
      e.preventDefault();
    }
  };

  useEffect(() => {
    const interestRate = getInterestRate(investmentSum, calcPeriod);
    setInterestRate(interestRate);
    setProfitSum(investmentSum * (interestRate / 100) * (30 * calcPeriod));
  }, [investmentSum, calcPeriod]);

  return (
    <ContentWrapper {...props}>
      <Container>
        <Title>Калькулятор дохода</Title>
        <CalculatorWrapper>
          <InputBox>
            <Title className="title">Сумма инвестиций</Title>
            <InputWrapper>
              <Input
                type="text"
                defaultValue={investmentSum}
                onChange={(e) => investmentSumHandler(e)}
                onKeyDown={filterInvestmentHandler}
                maxLength={10}
              />
              <Label>$</Label>
            </InputWrapper>
            <Title className="title">Период контракта</Title>
            <PeriodTriggers>
              <PeriodTrigger
                variant="contained"
                className={calcPeriod === 1 ? 'active' : ''}
                onClick={() => setCalcPeriod(1)}
              >
                1 месяц
              </PeriodTrigger>
              <PeriodTrigger
                variant="contained"
                className={calcPeriod === 3 ? 'active' : ''}
                onClick={() => setCalcPeriod(3)}
              >
                3 месяца
              </PeriodTrigger>
              <PeriodTrigger
                variant="contained"
                className={calcPeriod === 6 ? 'active' : ''}
                onClick={() => setCalcPeriod(6)}
              >
                6 месяцев
              </PeriodTrigger>
              <PeriodTrigger
                variant="contained"
                className={calcPeriod === 12 ? 'active' : ''}
                onClick={() => setCalcPeriod(12)}
              >
                12 месяцев
              </PeriodTrigger>
            </PeriodTriggers>
          </InputBox>
          <OutputBox>
            <Result>
              <Title className="title">Прибыль за день</Title>
              <Value>{`${interestRate} %`}</Value>
            </Result>
            <Result>
              <Title className="title">Общая прибыль</Title>
              <Value>{`${profitSum
                .toFixed(2)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')} $`}</Value>
            </Result>
          </OutputBox>
        </CalculatorWrapper>
      </Container>
    </ContentWrapper>
  );
};

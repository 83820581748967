import styled from '@emotion/styled';
import { getPath, View } from '@routes/index';
import { colorFetch } from '@styles/index';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as ArrowBack } from '@assets/images/common/arrow-left.svg';

const BackButtonWrapper = styled.div`
  cursor: pointer;
  position: absolute;
  z-index: 5;
  top: 30px;
  left: 0px;
  display: flex;
  gap: 10px;
`;

const Text = styled.span`
  font-family: Gilroy600;
  font-size: 14px;
  color: ${colorFetch('green')};
`;

interface BackButtonProps {}

export const BackButton: FC<BackButtonProps> = () => {
  const navigate = useNavigate();

  return (
    <BackButtonWrapper onClick={() => navigate(getPath((View as any).HOMEPAGE))}>
      <ArrowBack />
      <Text>Вернуться на сайт</Text>
    </BackButtonWrapper>
  );
};

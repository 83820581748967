import { FC } from 'react';
import styled from '@emotion/styled';
import { Box } from '@mui/material';
import { colorFetch, media } from '@styles/index';
import { GreenTextBox } from '@components/greenTextBox/GreenTextBox';

const ContentWrapper = styled(Box)`
  width: 100%;
  max-width: 1220px;
  margin: 40px auto 0;

  ${media.tablet} {
    margin: 30px auto 0;
  }
`;

const Container = styled.div`
  width: 100%;
  margin: 0 auto;
  background: ${colorFetch('white')};
  padding: 40px;
  border-radius: 30px;

  ${media.tablet} {
    padding: 20px;
  }
`;

const Image = styled.img`
  width: 100%;
  max-width: 260px;

  ${media.laptop} {
    margin-right: auto;
  }

  ${media.tablet} {
    max-width: 80px;
  }
`;

const TextsWrap = styled.div`
  max-width: 740px;
`;

const Text = styled.div`
  color: ${colorFetch('gray2')};
  font-family: Gilroy500, sans-serif;
  font-size: 18px;
  line-height: 150%;
  margin-bottom: 24px;

  &:first-of-type {
    margin-top: 32px;
  }

  &:nth-of-type(2) {
    color: ${colorFetch('black')};
    font-family: Gilroy600, sans-serif;
    font-size: 20px;
    line-height: 130%;
    letter-spacing: -0.4px;
  }

  &:last-of-type {
    margin-bottom: 0;
  }

  ${media.tablet} {
    margin-bottom: 20px;

    &:first-of-type {
      margin: 20px 0 20px;
    }
  }
`;

const Title = styled.div`
  max-width: 540px;
  color: ${colorFetch('black')};
  font-family: Gilroy600, sans-serif;
  font-size: 32px;
  line-height: 120%;
  letter-spacing: -0.64px;

  ${media.tablet} {
    font-size: 24px;
    letter-spacing: -0.48px;
  }
`;

const ContentBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;

  ${media.laptop} {
    flex-direction: column-reverse;
    align-items: flex-start;
  }
`;

const Description = styled.div``;

const GreenTextBoxStyled = styled(GreenTextBox)`
  padding: 0;

  ${media.tablet} {
    margin-top: 20px;

    .container {
      padding: 12px;
    }
  }
`;

interface DataType {
  image: string;
  title: string;
  texts: string[];
}

interface PropsType {
  maxtitlewidth?: string;
  data: DataType;
}

export const InvestmentPortfolio: FC<PropsType> = ({ data, ...props }) => {
  return (
    <ContentWrapper {...props}>
      <Container>
        <ContentBox>
          <Description>
            <Title>{data.title}</Title>
            <TextsWrap>
              {data.texts.map((el, i) => {
                return <Text key={i}>{el}</Text>;
              })}
            </TextsWrap>
          </Description>
          <Image src={data.image} />
        </ContentBox>
        <GreenTextBoxStyled text="В любой момент портфель GEMLINE представляет собой диверсифицированный набор активов, находящихся на разных стадиях готовности и обладающий сбалансированным профилем риска и доходности." />
      </Container>
    </ContentWrapper>
  );
};

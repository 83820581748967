import { useEffect } from 'react';
import styled from '@emotion/styled';
import { Box } from '@mui/material';
import { colorFetch, media, theme, useMediaType } from '@styles/index';
import Swiper from 'swiper';
import { Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import { ranksInfo } from './data';

import { ReactComponent as ArrowUp } from '@assets/images/common/fa_arrow_up.svg';

const ContentWrapper = styled(Box)`
  margin-top: 100px;

  ${media.tablet} {
    margin-top: 80px;
  }
`;

const Container = styled.div`
  max-width: 1180px;
  width: 100%;
  margin: 0 auto;

  ${media.laptop} {
    padding: 0 20px;
    overflow: hidden;
  }
`;

const Subject = styled.div`
  color: ${colorFetch('green')};
  font-family: Gilroy600, sans-serif;
  font-size: 20px;
  line-height: 140%;
  letter-spacing: -0.4px;
`;

const Title = styled.h2`
  max-width: 780px;
  color: ${colorFetch('altblack')};
  font-family: Gilroy600, sans-serif;
  font-size: 48px;
  line-height: 110%;
  letter-spacing: -0.96px;
  margin-top: 24px;

  ${media.laptop} {
    max-width: 780px;
  }

  ${media.tablet} {
    font-family: Gilroy600, sans-serif;
    font-size: 24px;
    line-height: 120%;
    letter-spacing: -0.48px;
    margin: 0;
  }
`;

const Description = styled.div`
  max-width: 680px;
  color: #7e828e;
  font-family: Gilroy500, sans-serif;
  font-size: 18px;
  line-height: 150%;
  margin-top: 32px;

  ${media.tablet} {
    margin-top: 16px;
    font-family: Gilroy500, sans-serif;
    font-size: 15px;
  }
`;

const RanksGrid = styled.div`
  ${media.tablet} {
    display: none;
  }
`;

const BoxesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 40px;
`;

const BoxItem = styled.div`
  flex: 1 1 calc((100% - 4 * 20px) / 5);
  border-radius: 20px;
  background: ${colorFetch('white')};
  padding: 20px;

  ${media.tablet} {
    flex: 1 1 100%;
  }
`;

const BoxImage = styled.div`
  display: flex;
  justify-content: center;
`;

const BoxTitle = styled.div`
  color: ${colorFetch('altblack')};
  font-family: Gilroy600, sans-serif;
  font-size: 20px;
  line-height: 120%;
  letter-spacing: -0.4px;
`;

const BoxIndexes = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 12px;
`;

const BoxReward = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
  > span:first-of-type {
    color: #7e828e;
    font-family: Gilroy500, sans-serif;
    font-size: 16px;
  }
  > span:last-of-type {
    color: ${colorFetch('altblack')};
    font-family: Gilroy700, sans-serif;
    font-size: 16px;

    ${media.tablet} {
      font-family: Gilroy600, sans-serif;
    }
  }
`;

const BoxVolume = styled(BoxReward)``;

const Divider = styled.div`
  border-top: 1px solid #ecedf0;
  margin: 16px 0;
`;

const RanksSlider = styled.div`
  display: none;

  ${media.tablet} {
    display: block;
    margin-top: 30px;
  }
`;

const SwiperWrapper = styled.div``;

const Slide = styled.div``;

const ArrowUpStyled = styled(ArrowUp)`
  width: 18px !important;
  height: 20px !important;

  ${media.tablet} {
    width: 14px !important;
    height: 15px !important;
  }
`;

const SliderNavigation = styled.div`
  display: none;
  margin-top: 20px;
  gap: 12px;

  ${media.tablet} {
    display: flex;
  }
`;

const NavigationButton = styled.div`
  position: static;
  border-radius: 100px;
  background: ${colorFetch('white')};
  width: 64px;
  height: 64px;
  margin: 0;
  z-index: 0;

  ${media.tablet} {
    width: 50px;
    height: 50px;
  }

  &:after {
    display: none;
  }
  &.swiper-button-next {
    svg {
      transform: rotate(180deg);
    }
  }
`;

const PrevSlideButton = styled(NavigationButton)``;

const NextSlideButton = styled(NavigationButton)``;

export const Ranks = () => {
  useEffect(() => {
    // init Swiper:
    const swiper = new Swiper('.swiper-ranks', {
      // configure Swiper to use modules
      slidesPerView: 1.45,
      spaceBetween: 20,
      loopAdditionalSlides: 1,
      // centeredSlides: true,
      loop: true,
      modules: [Navigation],
      navigation: {
        nextEl: '.rank-navigation-button.swiper-button-next',
        prevEl: '.rank-navigation-button.swiper-button-prev',
      },
    });
  }, []);

  return (
    <ContentWrapper>
      <Container>
        <Subject>Ранги</Subject>
        <Title>Ранг — ваш статус лидера, который определяется суммарным объемом привлеченных депозитов</Title>
        <Description>
          При достижении определенного объема привлеченных инвестиций вы получаете новый ранг и денежную награду за
          вклад в развитие GEMLINE
        </Description>
        <RanksGrid>
          <BoxesWrapper>
            {ranksInfo.map(({ Icon, title, reward, volume }, index) => {
              return (
                <BoxItem key={index}>
                  <BoxImage>
                    <Icon />
                  </BoxImage>
                  <Divider />
                  <BoxTitle>{title}</BoxTitle>
                  <BoxIndexes>
                    <BoxReward>
                      <span>Награда:</span>
                      <span>{reward}</span>
                    </BoxReward>
                    <BoxVolume>
                      <span>Объем:</span>
                      <span>{volume}</span>
                    </BoxVolume>
                  </BoxIndexes>
                </BoxItem>
              );
            })}
          </BoxesWrapper>
        </RanksGrid>
        <RanksSlider className="swiper-ranks">
          <SwiperWrapper className="swiper-wrapper">
            {ranksInfo.map(({ Icon, title, reward, volume }, index) => {
              return (
                <Slide className="swiper-slide" key={index}>
                  <BoxItem>
                    <BoxImage>
                      <Icon />
                    </BoxImage>
                    <Divider />
                    <BoxTitle>{title}</BoxTitle>
                    <BoxIndexes>
                      <BoxReward>
                        <span>Награда:</span>
                        <span>{reward}</span>
                      </BoxReward>
                      <BoxVolume>
                        <span>Объем:</span>
                        <span>{volume}</span>
                      </BoxVolume>
                    </BoxIndexes>
                  </BoxItem>
                </Slide>
              );
            })}
          </SwiperWrapper>
        </RanksSlider>
        <SliderNavigation>
          <PrevSlideButton className="rank-navigation-button swiper-button-prev">
            <ArrowUpStyled />
          </PrevSlideButton>
          <NextSlideButton className="rank-navigation-button swiper-button-next">
            <ArrowUpStyled />
          </NextSlideButton>
        </SliderNavigation>
      </Container>
    </ContentWrapper>
  );
};

import { FC } from 'react';
import { Box } from '@mui/material';
import { colorFetch, media } from '@styles/index';
import styled from '@emotion/styled';
// import { Helmet } from 'react-helmet';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Header } from '@components/header/Header';
import { Intro } from '@components/intro/Intro';
import { About } from '@components/about/About';
import { CallToActionLine } from '@components/CallToActionLine/CallToActionLine';
import { HowItWorks } from '@components/HowItWorks/HowItWorks';
import { Cards } from '@components/cards/Cards';
import { Chart } from '@components/Chart/Chart';
import { Benefits } from '@components/benefits/benefits';
import { Founder } from '@components/founder/founder';
import { Mission } from '@components/mission/mission';
import { Faq } from '@components/faq/faq';
import { Footer } from '@components/footer/Footer';
import { getPath, View } from '@routes/index';
import { useNavigate } from 'react-router-dom';

import { missionList } from './data';

import introImage from '@assets/images/intro/gem_in_hands.png';
import headBgDekor from '@assets/images/common/head_dekor.svg';
import chartHighIndexes from '@assets/images/common/chart_high_indexes.png';
import aboutBgDekor from '@assets/images/common/gem_dekor.svg';
import howItWorksBgDekor from '@assets/images/common/gem_dekor_2.svg';
import founderBgDekor from '@assets/images/common/gem_dekor_3.svg';
import faqBgDekor from '@assets/images/common/gem_dekor_4.svg';

const Wrapper = styled.div`
  position: relative;

  &:before {
    content: '';
    position: absolute;
    display: block;
    width: 82px;
    height: 216px;
    background: url(${headBgDekor}) no-repeat center;
    background-size: contain;
    top: 0;
    right: 0;
    /* z-index: 91; */

    ${media.tablet} {
      display: none;
    }
  }
`;

const AboutWrapper = styled.div`
  position: relative;

  &:before {
    content: '';
    position: absolute;
    display: block;
    width: 265px;
    height: 213px;
    background: url(${aboutBgDekor}) no-repeat center;
    background-size: cover;
    top: -70px;

    ${media.tablet} {
      display: none;
    }
  }
`;

const HowItWorksWrapper = styled.div`
  position: relative;

  &:before {
    content: '';
    position: absolute;
    display: block;
    width: 148px;
    height: 203px;
    background: url(${howItWorksBgDekor}) no-repeat center;
    background-size: contain;
    right: 0;
    bottom: -80px;

    ${media.tablet} {
      display: none;
    }
  }
`;

const FounderWrapper = styled.div`
  position: relative;

  &:before {
    content: '';
    position: absolute;
    display: block;
    width: 181px;
    height: 280px;
    background: url(${founderBgDekor}) no-repeat center;
    background-size: contain;
    left: 0;
    top: -170px;

    ${media.tablet} {
      display: none;
    }
  }
`;

const FaqWrapper = styled.div`
  position: relative;

  &:before {
    content: '';
    position: absolute;
    display: block;
    width: 130px;
    height: 186px;
    background: url(${faqBgDekor}) no-repeat center;
    background-size: contain;
    right: 0;
    bottom: -60px;

    ${media.tablet} {
      display: none;
    }
  }
`;

const CardsStyled = styled(Cards)`
  margin-top: 120px;

  ${media.tablet} {
    margin-top: 80px;
  }
`;

const GreyWrapper = styled.div`
  background-color: ${colorFetch('graybg')};
`;

const ContentWrapper = styled(Box)`
  width: 100%;
  flex: 1;

  ${media.tablet} {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const Container = styled.div`
  ${media.tablet} {
  }
`;

interface HomepageProps {}

export const Homepage: FC<HomepageProps> = ({}) => {
  const navigate = useNavigate();

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>GEMLINE - инвестиции в драгоценные камни</title>
          <meta
            name="description"
            content="🔹 GEMLINE - инновационная инвестиционная платформа нового поколения 💎 на рынке драгоценных камней. Начните зарабатывать на самом надежном активе!"
          />
        </Helmet>
      </HelmetProvider>
      <Wrapper>
        <ContentWrapper>
          <Container>
            <Header />
            <GreyWrapper>
              <Intro
                className="home_page"
                title="<span>Делаем доход</span> от драгоценных камней доступным для всех"
                description="Инвестируйте в реальные сокровища"
                image={introImage}
              />
              <AboutWrapper>
                <About />
              </AboutWrapper>
              <CallToActionLine
                title="Хватит недооценивать истинные сокровища планеты!"
                description="Присоединяйтесь к нашему сообществу инвесторов"
                buttonLabel="Начать инвестировать"
                onClick={() => navigate(getPath(View.AUTHENTICATION_LOGIN))}
              />
            </GreyWrapper>
            <HowItWorksWrapper>
              <HowItWorks />
            </HowItWorksWrapper>
            <CardsStyled
              className="gray"
              rowreverse={true}
              contentmaxwidth="740px"
              list={[
                {
                  icon: chartHighIndexes,
                  title:
                    'Драгоценные камни демонстрируют фантастическую динамику роста на протяжении десятилетий даже без GEMLINE',
                  description:
                    '<p>Но что происходит с камнем, когда его жизненный цикл находится под контролем GEMLINE?</p>',
                },
              ]}
            />
            <Chart />
            <Benefits title="Преимущества для инвестора" />
            <Benefits type="out-benefits" title="Преимущества для партнера" />
            <FounderWrapper>
              <Founder />
            </FounderWrapper>
            <Mission list={missionList} title="GEMLINE призван воплотить эту миссию" />
            <CallToActionLine
              themeMod="red"
              title="Инвестиционные камни могут сохранять и преумножать капитал при грамотном подходе"
              description="Наша миссия — предоставить эту возможность  широкому кругу инвесторов"
              ctaTitle="Присоединяйтесь!"
              buttonLabel="Регистрация"
              onClick={() => navigate(getPath(View.AUTHENTICATION_REGISTRATION))}
            />
            <FaqWrapper>
              <Faq />
            </FaqWrapper>
            <Footer />
          </Container>
        </ContentWrapper>
      </Wrapper>
    </>
  );
};

import { FC } from 'react';
import styled from '@emotion/styled';
import { Box } from '@mui/material';
import { colorFetch, media } from '@styles/index';

const ContentWrapper = styled(Box)`
  width: 100%;
  max-width: 1220px;
  margin: 130px auto 0;
  padding: 0 20px;

  ${media.tablet} {
    margin-top: 80px;
  }
`;

const Container = styled.div`
  width: 100%;
  margin: 0 auto;
`;

const Title = styled.h2`
  color: ${colorFetch('altblack')};
  font-family: Gilroy600, sans-serif;
  font-size: 48px;
  line-height: 120%;
  letter-spacing: -0.96px;

  ${media.tablet} {
    font-size: 32px;
    line-height: 120%;
    letter-spacing: -0.64px;
  }
`;

const Tiles = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 40px;

  ${media.tablet} {
    margin-top: 30px;
    gap: 12px;
  }
`;

const Tile = styled.div`
  display: flex;
  flex: 1 1 calc(50% - 10px);
  justify-content: space-between;
  gap: 24px;
  border-radius: 24px;
  background: ${colorFetch('graybg')};
  padding: 32px;

  ${media.laptop} {
    gap: 15px;
    min-width: 415px;
  }

  ${media.tablet} {
    min-width: unset;
    padding: 20px;

    svg image {
      & {
        display: none;
      }
      &.colored {
        display: block;
      }
    }
  }
`;

const TileTitle = styled.div<{
  hasDescription?: boolean;
}>`
  border-radius: 24px;
  background: ${colorFetch('graybg')};
  color: ${colorFetch('black_landing')};
  font-family: Gilroy600, sans-serif;
  font-size: 32px;
  line-height: 120%;
  letter-spacing: ${({ hasDescription }) => (hasDescription ? '-0.64px' : '-0.48px')};
  font-size: ${({ hasDescription }) => (hasDescription ? '32px' : '24px')};

  ${media.tablet} {
    font-size: ${({ hasDescription }) => (hasDescription ? '24px' : '18px')};
    letter-spacing: ${({ hasDescription }) => (hasDescription ? '-0.48px' : '-0.36px')};
  }
`;

const Content = styled.div``;

const TileIcon = styled.div<{
  iconWidth: string;
}>`
  flex: 0 0 ${({ iconWidth }) => iconWidth};

  ${media.tablet} {
    width: 80px;
    height: 80px;
    flex: 0 0 80px;

    svg {
      width: 80px;
      height: 80px;
    }
  }
`;

const Icon = styled.img`
  width: 100%;
`;

const Description = styled.div`
  color: ${colorFetch('dark_gray')};
  font-family: Gilroy500, sans-serif;
  font-size: 18px;
  line-height: 150%;
  margin-top: 37px;

  ${media.tablet} {
    margin-top: 12px;
    font-size: 15px;
  }
`;
interface MissionProps {
  title: string;
  list: Object;
}
interface Tile {
  icon: string;
  title: string;
  description: string;
}

export const Mission: FC<MissionProps> = ({ list, title }) => {
  return (
    <ContentWrapper>
      <Container>
        <Title>{title}</Title>
        <Tiles>
          {Object.values(list).map(({ title, icon, description }: Tile, index: number) => {
            return (
              <Tile key={index}>
                <Content>
                  <TileTitle hasDescription={!!description}>{title}</TileTitle>
                  <Description>{description}</Description>
                </Content>
                <TileIcon iconWidth={description ? '150px' : '120px'}>
                  <Icon src={icon} />
                </TileIcon>
              </Tile>
            );
          })}
        </Tiles>
      </Container>
    </ContentWrapper>
  );
};

import { useQuery } from '@tanstack/react-query';
import { HttpService } from '@utils/index';
import { API_URL } from 'src/consts';

interface useRegisteredUsersType {}

export const useRegisteredUsers = () => {
  return useQuery({
    queryKey: [],
    queryFn: () => HttpService.get(`${API_URL}/admin/registered-users`),
  });
};

import React, { FC, ReactNode } from 'react';
import styled from '@emotion/styled';
import { Box } from '@mui/material';
import { colorFetch, media } from '@styles/index';

const ContentWrapper = styled(Box)`
  &.keyPhrases {
    // not in use
    .advantageBox {
      justify-content: flex-end;
      border-radius: 24px;
      background: ${colorFetch('graybg')};
      flex: 1 0 calc(100% / 4 - 20px);
      min-width: 280px;
      padding: 24px;
      overflow: hidden;

      .imageWrapper {
        margin: 0 -24px 0 auto;

        img {
          width: 100%;
        }
      }

      &:nth-of-type(1) {
        .imageWrapper {
          max-width: 166px;
        }
      }

      &:nth-of-type(2) {
        .imageWrapper {
          max-width: 169px;
        }
      }

      &:nth-of-type(3) {
        .imageWrapper {
          margin: 0 -60px 0 auto;
          max-width: 190px;
        }
      }

      &:last-of-type {
        background-color: ${colorFetch('green')};

        .imageWrapper {
          margin: -30px -24px 0 auto;
        }

        .title,
        .subject {
          color: ${colorFetch('white')};
        }
      }

      .advantageContent {
        display: flex;
        flex-direction: column-reverse;
        height: 100%;
        max-width: unset;
      }

      .content {
        max-width: 880px;
      }

      .title {
        font-size: 24px;
        letter-spacing: -0.48px;
        margin-top: 16px;
      }
    }
    ${media.tablet} {
      margin-top: 30px;

      .advantages {
        gap: 12px;
      }
      .advantageBox {
        padding: 20px;

        .imageWrapper {
          flex: 0 0 90px;
        }

        .imageWrapper,
        .imageWrapper img {
          height: auto;
        }

        &:nth-of-type(3) {
          .imageWrapper {
            margin: 0 -38px 0 auto;
          }
        }
        &:last-of-type {
          .imageWrapper {
            margin: 12px -24px 0 auto;
          }
        }
        .advantageContent {
          flex-direction: row-reverse;
          align-items: flex-start;
        }
        .title {
          font-size: 18px;
          text-align: left;
          margin-top: 16px;
          letter-spacing: -0.36px;
        }
      }
    }
  }
  &.small {
    .advantageBox {
      min-width: unset;
      padding: 24px;

      .title {
        font-family: Gilroy600, sans-serif;
        font-size: 20px;
      }
      .description {
        font-size: 18px;
        margin-top: 16px;
      }
    }

    ${media.tablet} {
      .advantageBox {
        .title {
          font-size: 18px;
        }
        .description {
          font-size: 15px;
          margin-top: 12px;
        }
      }
    }
  }
  &.whyGem {
    .advantages {
      gap: 16px;
    }
    .advantageBox {
      .title {
        font-size: 24px;
      }
    }
    ${media.tablet} {
      .advantages {
        gap: 0;
      }
      .advantageBox {
        padding: 0;
        flex-direction: column;

        .advantageContent {
          flex-direction: row;
          align-items: center;
        }
        .imageWrapper {
          margin: 0;
          img {
            width: 60px;
            height: 60px;
          }
        }
        .title {
          font-size: 18px;
          letter-spacing: -0.36px;
        }

        &:not(:last-of-type):after {
          content: '';
          display: block;
          background-color: ${colorFetch('gray_border')};
          height: 1px;
          margin: 16px 0;
        }
      }
    }
  }
  &.insideInformation {
    .advantages {
      gap: 16px;
    }

    ${media.tablet} {
      .advantages {
        gap: 0;
      }
      .advantageBox {
        flex-direction: column;
        background-color: unset;
        padding: 0;

        &:not(:last-of-type):after {
          content: '';
          margin: 16px 0;
          border: 1px solid ${colorFetch('border')};
        }
      }
    }
  }
  &.uniqueAdvantages {
    ${media.tablet} {
      .advantages {
        gap: 0;
      }
      .advantageBox {
        padding: 0;
        flex-direction: column;

        .imageWrapper {
          width: 32px;
          height: 32px;
          flex: 0 0 32px;
          margin: 0;

          svg,
          img {
            width: 100%;
            height: 100%;
          }
        }
        .advantageContent {
          flex-direction: row;
          gap: 20px;
        }
        .description {
          margin: 0;
        }
        ${media.tablet} {
          &:not(:last-of-type):after {
            content: '';
            display: block;
            background-color: ${colorFetch('border')};
            height: 1px;
            margin: 16px 0;
          }
        }
      }
    }
  }
  &.functionsSeparation {
    .advantages {
      gap: 16px;

      .advantageBox {
        flex: 1 0 calc(100% / 4 - 16px);
        min-width: unset;
        .description {
          font-family: Gilroy500, sans-serif;
        }

        .imageWrapper {
          max-width: 100px;
          margin-bottom: 40px;

          img {
            width: 100%;
          }
        }
      }
    }
    ${media.tablet} {
      .advantages {
        flex-direction: column;
        gap: 0;

        .advantageBox {
          flex-direction: column;
          flex: 1;
          margin: 0;
          padding: 0;
          background-color: unset;

          .advantageContent {
            gap: 0 12px;
            align-items: center;
            flex-direction: row;
          }

          .description {
            margin: 0;
            font-size: 18px;
          }

          .imageWrapper {
            width: 60px;
            height: 60px;
            flex: 0 0 60px;
            margin-bottom: 0;

            img {
              width: 100%;
              height: 100%;
            }
          }

          &:not(:last-of-type):after {
            content: '';
            display: block;
            background-color: ${colorFetch('border')};
            height: 1px;
            margin: 16px 0;
          }
        }
      }
    }
  }
`;

const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  flex: 0 0 100px;
  margin-bottom: 20px;

  ${media.laptop} {
    flex: 0 1;
    margin-bottom: 0;
  }
`;

const Container = styled.div`
  max-width: 1180px;
  width: 100%;
  margin: 0 auto;
`;

const Advantages = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
`;

const AdvantageBox = styled.div<{
  itemBg?: string;
}>`
  display: flex;
  border-radius: 24px;
  background: ${({ itemBg }) => (itemBg ? colorFetch(itemBg) : colorFetch('white'))};
  width: 100%;
  padding: 32px;
  flex: 1 1 calc(100% / 3 - 20px);
  min-width: 380px;

  ${media.laptop} {
    flex-direction: column;
  }

  ${media.tablet} {
    padding: 20px;
    min-width: unset;
    flex: 1 1 100%;
  }
`;

const Image = styled.img`
  ${media.tablet} {
    width: 60px;
    height: 60px;
    margin-right: 12px;

    ${media.laptop} {
      margin-right: 0;
    }
    ${media.tablet} {
      width: 115px;
      height: 115px;
    }
  }
`;

const AdvantageContent = styled.div`
  max-width: 589px;
  width: 100%;

  ${media.laptop} {
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: space-between;
    max-width: 100%;
  }
  ${media.tablet} {
    flex-direction: row;
  }
`;

const Subject = styled.div`
  color: ${colorFetch('green')};
  font-family: Gilroy500, sans-serif;
  font-size: 20px;
  line-height: 150%;

  ${media.tablet} {
    font-size: 16px;
  }
`;

const AdvantageTitle = styled.h3`
  color: ${colorFetch('black')};
  font-family: Nunito600, sans-serif;
  font-size: 32px;
  line-height: 120%;
  letter-spacing: -0.64px;

  ${media.laptop} {
    max-width: 500px;
  }

  ${media.tablet} {
    color: ${colorFetch('black')};
    font-family: Gilroy600, sans-serif;
    font-size: 20px;
    line-height: 120%;
    letter-spacing: -0.4px;
    margin: 0;
  }
`;

const Content = styled.div`
  flex: 1;
  gap: 24px 0;
`;

const AdvantageDescription = styled.div`
  color: ${colorFetch('gray')};
  font-family: Gilroy500, sans-serif;
  font-size: 18px;
  line-height: 150%;
  margin-top: 24px;

  ${media.tablet} {
    margin-top: 12px;
    font-family: Gilroy500, sans-serif;
    font-size: 15px;

    &.main-advantage-item {
      margin-top: 20px;
    }
  }
`;

interface Card {
  image?: string | Object;
  title?: string;
  subject?: string;
  text?: string;
}

interface PropsType {
  className?: string;
  itemBg?: string;
  data: Card[];
}

export const GridBoxes: FC<PropsType> = ({ data, itemBg, ...props }) => {
  return (
    <ContentWrapper {...props}>
      <Container>
        <Advantages className="advantages">
          {data.map(({ image, subject, title, text }: Card, index: number) => {
            return (
              <AdvantageBox key={index} className="advantageBox" itemBg={itemBg}>
                <AdvantageContent className="advantageContent">
                  {image && (
                    <ImageWrapper className="imageWrapper">
                      {typeof image === 'string' ? (
                        <Image src={image as string} />
                      ) : (
                        React.createElement(image as React.FC)
                      )}
                    </ImageWrapper>
                  )}
                  <Content className="content">
                    {subject && <Subject className="subject">{subject}</Subject>}
                    {title && <AdvantageTitle className="title">{title}</AdvantageTitle>}
                    {text && <AdvantageDescription className="description">{text}</AdvantageDescription>}
                  </Content>
                </AdvantageContent>
              </AdvantageBox>
            );
          })}
        </Advantages>
      </Container>
    </ContentWrapper>
  );
};

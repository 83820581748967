import { FC } from 'react';
import styled from '@emotion/styled';
import { Box } from '@mui/material';
import { colorFetch, media } from '@styles/index';

import bgDekor from '@assets/images/common/bg_dekor.png';

const ContentWrapper = styled(Box)`
  width: 100%;
  max-width: 1220px;
  margin: 40px auto 0;
  padding: 0 20px;

  &.stableCoin {
    padding: 0;

    .text {
      font-size: 24px;
      letter-spacing: -0.48px;

      > p:last-child {
        margin-top: 20px;
      }
    }
    ${media.tablet} {
      padding: 0;

      .container {
        padding: 12px;

        .text {
          font-size: 18px;
          line-height: 130%;
          letter-spacing: -0.36px;
        }
      }
    }
  }

  ${media.tablet} {
    margin-top: 30px;
  }
`;

const Container = styled.div`
  position: relative;
  background-color: ${colorFetch('green')};
  padding: 40px;
  border-radius: 24px;

  ${media.tablet} {
    padding: 20px;
  }
`;

const Text = styled.div`
  position: relative;
  color: ${colorFetch('white')};
  font-family: Gilroy600, sans-serif;
  font-size: 32px;
  line-height: 130%;
  letter-spacing: -0.64px;
  z-index: 1;

  ${media.tablet} {
    font-size: 18px;
    line-height: 140%;
    letter-spacing: -0.36px;
  }
`;

const BgDekor = styled.img`
  position: absolute;
  width: 613px;
  height: 290px;
  top: -43px;
  right: 0;
`;

interface PropsType {
  className?: string;
  text: string;
}

export const GreenTextBox: FC<PropsType> = ({ text, ...props }) => {
  return (
    <ContentWrapper {...props}>
      <Container className="container">
        <Text dangerouslySetInnerHTML={{ __html: text }} className="text" />
        <BgDekor src={bgDekor} />
      </Container>
    </ContentWrapper>
  );
};

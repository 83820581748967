import { FC } from 'react';
import { Box } from '@mui/material';
import { colorFetch, media } from '@styles/index';
import styled from '@emotion/styled';
import { Helmet } from 'react-helmet';
import { Intro } from '@components/intro/Intro';
import { getPath, View } from '@routes/index';
import { useNavigate } from 'react-router-dom';
import { Footer, Header } from '@components/index';
import { GridBoxes } from '@components/gridBoxes/GridBoxes';
import { ProfitCalculator } from '@components/profitCalculator/ProfitCalculator';
import { CallToActionLine } from '@components/CallToActionLine/CallToActionLine';
import { NumberedGrid } from '@components/numberedGrid/NumberedGrid';
import { BoxContainer } from '@components/boxContainer/BoxContainer';
import { ProfitTable } from '@components/profitTable/ProfitTable';
import { Cards } from '@components/cards/Cards';
// import { ImplementCasesSlider } from '@components/implementCasesSlider/ImplementCasesSlider';
import { ChooseGemline } from '@components/chooseGemline/ChooseGemline';
import { ActivityAreas } from '@components/activityAreas/ActivityAreas';
import { LifecycleStages } from '@components/lifecycleStages/LifecycleStages';

import gemHandsImage from '@assets/images/intro/gem_hands.png';
import gemDekorAlt from '@assets/images/cta-line/gem-dekor-alt.png';
import gemDekorRed from '@assets/images/cta-line/gem-dekor-red-alt.png';
import gemDekorBlue from '@assets/images/cta-line/gem-dekor-blue.png';

import {
  advantagesList,
  gemsStabilityList,
  profitTableList,
  profitTableAdvantagesList,
  businessModelList,
} from './data';

const Wrapper = styled.div``;

const ContentWrapper = styled(Box)``;

const GreyWrapper = styled.div`
  background-color: ${colorFetch('graybg')};
`;

const ProfitCalculatorStyled = styled(ProfitCalculator)`
  margin-top: 120px;

  ${media.tablet} {
    margin-top: 80px;
  }
`;

const CallToActionLineStyled = styled(CallToActionLine)`
  margin-top: 150px;

  ${media.tablet} {
    margin-top: 80px;
  }
`;

const CallToActionLineBlue = styled(CallToActionLine)`
  margin-top: 150px;

  ${media.tablet} {
    margin-top: 46px;
  }
`;

const NumberedGridStyled = styled(NumberedGrid)`
  margin-top: 40px;

  .num {
    color: ${colorFetch('green')};
  }

  .title {
    color: ${colorFetch('altblack')};
  }

  .description {
    color: ${colorFetch('gray')};
    font-size: 18px;
  }
`;

const InvestmentSteps = styled(BoxContainer)`
  margin-top: 120px;

  ${media.tablet} {
    margin-top: 80px;
  }
`;

// const ImplementCasesSliderStyled = styled(ImplementCasesSlider)`
//   margin-top: 240px;
// `;

const BusinessModel = styled(BoxContainer)`
  margin-top: 120px;

  ${media.tablet} {
    margin-top: 80px;
  }
`;

const GemAdvantages = styled(BoxContainer)`
  margin-top: 100px;

  ${media.tablet} {
    margin-top: 40px;
  }
`;

const GridBoxesStyled = styled(GridBoxes)`
  margin-top: 40px;

  ${media.tablet} {
    margin-top: 30px;
  }
`;

interface InvestorsProps {}

export const Investors: FC<InvestorsProps> = ({}) => {
  const navigate = useNavigate();

  return (
    <>
      <Helmet>
        <title>Инвесторам — выгодные условия в GEMLINE</title>
        <meta
          name="description"
          content="🔹 Выгодные условия инвестирования в драгоценные камни 💎 на платформе GEMLINE. Начните получать доход 💰 уже сегодня!"
        />
      </Helmet>
      <Wrapper>
        <ContentWrapper>
          <Header />
          <GreyWrapper>
            <Intro
              title="Почему стоит инвестировать в цветные камни?"
              description="Ищете надежные инструменты приумножения капитала? Хотите защитить свои средства от инфляции и кризисов?"
              image={gemHandsImage}
            />
            <GemAdvantages title="Драгоценные камни — это">
              <GridBoxesStyled data={advantagesList} />
            </GemAdvantages>
            <ProfitCalculatorStyled />
            <CallToActionLineStyled
              title="GEMLINE делает инвестиции в редчайшие природные сокровища доступными каждому"
              image={gemDekorAlt}
              ctaTitle="Начните всего с $50!"
              buttonLabel="Начать инвестировать"
              onClick={() => navigate(getPath(View.AUTHENTICATION_REGISTRATION))}
            />
          </GreyWrapper>
          <InvestmentSteps
            title="Как инвестировать в GEMLINE?"
            text="Давайте разберем пошагово процесс инвестирования:"
          >
            <NumberedGridStyled list={gemsStabilityList} />
          </InvestmentSteps>
          <InvestmentSteps
            subject="Доходность инвестиций"
            title="Таблица доходов в зависимости от срока и суммы инвестирования"
            maxtitlewidth="756px"
          >
            <ProfitTable list={profitTableList} advantagesList={profitTableAdvantagesList} />
          </InvestmentSteps>
          <ActivityAreas title="Направления деятельности нашей компании" />
          <ChooseGemline title="Конкурентное преимущество GEMLINE" />
          <CallToActionLineBlue
            themeMod="blue"
            title="Вы становитесь совладельцем уникальных камней"
            description="и получаете доход от роста их стоимости. "
            image={gemDekorBlue}
            buttonLabel="Начать инвестировать"
            onClick={() => navigate(getPath(View.AUTHENTICATION_REGISTRATION))}
          />
          <GreyWrapper>
            <LifecycleStages title="Этапы жизненного цикла цветного камня" />
            {/* <ImplementCasesSliderStyled /> */}
            <BusinessModel title="Как появилась платформа GEMLINE">
              <Cards list={businessModelList} className="businessModel" />
            </BusinessModel>
            <CallToActionLine
              themeMod="centeredRed"
              containerwrapper={true}
              image={gemDekorRed}
              title="Присоединяйтесь к <br/>GEMLINE"
              description="И откройте для себя высокодоходный класс активов!"
              buttonLabel="Регистрация"
              onClick={() => navigate(getPath(View.AUTHENTICATION_REGISTRATION))}
            />
            <Footer />
          </GreyWrapper>
        </ContentWrapper>
      </Wrapper>
    </>
  );
};

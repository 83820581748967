export enum GeneralView {
  HOMEPAGE = 'HOMEPAGE',
  ABOUT_US = 'ABOUT_US',
  INVESTORS = 'INVESTORS',
  PARTNERS = 'PARTNERS',
  BLOG = 'BLOG',
  REGISTERED_USERS = 'REGISTERED_USERS',
  USER_DEPOSITS = 'USER_DEPOSITS',

  AUTHENTICATION = 'AUTHENTICATION',

  ERROR = 'ERROR',
}

export enum AuthenticationView {
  AUTHENTICATION_LOGIN = 'AUTHENTICATION_LOGIN',
  AUTHENTICATION_REGISTRATION = 'AUTHENTICATION_REGISTRATION',
  AUTHENTICATION_FORGOT_PASSWORD = 'AUTHENTICATION_FORGOT_PASSWORD',
  AUTHENTICATION_RESET_PASSWORD = 'AUTHENTICATION_RESET_PASSWORD',
}

export enum ErrorView {
  ERROR_404 = 'ERROR_404',
}

import { useState } from 'react';
import styled from '@emotion/styled';
import { Box } from '@mui/material';
import { colorFetch, media } from '@styles/index';
import { ChartsModal } from '@components/modal/modals/ChartsModal';
import { historiesVectorX, historiesVectorY, historiesList } from './data';
import { ChartLines } from './chartLines';

const ContentWrapper = styled(Box)`
  width: 100%;
  max-width: 1220px;
  margin: 120px auto;
  padding: 0 20px;

  ${media.tablet} {
    margin: 80px auto;
  }
`;

const Container = styled.div`
  gap: 40px;
  width: 100%;
  margin: 0 auto;

  ${media.tablet} {
    gap: 30px;
  }
`;

const Title = styled.h2`
  color: ${colorFetch('altblack')};
  font-family: Gilroy600, sans-serif;
  font-size: 48px;
  font-weight: 600;
  line-height: 120%;
  letter-spacing: -0.96px;

  ${media.laptop} {
    font-size: 32px;
    letter-spacing: -0.64px;
    font-size: 32px;
    letter-spacing: -0.64px;
  }
`;

const ChartWrapper = styled.div`
  padding: 0 76px 0 0px;
  margin-bottom: 30px;

  ${media.tablet} {
    padding: 30px 46px 0 0px;
    width: 100%;
  }
`;

const ChartContainer = styled.div`
  position: relative;
  display: flex;

  ${media.laptop} {
    width: 100%;
  }
`;

const ChartContainerCols = styled.div`
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  flex: 1;
  justify-content: space-between;
`;

const ChartContainerRows = styled.div`
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
`;

const ChartContainerRow = styled.div<{
  label?: string;
}>`
  display: flex;
  justify-content: flex-end;
  height: 2px;
  background: linear-gradient(
    90deg,
    rgba(239, 238, 243, 0) 0%,
    rgb(239 238 243 / 70%) 5.93%,
    rgb(239 238 243 / 70%) 93.04%,
    rgba(239, 238, 243, 0) 100%
  );

  &:first-of-type {
    margin-top: 3.4%;
    background: transparent;

    ${media.tablet} {
      margin-top: 9.4%;
      background: linear-gradient(
        90deg,
        rgba(239, 238, 243, 0) 0%,
        rgb(239 238 243 / 70%) 5.93%,
        rgb(239 238 243 / 70%) 93.04%,
        rgba(239, 238, 243, 0) 100%
      );
    }
  }

  &:last-of-type {
    margin-bottom: 5%;

    ${media.tablet} {
      margin-bottom: 7%;
    }
  }

  &:after {
    content: ${({ label }) => (label ? `"${label}"` : '')};
    min-width: 65px;
    transform: translate(100%, -50%);
    height: 20px;
    color: #aaa;
    text-align: right;
    font-family: Gilroy600, sans-serif;
    font-size: 14px;
    margin-right: -10px;
  }

  &.active:after {
    color: black;
  }

  ${media.tablet} {
    &:after {
      min-width: 56px;
      margin-right: 10px;
      font-size: 12px;
    }
  }
`;

const ChartContainerCol = styled.div<{
  label?: string;
}>`
  display: block;
  width: 2px;

  &:first-of-type {
    margin-left: 10.2%;
  }

  &:last-of-type {
    margin-right: 10.4%;
  }

  &:before {
    content: '';
    border-left: 2px solid;
    display: block;
    height: 100%;
    border-image: linear-gradient(
      180deg,
      rgba(239, 238, 243, 0) 0%,
      rgb(239 238 243 / 70%) 14.27%,
      rgb(239 238 243 / 70%) 85.77%,
      rgba(239, 238, 243, 0) 100%
    );
    border-image-slice: 1;
  }
  &:after {
    content: ${(props) => (props.label ? `"${props.label}"` : '')};
    display: block;
    white-space: nowrap;
    width: fit-content;
    transform: translateX(-50%);
    color: #aaa;
    text-align: right;
    font-family: Gilroy600, sans-serif;
    font-size: 14px;
    margin-top: 10px;
  }

  &.active:after {
    color: black;
  }

  ${media.tablet} {
    &:after {
      font-size: 10px;
      white-space: normal;
    }

    &:first-of-type {
      margin-left: 10.8%;
    }

    &:last-of-type {
      margin-right: 10.8%;
    }
  }
`;

const ChartContainerIndexes = styled.div`
  width: 100%;
  height: 100%;
`;

const TextBold = styled.div`
  color: ${colorFetch('black')};
  font-family: Gilroy600, sans-serif;
  font-size: 32px;
  line-height: 120%;
  letter-spacing: -0.64px;
  margin-top: 93px;

  ${media.tablet} {
    font-size: 24px;
    letter-spacing: -0.48px;
  }
`;

const Text = styled.div`
  max-width: 610px;
  color: ${colorFetch('gray2')};
  font-family: Gilroy500, sans-serif;
  font-size: 18px;
  line-height: 150%;
  margin-top: 32px;

  ${media.tablet} {
    font-size: 15px;
    margin-top: 24px;
  }
`;

export const Chart: React.FC = () => {
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  return (
    <ContentWrapper>
      <Container>
        <Title>Модель работы GEMLINE</Title>
        <ChartWrapper>
          <ChartContainer>
            <ChartContainerIndexes>
              <ChartLines setHoveredIndex={setHoveredIndex} setIsModalOpen={setIsModalOpen} />
            </ChartContainerIndexes>
            <ChartContainerRows>
              {historiesVectorY.map((el: any, index: number) => (
                <ChartContainerRow
                  key={index}
                  label={el}
                  className={hoveredIndex !== null && historiesList[hoveredIndex]?.vectorYvalue === el ? 'active' : ''}
                />
              ))}
            </ChartContainerRows>
            <ChartContainerCols>
              {historiesVectorX.map((el: any, index: number) => {
                return (
                  <>
                    <ChartContainerCol key={index} label={el} className={hoveredIndex === index ? 'active' : ''} />
                  </>
                );
              })}
            </ChartContainerCols>
          </ChartContainer>
        </ChartWrapper>
        <TextBold>
          В своей работе мы вынуждены мириться с тем, что на месторождениях готовы продавать только партии сырья.
          Большой объем сырых камней приходится банально «выбрасывать»
        </TextBold>
        <Text>
          Драгоценный камень, который был отобран и прошел полный жизненный цикл GEMLINE, дает доходность от х100
          и выше.
        </Text>
      </Container>
      <ChartsModal
        setHoveredIndex={setHoveredIndex}
        hoveredIndex={hoveredIndex}
        data={historiesList}
        onClose={() => setIsModalOpen(!isModalOpen)}
        isModalOpen={isModalOpen}
      />
    </ContentWrapper>
  );
};

import { ReactNode, FC } from 'react';
import styled from '@emotion/styled';
// import { Box } from '@mui/material';
import { colorFetch, media } from '@styles/index';
import { StyledModal } from '../StyledModal';

const ProfitTableContainer = styled.div``;

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 12px 0;

  &:not(:last-of-type):after {
    content: '';
    display: block;
    width: 100%;
    border-bottom: 1px solid ${colorFetch('gray1')};
    margin-bottom: 12px;
  }

  .colValue {
    flex: 1 0;

    &:first-of-type {
      flex: 1 0 100%;
    }
    &:nth-of-type(2) {
      min-width: 120px;
    }
  }
`;

const ColValue = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;

  &:first-child {
    .value {
      font-family: Gilroy600, sans-serif;
    }
  }
`;

const Label = styled.div`
  color: ${colorFetch('gray1')};
  font-family: Gilroy500, sans-serif;
  font-size: 12px;
`;

const Value = styled.div`
  color: ${colorFetch('black')};
  font-family: Gilroy500, sans-serif;
  font-size: 16px;
`;

interface YourItemType {
  investmentSum: string;
  interestRatePerDay: string;
  interestRatePerMonth: string;
  interestRatePerYear: string;
}

interface DataType {
  period: string;
  list: YourItemType[];
}

interface PropsType {
  maxtitlewidth?: string;
  isModalOpen: boolean;
  onClose: () => void;
  subject?: string;
  text?: string;
  data: DataType;
}

export const ProfitTableModal: FC<PropsType> = ({
  subject,
  text,
  maxtitlewidth,
  isModalOpen,
  onClose,
  data,
  ...props
}) => {
  return (
    <StyledModal title={`Инвестиции на ${data.period}`} open={isModalOpen} onClose={onClose} {...props}>
      <ProfitTableContainer>
        {Object.values(data.list).map(
          (
            { investmentSum, interestRatePerDay, interestRatePerMonth, interestRatePerYear }: YourItemType,
            index: number,
          ) => {
            return (
              <Row key={index}>
                <ColValue className="colValue">
                  <Label className="label">Сумма инвестиции</Label>
                  <Value className="value">{investmentSum}</Value>
                </ColValue>
                <ColValue className="colValue">
                  <Label className="label">% прибыли за день</Label>
                  <Value className="value">{interestRatePerDay}</Value>
                </ColValue>
                <ColValue className="colValue">
                  <Label className="label">% за месяц</Label>
                  <Value className="value">{interestRatePerMonth}</Value>
                </ColValue>
                <ColValue className="colValue">
                  <Label className="label">% за год</Label>
                  <Value className="value">{interestRatePerYear}</Value>
                </ColValue>
              </Row>
            );
          },
        )}
      </ProfitTableContainer>
    </StyledModal>
  );
};

import React from 'react';
import styled from '@emotion/styled';
import { Box } from '@mui/material';
import { colorFetch, media, useMediaType } from '@styles/index';
import { whyGemlinelist } from './data';

import { ReactComponent as WhiteBigLogo } from '@assets/images/common/logo/white_big_logo.svg';
import { ReactComponent as WhiteBigLogoHorizontal } from '@assets/images/common/logo/white_big_logo_horizontal.svg';
import latticeDecorImage from '@assets/images/common/lattice_decor.png';

const ContentWrapper = styled(Box)`
  margin-top: 113px;

  ${media.laptop} {
    margin-top: 80px;
    overflow: hidden;
  }
`;

const Container = styled.div`
  position: relative;
  max-width: 1180px;
  width: 100%;
  margin: 0 auto;

  ${media.laptop} {
    padding: 0 20px;
  }
`;

const Title = styled.h3`
  width: 100%;
  max-width: 756px;
  color: ${colorFetch('altblack')};
  font-family: Gilroy600, sans-serif;
  font-size: 48px;
  line-height: 110%;
  letter-spacing: -0.96px;

  ${media.laptop} {
    font-size: 32px;
    letter-spacing: -0.64px;
  }

  ${media.tablet} {
    color: var(--Black, #35343d);
    font-size: 32px;
    line-height: 120%;
    letter-spacing: -0.64px;
  }
`;

const Image = styled.img`
  ${media.tablet} {
    width: 32px;
    height: 32px;
  }
`;

const TilesWrapper = styled.div`
  display: flex;
  position: relative;
  display: flex;
  gap: 20px;
  margin-top: 57px;

  ${media.laptop} {
    flex-wrap: wrap;
  }

  ${media.tablet} {
    flex-direction: column;
    gap: 0;
    margin-top: 30px;

    .logoCol {
      order: 1;
      padding: 20px;
    }
    .listCol {
      order: 2;

      &:first-of-type {
        margin-top: 12px;
      }
    }
  }
`;

const TilesCol = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  &:nth-of-type(1) {
    flex: 0 0 380px;
  }
  &:nth-of-type(2) {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    border-radius: 24px;
    background: ${colorFetch('green')} url(${latticeDecorImage}) no-repeat center center / contain;
  }
  &:nth-of-type(3) {
    flex: 0 0 380px;
  }

  ${media.laptop} {
    &:first-of-type {
      order: 2;
      flex: 1 1 calc(100% / 2 - 20px);
    }
    &:nth-of-type(2) {
      order: 1;
      flex: 1 1 100%;
    }
    &:last-of-type {
      order: 3;
      flex: 1 1 calc(100% / 2 - 20px);
    }
  }

  ${media.tablet} {
    flex: 1;
    gap: 0;
    background-color: ${colorFetch('graybg')};
    padding: 20px;

    &:nth-of-type(1) {
      flex: 1;
      padding-bottom: 0;
      border-radius: 24px 24px 0 0;

      .tileListItem {
        &:first-of-type {
          padding-top: 0;
        }
      }
    }
    &:nth-of-type(2) {
      background: ${colorFetch('green')};
    }
    &:nth-of-type(3) {
      flex: 1;
      padding-top: 0;
      border-radius: 0 0 24px 24px;

      .tileListItem {
        &:last-of-type {
          border-bottom: 0;
          padding-bottom: 0;
        }
      }
    }
  }
`;

const Tile = styled.div`
  border-radius: 16px;
  border-radius: 24px;
  background: ${colorFetch('graybg')};
  box-shadow: 0px -2px 4px 0px ${colorFetch('greyShadow')} inset;
  flex: 1 1 calc(100% / 4 - 16px * 3 / 4);
  padding: 23px;
  min-height: 190px;

  ${media.laptop} {
    display: flex;
    align-items: flex-start;
    gap: 20px;
    min-width: 356px;
    min-height: unset;
  }

  ${media.tablet} {
    padding: 16px 0;
    box-shadow: unset;
    border-bottom: 1px solid ${colorFetch('white')};
    border-radius: 0;
  }

  ${media.phone} {
    min-width: unset;
  }
`;

const TileDescription = styled.div`
  margin-top: 21px;
  color: ${colorFetch('dark_gray')};
  font-family: Gilroy500, sans-serif;
  font-size: 18px;
  line-height: 150%;

  ${media.laptop} {
    margin: 0;
  }

  ${media.tablet} {
    margin: 0;
    font-size: 15px;
  }
`;

interface ChildComponentProps {
  type?: string;
  title: string;
}

export const ActivityAreas: React.FC<ChildComponentProps> = ({ type, title }) => {
  const { tablet: isTablet, phone: isPhone } = useMediaType();

  // Filter the odd numbers
  const oddItems = whyGemlinelist.filter((item, index) => index % 2 !== 0);
  // Filter the odd numbers
  const evenItems = whyGemlinelist.filter((item, index) => index % 2 === 0);

  return (
    <ContentWrapper>
      <Container>
        <Title>{title}</Title>
        <TilesWrapper>
          <TilesCol className="listCol">
            {!type &&
              evenItems.map(({ icon, text }, index: number) => {
                return (
                  <Tile key={index} className="tileListItem">
                    <Image src={icon} />
                    <TileDescription dangerouslySetInnerHTML={{ __html: text }} />
                  </Tile>
                );
              })}
          </TilesCol>
          <TilesCol className="logoCol">{!isTablet ? <WhiteBigLogo /> : <WhiteBigLogoHorizontal />}</TilesCol>
          <TilesCol className="listCol">
            {!type &&
              oddItems.map(({ icon, text }, index: number) => {
                return (
                  <Tile key={index} className="tileListItem">
                    <Image src={icon} />
                    <TileDescription dangerouslySetInnerHTML={{ __html: text }} />
                  </Tile>
                );
              })}
          </TilesCol>
        </TilesWrapper>
      </Container>
    </ContentWrapper>
  );
};

import styled from '@emotion/styled';
import { colorFetch, media, theme } from '@styles/index';
import { useId } from 'react';
import Collapsible from 'react-collapsible';

const CollapsedListStyled = styled.div``;

const Item = styled.div`
  position: relative;
  margin-bottom: 12px;
  background: linear-gradient(180deg, rgba(232, 236, 243, 0.05) 0%, rgba(232, 236, 243, 0) 100%);
  background-origin: border-box;
  border: 1px solid transparent;
  border-radius: 16px;
  overflow: hidden;
  &:last-of-type {
    margin-bottom: 0;
  }
  &:before {
    background: ${colorFetch('graybg')};
    display: block;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }
`;

const CollapsibleWrapper = styled.div`
  position: relative;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0) 100%);

  .title {
    position: relative;
    display: flex;
    flex-direction: row-reverse;
    color: ${colorFetch('black_landing')};
    font-family: Gilroy600, sans-serif;
    font-size: 18px;
    font-style: normal;
    user-select: none;
    cursor: pointer;
    padding: 20px;

    ${media.tablet} {
      font-size: 16px;
    }
  }

  ul {
    padding-left: 18px;
    margin-bottom: 20px;
  }

  .panelOpen {
    .title {
      border-bottom: 1px solid #e0dee9;
    }

    .titleIcon {
      background: ${colorFetch('green')};
    }

    .titleIcon:before,
    .titleIcon:after {
      background: ${colorFetch('white')};
    }

    .titleIcon:after {
      transform: rotate(180deg);
    }
  }
`;

const TitleIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  margin-left: 20px;
  position: relative;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: ${colorFetch('white')};

  &:before,
  &:after {
    content: '';
    position: absolute;
    width: 20px;
    height: 2px;
    background-color: ${colorFetch('green')};
    transition: 0.2s transform ease-in-out;
  }
  &:after {
    transform: rotate(90deg);
  }

  ${media.tablet} {
    width: 32px;
    height: 32px;

    &:before,
    &:after {
      width: 12px;
    }
  }
`;

const TitleLabel = styled.div`
  align-self: center;
  flex-grow: 1;
  flex-shrink: 1;
`;

const Drop = styled.div`
  position: relative;
  color: ${colorFetch('black_landing')};
  font-family: Gilroy400, sans-serif;
  font-size: 18px;
  line-height: 150%;
  padding: 20px;
  ${media.phone} {
    padding: 25px 20px 0;
  }

  p {
    margin: 0 0 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }

  ${media.tablet} {
    font-size: 15px;
  }
`;

interface ChildComponentProps {
  data: Array<{ title: string; text: string }>;
}

export const CollapsedList: React.FC<ChildComponentProps> = ({ data }) => {
  const id = useId();

  return (
    <CollapsedListStyled>
      {data.map(({ title, text }, index: number) => (
        <Item key={`${index}-collapsed-list`}>
          <CollapsibleWrapper>
            <Collapsible
              className="panel"
              openedClassName="panelOpen"
              triggerClassName="title"
              triggerOpenedClassName="title"
              trigger={
                <>
                  <TitleIcon className="titleIcon" />
                  <TitleLabel dangerouslySetInnerHTML={{ __html: title }} />
                </>
              }
              transitionTime={200}
              contentElementId={`collapsible-content-${id}`}
              triggerElementProps={{
                id: `collapsible-trigger-${id}`,
              }}
            >
              {text && <Drop dangerouslySetInnerHTML={{ __html: text }} />}
            </Collapsible>
          </CollapsibleWrapper>
        </Item>
      ))}
    </CollapsedListStyled>
  );
};

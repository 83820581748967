import { useResetPassword } from '@api/index';
import {
  AuthFormsBox,
  EndAdornment,
  FormsFieldsBox,
  FormsHeading,
  FormsSubmitButton,
  InputWithEndAdornment,
  Logo,
} from '@components/index';
import styled from '@emotion/styled';
import { colorFetch } from '@styles/index';
import { useGetQueryFromURL } from '@utils/index';
import { sha256 } from 'js-sha256';
import { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { ReactComponent as ShowHidePass } from '@assets/images/common/show_hide_password.svg';
import { getPath, View } from '@routes/index';
import { useNavigate } from 'react-router-dom';

const defaultValues = {
  new_password: '',
  repeat_password: '',
};

const StyledShowHidePass = styled(ShowHidePass)`
  cursor: pointer;

  & g path {
    fill: ${colorFetch('gray1')};
    transition: all 0.2s linear;
  }

  &.active {
    & g {
      opacity: 1;
    }

    & g path {
      fill: ${colorFetch('green')};
    }
  }

  &:hover {
    & g path {
      fill: ${colorFetch('green')};
    }
  }
`;

export const ErrorInfoText = styled.span`
  display: inline-block;
  margin-top: 20px;
  width: 100%;
  text-align: center;
  color: ${colorFetch('red')};
  font-family: Gilroy400;
  font-size: 16px;
`;

interface IFormInput {
  new_password: string;
  repeat_password: string;
}

interface ResetPasswordFormProps {
  setIsComplete: (data: boolean) => void;
}

export const ResetPasswordForm: FC<ResetPasswordFormProps> = ({ setIsComplete }) => {
  const queryParams = useGetQueryFromURL('recovery_hash');

  const navigate = useNavigate();

  const [timerCounter, setTimerCounter] = useState(5);

  const [commonError, setCommonError] = useState<string>('');
  const [showPassword, setShowPassword] = useState(false);
  const [showRepeatPassword, setShowRepeatPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowRepeatPassword = () => setShowRepeatPassword((show) => !show);

  const { handleSubmit, register, formState, watch } = useForm<IFormInput>({ defaultValues });

  const passwordValue = watch('new_password');

  const { errors } = formState;

  const resetPasswordSuccess = (data: any) => {
    setIsComplete(true);
  };

  const resetPasswordError = ({ response }: any) => {
    if (response.data === 'invalid hash') {
      setCommonError(
        `Вы воспользовались некорректной ссылкой для сброса пароля.\nВы будете перенаправлены на страницу авторизации через `,
      );
    }
  };

  const resetPassword = useResetPassword({
    onSuccess: resetPasswordSuccess,
    onError: resetPasswordError,
  });

  useEffect(() => {
    let intervalId: string | number | NodeJS.Timeout | undefined;

    if (commonError) {
      intervalId = setInterval(() => {
        setTimerCounter((prevCounter) => {
          if (prevCounter <= 1) {
            clearInterval(intervalId);
            navigate(getPath(View.AUTHENTICATION_LOGIN));
          }
          return prevCounter - 1;
        });
      }, 1000);
    }

    return () => clearInterval(intervalId); // Очистка интервала при размонтировании компонента
  }, [commonError]);

  const onSubmit = (data: IFormInput) => {
    const { new_password } = data;

    const encryptedPassword = sha256(new_password);

    resetPassword({
      NewPassword: encryptedPassword,
      RecoveryHash: queryParams?.recovery_hash,
    });
  };

  return (
    <AuthFormsBox onSubmit={handleSubmit(onSubmit)} noValidate>
      <Logo />
      <FormsHeading>Новый пароль</FormsHeading>
      <FormsFieldsBox>
        <InputWithEndAdornment
          label={'Новый пароль'}
          placeholder={'Введите новый пароль'}
          type={showPassword ? 'text' : 'password'}
          name={'new_password'}
          error={!!errors.new_password}
          helperText={errors.new_password?.message}
          rules={{
            ...register('new_password', {
              required: 'Обязательное поле',
              minLength: {
                value: 8,
                message: 'Пароль должен быть длиннее 8 символов',
              },
              maxLength: {
                value: 64,
                message: 'Пароль должен быть меньше 64 символов',
              },
              pattern: {
                value: /^[A-Za-z0-9~`!@#$%^&*()_\-+={[}\]|:;"'<,>.?/]+$/,
                message: 'Пароль может содержать латинские буквы, цифры и некоторые специальные символы',
              },
            }),
          }}
          endAdornment={
            <EndAdornment
              state={showPassword}
              handleClickIcon={handleClickShowPassword}
              icon1={<StyledShowHidePass className={showPassword && 'active'} />}
              icon2={<StyledShowHidePass className={showPassword && 'active'} />}
            />
          }
        />
        <InputWithEndAdornment
          label={'Повторите пароль'}
          placeholder={'Повторите пароль'}
          type={showRepeatPassword ? 'text' : 'password'}
          name={'repeat_password'}
          error={!!errors.repeat_password}
          helperText={errors.repeat_password?.message}
          rules={{
            ...register('repeat_password', {
              required: 'Обязательное поле',
              validate: (value) => value === passwordValue || 'Пароли не совпадают',
            }),
          }}
          endAdornment={
            <EndAdornment
              state={showRepeatPassword}
              handleClickIcon={handleClickShowRepeatPassword}
              icon1={<StyledShowHidePass className={showRepeatPassword && 'active'} />}
              icon2={<StyledShowHidePass className={showRepeatPassword && 'active'} />}
            />
          }
        />
      </FormsFieldsBox>
      <FormsSubmitButton type={'submit'} variant={'contained'}>
        Сохранить
      </FormsSubmitButton>
      {commonError && <ErrorInfoText>{commonError + timerCounter + '...'}</ErrorInfoText>}
    </AuthFormsBox>
  );
};

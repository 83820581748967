import { FC } from 'react';
import styled from 'styled-components';
import { Box } from '@mui/material';
import { colorFetch, media } from '@styles/index';

import timelineHistoryImage from '@assets/images/about_us/timeline_history.png';

const ContentWrapper = styled(Box)`
  width: 100%;
  max-width: 1220px;
  margin: 120px auto 0;
  padding: 0 20px;

  ${media.tablet} {
    margin-top: 80px;
  }
`;

const Container = styled.div`
  position: relative;
  display: flex;
  gap: 60px;

  ${media.tablet} {
    gap: 0;
    flex-direction: column;
  }
`;

const Title = styled.div`
  max-width: 680px;
  color: ${colorFetch('black')};
  font-family: Gilroy600, sans-serif;
  font-size: 48px;
  line-height: 120%;
  letter-spacing: -0.96px;

  ${media.tablet} {
    font-size: 32px;
    letter-spacing: -0.64px;
  }
`;

const Col = styled.div`
  flex: 1 1 50%;
`;

const ListWrapper = styled.div`
  /* border-left: 4px dashed ${colorFetch('green')}; */
  background-image: linear-gradient(to right, ${colorFetch('green')} 50%, transparent 50%),
    linear-gradient(to right, ${colorFetch('green')} 50%, transparent 50%),
    linear-gradient(to bottom, ${colorFetch('green')} 50%, transparent 50%),
    linear-gradient(to bottom, ${colorFetch('green')} 50%, transparent 50%);
  background-position: left top, left bottom, left top, right top;
  background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
  background-size: 0, 0, 4px 35px, 0;

  ${media.tablet} {
    margin-top: 30px;
    margin-left: 20px;
  }
`;

const List = styled.div`
  counter-reset: line-number;
`;

const Image = styled.img`
  max-width: 480px;
  width: 100%;
  margin-top: 120px;

  ${media.tablet} {
    max-width: 220px;
    width: 100%;
    margin-top: 30px;
  }
`;

const BoxItem = styled.div`
  position: relative;
  border-radius: 24px;
  background: ${colorFetch('graybg')};
  box-shadow: 0px -2px 4px 0px ${colorFetch('greyShadow')} inset;
  padding: 24px;
  margin: 60px 0 0 100px;
  counter-increment: line-number;

  ${Title} {
    color: ${colorFetch('green')};
    font-family: Gilroy600, sans-serif;
    font-size: 32px;
    line-height: 120%;
    letter-spacing: -0.64px;
  }

  &:after {
    position: absolute;
    content: counter(line-number);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    padding: 20px;
    background-color: ${colorFetch('green')};
    border-radius: 100%;
    color: ${colorFetch('white')};
    font-family: Gilroy600, sans-serif;
    font-size: 32px;
    line-height: 120%;
    letter-spacing: -0.64px;
    top: 0;
    left: -100px;
    transform: translateX(-50%);
  }

  &:last-of-type {
    &:after {
      top: unset;
      bottom: 0;
    }
  }

  ${media.tablet} {
    margin: 30px 0 0 33px;
    padding: 12px;

    &:after {
      width: 20px;
      height: 20px;
      padding: 10px;
      font-size: 18px;
      letter-spacing: -0.36px;
      left: -32px;
    }

    ${Title} {
      font-size: 18px;
      letter-spacing: -0.36px;
    }
  }
`;

const SubList = styled.ul`
  margin: 14px 0 0 18px;
`;

const SubListItem = styled.li`
  color: ${colorFetch('gray2')};
  font-family: Gilroy500, sans-serif;
  font-size: 18px;
  line-height: 140%;
  letter-spacing: -0.36px;

  ${media.tablet} {
    font-size: 15px;
    letter-spacing: -0.3px;
  }
`;

interface PropsType {
  list: Object;
}

interface ListType {
  title: string;
  list: [];
}

export const ProjectHistory: FC<PropsType> = ({ list }) => {
  return (
    <>
      <ContentWrapper>
        <Container>
          <Col>
            <Title>История проекта</Title>
            <Image src={timelineHistoryImage} />
          </Col>
          <Col>
            <ListWrapper>
              <List>
                {Object.values(list).map(({ title, list }: ListType, index: number) => {
                  return (
                    <BoxItem key={index}>
                      <Title>{title}</Title>
                      <SubList>
                        {list.map((item, index: number) => {
                          return <SubListItem key={index}>{item}</SubListItem>;
                        })}
                      </SubList>
                    </BoxItem>
                  );
                })}
              </List>
            </ListWrapper>
          </Col>
        </Container>
      </ContentWrapper>
    </>
  );
};

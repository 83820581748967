import { CallbackData, UseApiMutationOptions } from '@api/types';
import { useMutation } from '@tanstack/react-query';
import { HttpService } from '@utils/http';
import { API_URL } from 'src/consts';

interface RegistrationData {
  Name: string;
  Login: string;
  Password: string;
  ReferralCode?: string;
  Host: string;
}

export const useRegistration = ({ onSuccess, onError }: UseApiMutationOptions) => {
  const { mutate } = useMutation<CallbackData, CallbackData, RegistrationData, unknown>({
    mutationFn: (data) => {
      return HttpService.post(`${API_URL}/registration`, data);
    },
    onSuccess,
    onError,
  });

  return mutate;
};

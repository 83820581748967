import { FC } from 'react';
import styled from '@emotion/styled';
import { Box } from '@mui/material';
import { colorFetch, media } from '@styles/index';
import { GridBoxes } from '@components/gridBoxes/GridBoxes';
import { GreenTextBox } from '@components/greenTextBox/GreenTextBox';

import gemHandsImage from '@assets/images/common/lightning.png';

const ContentWrapper = styled(Box)`
  width: 100%;
  max-width: 1220px;
  margin: 0 auto;
`;

const Container = styled.div`
  width: 100%;
  margin: 0 auto;
  background: ${colorFetch('white')};
  padding: 40px;
  border-radius: 30px;

  ${media.tablet} {
    padding: 20px;
  }
`;

const Image = styled.img`
  width: 100%;
  max-width: 160px;

  ${media.laptop} {
    margin-right: auto;
  }

  ${media.tablet} {
    width: 80px;
  }
`;

const Text = styled.div``;

const Title = styled.div`
  max-width: 640px;
  color: ${colorFetch('black')};
  font-family: Gilroy600, sans-serif;
  font-size: 32px;
  line-height: 120%;
  letter-spacing: -0.64px;

  ${media.tablet} {
    font-size: 24px;
    letter-spacing: -0.48px;
  }
`;

const ContentBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${media.tablet} {
    flex-direction: column-reverse;
    gap: 20px;
  }
`;

const Description = styled.div`
  max-width: 848px;
  color: ${colorFetch('gray2')};
  font-family: Gilroy500, sans-serif;
  font-size: 18px;
  line-height: 150%;
  margin-top: 32px;

  ${media.tablet} {
    font-size: 15px;
    margin-top: 20px;
  }
`;

const GridWrapper = styled.div``;

const GridBoxesStyled = styled(GridBoxes)`
  margin-top: 32px;
`;

const GreenTextBoxStyled = styled(GreenTextBox)`
  padding: 0;
  margin-top: 32px;

  .container {
    padding: 32px;
  }

  ${media.tablet} {
    .container {
      padding: 12px;
    }
  }
`;

interface DataType {
  title: string;
  text: string;
}

interface PropsType {
  maxtitlewidth?: string;
  data: DataType;
}

export const InvestmentСapital: FC<PropsType> = ({ data, ...props }) => {
  return (
    <ContentWrapper {...props}>
      <Container>
        <ContentBox>
          <Text>
            <Title>{data.title}</Title>
            <Description>{data.text}</Description>
          </Text>
          <Image src={gemHandsImage} className="image" />
        </ContentBox>
        <GreenTextBoxStyled text="На фоне истощения природных месторождений растет глобальный спрос со стороны коллекционеров и инвесторов. Гарантия интереса к активу — цена на драгоценные камни растет значительно быстрее уровня инфляции." />
      </Container>
    </ContentWrapper>
  );
};

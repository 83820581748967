import styled from '@emotion/styled';
import { Box, Button, Link, Typography } from '@mui/material';
import { colorFetch, media, theme } from '@styles/index';
import { CollapsedList } from '@components/collapsedList/collapsedList';
import { list } from './data';

const ContentWrapper = styled(Box)`
  width: 100%;
  max-width: 1220px;
  margin: 120px auto 0;
  padding: 0 20px;

  ${media.tablet} {
    margin-top: 80px;
  }
`;

const Container = styled.div`
  width: 100%;
  margin: 0 auto;
`;

const FaqListWrapper = styled.div`
  margin-top: 40px;

  ${media.tablet} {
    margin-top: 30px;
  }
`;

const Title = styled.h2`
  color: ${colorFetch('altblack')};
  font-family: Gilroy600, sans-serif;
  font-size: 48px;
  line-height: 120%;
  letter-spacing: -0.96px;

  ${media.tablet} {
    font-size: 32px;
    letter-spacing: -0.64px;
  }
`;

export const Faq = () => {
  return (
    <ContentWrapper>
      <Container>
        <Title>Часто задаваемые вопросы</Title>
        <FaqListWrapper>
          <CollapsedList data={list} />
        </FaqListWrapper>
      </Container>
    </ContentWrapper>
  );
};

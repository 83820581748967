import { useState } from 'react';
import styled from '@emotion/styled';
import { Box } from '@mui/material';
import { colorFetch, media } from '@styles/index';
import { VideoModal } from '@components/modal/modals/VideoModal';

import gameLineCeoVideo from '@assets/videos/gameLine_ceo_video.mp4';
import bannerDesktop from '@assets/images/about/video_preview.jpg';
import arrowUp from '@assets/images/common/arrow_up.png';
import coinIcon from '@assets/images/about/coin.png';
import chartIcon from '@assets/images/about/chart.png';
import keyIcon from '@assets/images/about/key.png';
import { ReactComponent as PlayButton } from '@assets/images/common/play_button.svg';

const ContentWrapper = styled(Box)`
  margin-top: 40px;

  ${media.tablet} {
    margin-top: 44px;
  }
`;

const TitleWrapper = styled.div`
  ${media.tablet} {
    display: flex;
    align-items: center;
  }
`;

const Container = styled.div`
  max-width: 1180px;
  width: 100%;
  margin: 0 auto;

  ${media.laptop} {
    padding: 0 20px;
  }
`;

const Banner = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PlayButtonStyled = styled(PlayButton)`
  position: absolute;
  width: 100px;
  height: 100px;
  cursor: pointer;
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.1);
  }
  ${media.tablet} {
    width: 70px;
    height: 70px;
    opacity: 0.8;
  }
`;

const Advantages = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 20px;
`;

const AdvantageBox = styled.div<{
  padding?: number;
  flex?: string;
  width?: string;
}>`
  display: flex;
  border-radius: 24px;
  background: #fff;
  width: ${({ width }) => (width ? width : '100%')};
  padding: ${({ padding }) => (padding ? `${padding}px` : '40px')};
  flex: ${({ flex }) => (flex ? flex : '1 1 calc(100% / 3 - 20px)')};
  min-width: 380px;

  svg image:not(.colored) {
    display: none;
  }

  ${media.laptop} {
    flex-direction: column-reverse;
  }

  ${media.tablet} {
    padding: 20px;
    min-width: unset;
    flex: 1 1 100%;

    svg image {
      & {
        display: none;
      }
      &.colored {
        display: block;
      }
    }
  }
`;

const CoinIconStyled = styled.img`
  ${media.tablet} {
    width: 60px;
    height: 60px;
    margin-right: 12px;
  }
`;

const ChartIconStyled = styled.img`
  ${media.tablet} {
    width: 60px;
    height: 60px;
    margin-right: 12px;
  }
`;

const KeyIconStyled = styled.img`
  ${media.tablet} {
    width: 60px;
    height: 60px;
    margin-right: 12px;
  }
`;

const AdvantageArrowUp = styled.div`
  position: relative;
  max-width: 500px;
  width: 100%;

  ${media.laptop} {
    max-width: 100%;
  }

  ${media.tablet} {
    /* top: -54px; */
    margin-top: -58px;
  }
`;

const ArrowUp = styled.img`
  position: absolute;
  top: -92px;
  right: -10px;
  z-index: 2;

  ${media.laptop} {
    max-width: 320px;
  }

  ${media.tablet} {
    position: relative;
    top: 0;
    right: 0px;
    max-width: 100%;
  }
`;

const AdvantageContent = styled.div`
  max-width: 589px;
  width: 100%;

  ${media.laptop} {
    max-width: 100%;
  }
`;

const AdvantageTitle = styled.h3<{
  marginTop?: number;
}>`
  color: ${colorFetch('altblack')};
  font-family: Gilroy600, sans-serif;
  font-size: 32px;
  line-height: 120%;
  letter-spacing: -0.64px;
  margin-top: ${({ marginTop }) => (marginTop ? `${marginTop}px` : '0px')};

  ${media.laptop} {
    max-width: 500px;
  }

  ${media.tablet} {
    color: #35343d;
    font-family: Gilroy600, sans-serif;
    font-size: 24px;
    line-height: 120%;
    letter-spacing: -0.48px;
    margin: 0;
  }
`;

const AdvantageDescription = styled.div<{
  marginTop?: number;
}>`
  color: ${colorFetch('dark_gray')};
  font-family: Gilroy500, sans-serif;
  font-size: 18px;
  line-height: 150%;
  margin-top: ${({ marginTop }) => (marginTop ? `${marginTop}px` : '0px')};

  > p:not(:last-of-type) {
    margin-bottom: 20px;
  }

  ${media.tablet} {
    margin-top: 16px;
    font-family: Gilroy500, sans-serif;
    font-size: 15px;

    &.main-advantage-item {
      margin-top: 20px;
    }
  }
`;

const Excerpt = styled.div`
  margin-top: 32px;
  color: ${colorFetch('altblack')};
  font-family: Gilroy600, sans-serif;
  font-size: 20px;
  line-height: 120%;
  letter-spacing: -0.4px;

  > p:not(:last-of-type) {
    margin-bottom: 20px;
  }

  span:first-child {
    display: block;
  }

  span:last-child {
    color: ${colorFetch('green')};
  }

  ${media.tablet} {
    margin-top: 20px;
    font-family: Gilroy600, sans-serif;
    font-size: 15px;
  }
`;

const BannerStyled = styled.div`
  width: 100%;
  height: 400px;
  background: url(${bannerDesktop}) no-repeat center 38% / cover;
  border-radius: 24px;

  ${media.tablet} {
    height: 220px;
  }
`;

export const About = () => {
  const [isVideoPopupOpen, setIsVideoPopupOpen] = useState<boolean>(false);

  return (
    <ContentWrapper>
      <Container>
        <Banner>
          <BannerStyled />
          <PlayButtonStyled onClick={() => setIsVideoPopupOpen(!isVideoPopupOpen)} />
        </Banner>
        <Advantages>
          <AdvantageBox width="100%" flex="1 1 100%">
            <AdvantageContent>
              <AdvantageTitle>Эксперты нашей платформы прикоснулись к настоящему сокровищу</AdvantageTitle>
              <AdvantageDescription marginTop={32} className="main-advantage-item">
                <p>
                  Редчайший 15-каратный сапфир "Голубая Луна" был куплен нашей компанией за $198 000 на аукционе
                  Christie’s.
                </p>
                <p>
                  Спустя 8 месяцев этот уникальный камень был продан частному коллекционеру из Гонконга за $1 560 000!
                </p>
                <p>
                  Подобные сверхдоходные инвестиции недоступны на традиционных финансовых рынках. А драгоценные камни
                  считаются элитарным активом, недоступным рядовому инвестору.
                </p>
              </AdvantageDescription>
              <Excerpt>
                {/* <span>Таким образом, компания увеличила</span> вложенный в актив капитал в <span>8 раз</span> */}
                <p>Мы решили это изменить.</p>
                <p>
                  GEMLINE делает инвестиции в редчайшие природные сокровища доступными каждому: начните всего с $50 и
                  откройте для себя высокодоходный класс активов!
                </p>
              </Excerpt>
            </AdvantageContent>
            <AdvantageArrowUp>
              <ArrowUp src={arrowUp} />
            </AdvantageArrowUp>
          </AdvantageBox>

          <AdvantageBox padding={32}>
            <AdvantageContent>
              <TitleWrapper>
                <CoinIconStyled src={coinIcon} />
                <AdvantageTitle marginTop={32}>Становись инвестором!</AdvantageTitle>
              </TitleWrapper>
              <AdvantageDescription marginTop={24}>
                Используй возможность инвестировать в редчайшие сокровища планеты — порог вхождения всего $50
              </AdvantageDescription>
            </AdvantageContent>
          </AdvantageBox>

          <AdvantageBox padding={32}>
            <AdvantageContent>
              <TitleWrapper>
                <ChartIconStyled src={chartIcon} />
                <AdvantageTitle marginTop={32}>Гарантия доходности</AdvantageTitle>
              </TitleWrapper>
              <AdvantageDescription marginTop={24}>
                Полный контроль над жизненным циклом камня от сырья до продажи гарантирует выполнение всех обязательств
                перед инвесторами вне зависимости рыночной конъюнктуры
              </AdvantageDescription>
            </AdvantageContent>
          </AdvantageBox>

          <AdvantageBox padding={32}>
            <AdvantageContent>
              <TitleWrapper>
                <KeyIconStyled src={keyIcon} />
                <AdvantageTitle marginTop={32}>Закрытый рынок теперь доступный каждому!</AdvantageTitle>
              </TitleWrapper>
              <AdvantageDescription marginTop={24}>
                Выбирай проверенные временем активы, и наблюдай, как ежедневно растет твой депозит
              </AdvantageDescription>
            </AdvantageContent>
          </AdvantageBox>
        </Advantages>
      </Container>
      <VideoModal
        videoSource={gameLineCeoVideo}
        isModalOpen={isVideoPopupOpen}
        onClose={() => setIsVideoPopupOpen(!isVideoPopupOpen)}
      />
    </ContentWrapper>
  );
};

import React, { FC, useEffect, Dispatch, SetStateAction } from 'react';
import styled from '@emotion/styled';
// import { Box } from '@mui/material';
import { colorFetch, media } from '@styles/index';
import { StyledModal } from '../StyledModal';
import { historiesVectorX } from '@components/Chart/data';
import Swiper from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';

import { ReactComponent as ArrowUp } from '@assets/images/common/fa_arrow_up.svg';

const ContentContainer = styled.div``;

const ChartsModalStyled = styled(StyledModal)`
  .modalContentWrapper {
    width: calc(100% - 40px);
  }
  .modalContentBox {
    padding: 0 20px 20px;
  }
`;

const SwiperSlider = styled.div`
  overflow: hidden;
`;

const SwiperWrapper = styled.div``;

const Slide = styled.div``;

const SliderNavigation = styled.div``;

const NavigationButton = styled.div`
  position: static;
  flex: 0;
  aspect-ratio: 1;
  background: ${colorFetch('graybg')};
  border-radius: 100px;
  width: 64px;
  height: 64px;
  margin: 0;
  z-index: 0;

  ${media.tablet} {
    width: 50px;
    height: 50px;
  }

  &:after {
    display: none;
  }
  &.swiper-button-next {
    svg {
      transform: rotate(180deg);
    }
  }
`;

const SliderNavigationButtons = styled.div`
  display: flex;
  gap: 12px;
  justify-content: space-between;
  margin-top: 20px;
`;

const PrevSlideButton = styled(NavigationButton)``;

const NextSlideButton = styled(NavigationButton)``;

const SliderPageination = styled.div`
  position: static;
  display: flex;
  align-items: center;
  justify-content: center;

  .swiper-pagination-bullet {
    background-color: ${colorFetch('green')};
    opacity: 0.4;

    &.swiper-pagination-bullet-active {
      opacity: 1;
      width: 12px;
      height: 12px;
    }
  }
`;

const ArrowUpStyled = styled(ArrowUp)`
  width: 18px !important;
  height: 20px !important;

  ${media.tablet} {
    width: 14px !important;
    height: 15px !important;
  }
`;

const Text = styled.div`
  color: ${colorFetch('gray1')};
  font-family: Gilroy500;
  font-size: 12px;
`;

interface DataObjectTypr {
  tooltipText: string;
}

interface PropsType {
  isModalOpen: boolean;
  onClose: () => void;
  setHoveredIndex: Dispatch<SetStateAction<number | null>>;
  hoveredIndex: number | null;
  data: DataObjectTypr[];
}

export const ChartsModal: FC<PropsType> = ({ isModalOpen, onClose, setHoveredIndex, data, hoveredIndex, ...props }) => {
  const swiperRef = React.createRef<HTMLDivElement>();

  let swiperInstance: Swiper | null = null;

  const initializeSwiper = () => {
    if (swiperRef.current) {
      swiperInstance = new Swiper(swiperRef.current, {
        slidesPerView: 1,
        spaceBetween: 20,
        loopAdditionalSlides: 1,
        loop: true,
        modules: [Navigation, Pagination],
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        pagination: {
          el: '.modelOfWorkSwiper.swiper-pagination',
          clickable: true,
        },
      });
      swiperInstance.slideTo(hoveredIndex ? hoveredIndex : 0);
    }
  };

  useEffect(() => {
    if (isModalOpen) {
      requestAnimationFrame(initializeSwiper);
    }

    return () => {
      if (swiperInstance) {
        swiperInstance.destroy();
      }
    };
  }, [isModalOpen]);

  return (
    <ChartsModalStyled
      title={hoveredIndex ? historiesVectorX[hoveredIndex] : historiesVectorX[0]}
      open={isModalOpen}
      onClose={onClose}
      {...props}
    >
      <ContentContainer>
        <SwiperSlider className="modelOfWorkSwiper" ref={swiperRef}>
          <SwiperWrapper className="swiper-wrapper">
            {data.map(({ tooltipText }: DataObjectTypr, index: number) => {
              return (
                <Slide className="swiper-slide" key={index}>
                  <Text>{tooltipText}</Text>
                </Slide>
              );
            })}
          </SwiperWrapper>
        </SwiperSlider>

        <SliderNavigation>
          <SliderNavigationButtons>
            <PrevSlideButton className="modelOfWorkSwiper swiper-button-prev">
              <ArrowUpStyled />
            </PrevSlideButton>
            <SliderPageination className="modelOfWorkSwiper swiper-pagination" />
            <NextSlideButton className="modelOfWorkSwiper swiper-button-next">
              <ArrowUpStyled />
            </NextSlideButton>
          </SliderNavigationButtons>
        </SliderNavigation>
      </ContentContainer>
    </ChartsModalStyled>
  );
};

import { useState } from 'react';
import styled from '@emotion/styled';
import { Box, Button } from '@mui/material';
import { colorFetch, media } from '@styles/index';
import { getPath, View } from '@routes/index';
import { useNavigate } from 'react-router-dom';
import { useViewMatch } from '@utils/index';
import { CsValueType } from '@enums/CsValueType';
import { PLATFORM_URL } from 'src/consts';
import { cs } from '@utils/index';

import { ReactComponent as ColoredLogo } from '@assets/images/common/logo/colored_logo_black.svg';
import { ReactComponent as MenuIcon } from '@assets/images/common/mobile_menu.svg';
import { ReactComponent as MobileMenuClose } from '@assets/images/common/mobile_menu_close.svg';

const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1220px;
  width: 100%;
  padding: 16px 20px;
  margin: 0 auto;
  z-index: 4;

  ${media.tablet} {
    justify-content: space-between;
    padding: 10px 20px;
  }
`;

const ColoredLogoStyled = styled(ColoredLogo)`
  cursor: pointer;

  &:hover {
    g > path:last-of-type {
      fill: ${colorFetch('light_green_hover')};
    }
  }

  ${media.laptop} {
    max-width: 130px;
  }
`;

const HeaderWrapper = styled.div`
  height: 88px;
  background-color: ${colorFetch('graybg')};

  ${media.tablet} {
    height: 76px;
  }
`;

const ContentWrapper = styled(Box)`
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  background: rgb(239 238 243 / 50%);
  z-index: 9;

  &:after {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    backdrop-filter: blur(10px);
  }

  ${media.tablet} {
    background-color: rgb(255 255 255 / 50%);

    &:after {
      backdrop-filter: blur(4px);
    }
  }
`;

const HeaderMenu = styled.div`
  display: flex;
  align-items: center;
  margin: 0 auto 0 64px;
  gap: 40px;

  ${media.laptop} {
    margin-left: 20px;
    gap: 20px;
  }

  ${media.tablet} {
    display: none;
  }
`;

const MenuLink = styled.div`
  color: ${colorFetch('altblack')};
  font-family: Gilroy600, sans-serif;
  font-size: 18px;
  cursor: pointer;

  &.active {
    color: ${colorFetch('green')};
  }

  &:hover {
    color: ${colorFetch('light_green_hover')};
  }

  ${media.laptop} {
    font-size: 16px;
  }

  ${media.tablet} {
    font-family: Gilroy500, sans-serif;
    font-size: 24px;
  }
`;

const AuthButtons = styled.div`
  display: flex;
  align-items: center;
  ${media.tablet} {
    display: none;
  }
`;

const SignUpButton = styled(Button)<{
  backgroundColor?: string;
}>`
  max-width: 157px;
  width: 100%;
  display: flex;
  font-family: Gilroy600, sans-serif;
  font-size: 18px;
  line-height: 120%;
  color: ${colorFetch('green')};
  letter-spacing: normal;
  text-transform: none;
  background: rgba(45, 187, 110, 0.15);
  border-radius: 8px;
  padding: 12px 15px;

  &:hover {
    background: ${colorFetch('green_transparent_hover')};
  }

  ${media.laptop} {
    max-width: 150px;
    font-size: 16px;
  }

  ${media.tablet} {
    max-width: unset;
    border-radius: 8px;
    background: ${colorFetch('green')};
    color: ${colorFetch('white')};
  }
`;

const PlatformButton = styled(SignUpButton)`
  max-width: 200px;
`;

const LoginButton = styled(Button)`
  cursor: pointer;
  color: ${colorFetch('green')};
  font-family: Gilroy600, sans-serif;
  font-size: 18px;
  line-height: 120%;
  margin-left: auto;
  margin-right: 24px;
  text-transform: initial;

  &:hover {
    background-color: unset;
    color: ${colorFetch('light_green_hover')};
  }

  ${media.laptop} {
    margin-right: 20px;
    font-size: 16px;
  }

  ${media.tablet} {
    border-radius: 8px;
    background: rgba(45, 187, 110, 0.15);
    padding: 11px 24px;
    color: ${colorFetch('green')};
    text-align: center;
    margin: 0;
  }
`;

const MobileAuthButtons = styled.div`
  display: none;

  ${media.tablet} {
    display: flex;
    justify-content: center;
    gap: 20px;
  }
`;

const SignUpButtonMobile = styled(SignUpButton)`
  flex: 1 1 50%;
`;

const LoginButtonMobile = styled(LoginButton)`
  flex: 1 1 50%;
`;

const MobileMenuButton = styled(MenuIcon)`
  display: none;

  ${media.tablet} {
    display: block;
  }
`;

const MobileMenuCloseButton = styled(MobileMenuClose)`
  display: none;

  ${media.tablet} {
    display: block;
  }
`;

const BurgerMenuWrapper = styled.div`
  display: none;
  position: absolute;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  background: rgba(217, 217, 217, 0.2);
  backdrop-filter: blur(5px);
  z-index: 3;

  ${media.tablet} {
    display: block;
  }
`;

const BurgerMenu = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 0px 0px 20px 20px;
  background: ${colorFetch('white')};
  padding: 102px 20px 20px;
  gap: 40px;
`;

interface menuLinks {
  title: string;
  link: string;
  view: keyof typeof View;
}

const headerMenu: menuLinks[] = [
  {
    title: 'О проекте',
    link: getPath(View.ABOUT_US),
    view: View.ABOUT_US,
  },
  {
    title: 'Инвесторам',
    link: getPath(View.INVESTORS),
    view: View.INVESTORS,
  },
  {
    title: 'Партнерам',
    link: getPath(View.PARTNERS),
    view: View.PARTNERS,
  },
];

export const Header = () => {
  const navigate = useNavigate();
  const matchPath = useViewMatch();
  const UserAuthToken = cs.get(CsValueType.token);
  const UserId = cs.get(CsValueType.user_id);
  const queryParams = new URLSearchParams({ UserId, UserAuthToken }).toString();

  const [burgerMenuOpened, setBurgerMenuOpened] = useState<boolean>();

  return (
    <HeaderWrapper>
      <ContentWrapper>
        <Container>
          <ColoredLogoStyled onClick={() => navigate(getPath(View.HOMEPAGE))} />
          <HeaderMenu>
            {headerMenu.map(({ link, title, view }: menuLinks, index) => (
              <MenuLink key={index} onClick={() => navigate(link)} className={matchPath(view as any) ? 'active' : ''}>
                {title}
              </MenuLink>
            ))}
          </HeaderMenu>
          {burgerMenuOpened ? (
            <MobileMenuCloseButton
              onClick={() => {
                setBurgerMenuOpened(!burgerMenuOpened);
              }}
            />
          ) : (
            <MobileMenuButton
              onClick={() => {
                setBurgerMenuOpened(!burgerMenuOpened);
              }}
            />
          )}
          {!UserAuthToken && !UserId ? (
            <AuthButtons>
              <LoginButton onClick={() => navigate(getPath(View.AUTHENTICATION_LOGIN))} variant="text">
                Вход
              </LoginButton>
              <SignUpButton onClick={() => navigate(getPath(View.AUTHENTICATION_REGISTRATION))}>
                Регистрация
              </SignUpButton>
            </AuthButtons>
          ) : (
            <AuthButtons>
              <PlatformButton href={`${PLATFORM_URL}?${queryParams}`}>В личный кабинет</PlatformButton>
            </AuthButtons>
          )}
        </Container>
        {burgerMenuOpened && (
          <BurgerMenuWrapper className="burgermenuWrapper">
            <BurgerMenu>
              {headerMenu.map(({ link, title, view }: menuLinks, index) => (
                <MenuLink key={index} onClick={() => navigate(link)} className={matchPath(view as any) ? 'active' : ''}>
                  {title}
                </MenuLink>
              ))}
              {!UserAuthToken && !UserId ? (
                <MobileAuthButtons>
                  <LoginButtonMobile onClick={() => navigate(getPath(View.AUTHENTICATION_LOGIN))}>
                    Вход
                  </LoginButtonMobile>
                  <SignUpButtonMobile onClick={() => navigate(getPath(View.AUTHENTICATION_REGISTRATION))}>
                    Регистрация
                  </SignUpButtonMobile>
                </MobileAuthButtons>
              ) : (
                <MobileAuthButtons>
                  <PlatformButton href={`${PLATFORM_URL}?${queryParams}`}>В личный кабинет</PlatformButton>
                </MobileAuthButtons>
              )}
            </BurgerMenu>
          </BurgerMenuWrapper>
        )}
      </ContentWrapper>
    </HeaderWrapper>
  );
};

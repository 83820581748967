import { ReactComponent as ChartIcon } from '@assets/images/common/chart.svg';
import { ReactComponent as BrilliantIcon } from '@assets/images/common/brilliant.svg';
import { ReactComponent as SuitcaseIcon } from '@assets/images/common/suitcase.svg';
import { ReactComponent as CoinsIcon } from '@assets/images/common/coins.svg';
import { ReactComponent as ExpertsIcon } from '@assets/images/common/experts.svg';
import { ReactComponent as InvestingIcon } from '@assets/images/common/investing.svg';

import { ReactComponent as ChartsIcon } from '@assets/images/common/charts.svg';
import { ReactComponent as PartnersNetworkIcon } from '@assets/images/common/partners_network.svg';
import { ReactComponent as GiftBoxGiftBoxIcon } from '@assets/images/common/gift_box.svg';
import { ReactComponent as LaptopChartIcon } from '@assets/images/common/laptop_chart.svg';
import { ReactComponent as TechnologyIcon } from '@assets/images/common/technology.svg';
import { ReactComponent as SupportIcon } from '@assets/images/common/support.svg';

import { ReactComponent as MegaphoneIcon } from '@assets/images/common/megaphone.svg';
import { ReactComponent as UsersNetworkIcon } from '@assets/images/common/users_network.svg';
import { ReactComponent as CashIcon } from '@assets/images/common/cash.svg';
import { ReactComponent as GiftIcon } from '@assets/images/common/gift.svg';
import { ReactComponent as CalendarIcon } from '@assets/images/common/calendar.svg';
import { ReactComponent as AltChartIcon } from '@assets/images/common/alt_chart.svg';

export interface Tile {
  Icon: Object;
  description: string;
}

export const investingBenefitsList: Tile[] = [
  {
    Icon: ChartIcon,
    description: 'Реальный материальный актив, сохраняющий актуальность тысячи лет',
  },
  {
    Icon: BrilliantIcon,
    description: 'Возможность инвестировать в драгоценные камни от $50',
  },
  {
    Icon: SuitcaseIcon,
    description: 'Полный контроль над жизненным циклом камня от сырья до продажи позволяет максимизировать прибыль',
  },
  {
    Icon: CoinsIcon,
    description: 'GEMLINE работает с сырьем, а не готовыми камнями — платформа не зависит от рыночной конъюнктуры',
  },
  {
    Icon: ExpertsIcon,
    description: 'Возможность получить высокую доходность без рисков',
  },
  {
    Icon: InvestingIcon,
    description: 'Возможность внести депозит в USDT (BSC (Binance), ERC20 и TRC20)',
  },
];

export const ourBenefitsList: Tile[] = [
  {
    Icon: ChartsIcon,
    description: 'До 10% от инвестиций привлеченных клиентов',
  },
  {
    Icon: PartnersNetworkIcon,
    description: 'Заработок на партнерской сети по многоуровневой системе',
  },
  {
    Icon: GiftBoxGiftBoxIcon,
    description: 'Бонусы за выполнение плана по привлечению инвестиций',
  },
  {
    Icon: LaptopChartIcon,
    description: 'Полная прозрачность статистики и начисления вознаграждения в личном кабинете',
  },
  {
    Icon: TechnologyIcon,
    description: 'Передовой и востребованный продукт на стыке инвестиций и технологий',
  },
  {
    Icon: SupportIcon,
    description: 'Поддержка профессиональной команды',
  },
];

export const joinUsList: Tile[] = [
  {
    Icon: MegaphoneIcon,
    description: 'Получайте доступ к уникальным промо-материалам',
  },
  {
    Icon: UsersNetworkIcon,
    description: 'Приглашайте клиентов и стройте сеть',
  },
  {
    Icon: CashIcon,
    description: 'Зарабатывайте на каждом привлеченном инвесторе',
  },
  {
    Icon: GiftIcon,
    description: 'Повышайте свой статус и получайте бонусы',
  },
  {
    Icon: CalendarIcon,
    description: 'Организовывайте собственные вебинары и мероприятия',
  },
  {
    Icon: AltChartIcon,
    description: 'Развивайте свои навыки и достигайте новых высот!',
  },
];

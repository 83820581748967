import styled from '@emotion/styled';
import { Box } from '@mui/material';
import { colorFetch, media, theme } from '@styles/index';
import { list } from './data';

const ContentWrapper = styled(Box)`
  margin-top: 120px;
  width: 100%;
  max-width: 1220px;
  margin: 0 auto;
  padding: 0 20px;
`;

const Container = styled.div`
  margin-top: 120px;

  ${media.tablet} {
    margin-top: 80px;
  }
`;

const Title = styled.h2`
  color: ${colorFetch('altblack')};
  font-family: Gilroy600, sans-serif;
  font-size: 48px;
  font-style: normal;
  line-height: 120%;
  letter-spacing: -0.96px;

  ${media.tablet} {
    font-size: 32px;
    letter-spacing: -0.64px;
  }
`;

const NumberedTiles = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 40px;

  ${media.tablet} {
    margin-top: 30px;
    gap: 12px;
  }
`;

const Tile = styled.div`
  position: relative;
  min-height: 220px;
  flex: 0 1 calc(100% / 3 - (20px * 2 / 3));
  border-radius: 24px;
  background: ${colorFetch('graybg')};
  box-shadow: 0px -2px 4px 0px ${colorFetch('greyShadow')} inset;
  padding: 32px;
  min-width: 300px;

  ${media.laptop} {
    flex: 1 1 calc(100% / 3 - (20px * 2 / 3));
  }

  ${media.tablet} {
    padding: 20px;
    min-height: auto;
    flex: 1 1 calc(100% / 3 - (20px * 2 / 3));
  }
`;

const TileIndex = styled.div`
  position: relative;
  color: ${colorFetch('green')};
  font-family: Gilroy600, sans-serif;
  font-size: 32px;
  line-height: 120%;
  letter-spacing: -0.64px;

  ${media.tablet} {
    font-size: 24px;
    letter-spacing: -0.48px;
  }
`;

const TileDescription = styled.div`
  position: relative;
  color: ${colorFetch('altblack')};
  font-family: Gilroy600, sans-serif;
  font-size: 20px;
  line-height: 140%;
  letter-spacing: -0.4px;
  margin-top: 34px;

  ${media.tablet} {
    margin-top: 12px;
    font-size: 18px;
    letter-spacing: -0.36px;
  }
`;

const TileDekorIndex = styled.div`
  position: absolute;
  color: #e8e6f0;
  font-family: Gilroy700, sans-serif;
  font-size: 120px;
  font-weight: 700;
  letter-spacing: -2.4px;
  top: -20px;
  right: 13px;

  ${media.tablet} {
    font-size: 80px;
    letter-spacing: -1.6px;
    top: -4px;
  }
`;

export const HowItWorks = () => {
  return (
    <ContentWrapper>
      <Container>
        <Title>Как работает GEMLINE</Title>
        <NumberedTiles>
          {list.map((el: string, index: number) => {
            return (
              <Tile key={index}>
                <TileDekorIndex>{index < 10 ? `0${++index}` : ++index}</TileDekorIndex>
                <TileIndex>{index < 10 ? `0${index}` : index}</TileIndex>
                <TileDescription dangerouslySetInnerHTML={{ __html: el }} />
              </Tile>
            );
          })}
        </NumberedTiles>
      </Container>
    </ContentWrapper>
  );
};

import { FC } from 'react';
import styled from '@emotion/styled';
import { Box } from '@mui/material';
import { colorFetch, media } from '@styles/index';
// import { OfferBox } from '@components/offerBox/OfferBox';
import { GreenTextBox } from '@components/greenTextBox/GreenTextBox';

// import investingInGem from '@assets/images/about_us/investing_in_gem.png';
// import gemRisingPrices from '@assets/images/about_us/gem_rising_prices.png';
import { BoxContainer } from '@components/boxContainer/BoxContainer';
import { GridBoxes } from '@components/gridBoxes/GridBoxes';
import { Cards } from '@components/cards/Cards';

import documentsImage from '@assets/images/common/documents.png';

const ContentWrapper = styled(Box)`
  width: 100%;
  max-width: 1220px;
  margin: 120px auto 0;
  padding: 0 20px;

  ${media.tablet} {
    margin-top: 60px;
  }
`;

const Container = styled.div``;

const Row = styled.div`
  &.col-2 {
    display: flex;
    flex-wrap: wrap;
    gap: 0 40px;

    > * {
      flex: 1 0 calc(100% / 2 - 40px);
    }
    ${media.tablet} {
      > * {
        flex: 1 0 100%;
      }
    }
  }
`;

const Title = styled.h2`
  color: ${colorFetch('black')};
  font-family: Gilroy600, sans-serif;
  font-size: 48px;
  line-height: 120%;
  letter-spacing: -0.96px;

  ${media.tablet} {
    font-size: 32px;
    letter-spacing: -0.64px;
  }
`;

const Description = styled.div`
  max-width: 980px;
  color: ${colorFetch('light_text_grey')};
  font-family: Gilroy500, sans-serif;
  font-size: 18px;
  line-height: 150%;
  margin-top: 32px;

  > p:not(:last-child) {
    margin-bottom: 15px;
  }

  ${media.tablet} {
    font-size: 15px;
    margin-top: 20px;
  }
`;

const BoxContainerStyled = styled(BoxContainer)`
  padding: 0;
  margin-top: 40px;

  ${media.tablet} {
    margin-top: 30px;
  }
`;

const SnippetText = styled.div`
  color: ${colorFetch('gray2')};
  font-family: Gilroy500, sans-serif;
  font-size: 18px;
  line-height: 150%;
  margin-top: 32px;

  > p:not(:last-of-type) {
    margin-bottom: 15px;
  }

  ${media.tablet} {
    margin-top: 20px;
    font-size: 15px;
  }
`;

const GridBoxesStyled = styled(GridBoxes)`
  margin-top: 24px;
`;

const CardsStyled = styled(Cards)`
  padding: 0;
  margin-top: 40px;
  /* .cardsWrapper {
    background-color: ${colorFetch('gray2')};
  } */
  .card {
    background-color: ${colorFetch('graybg')};
    padding: 40px;
    border-radius: 24px;

    .description {
      p:nth-last-of-type(-n + 2) {
        color: ${colorFetch('black')};
        font-family: Gilroy600, sans-serif;
        font-size: 20px;
        line-height: 130%;
        letter-spacing: -0.4px;
      }
    }
  }
  ${media.tablet} {
    .card {
      padding: 20px;

      .description {
        p:nth-last-of-type(-n + 2) {
          font-size: 18px;
        }
      }

      .imageWrapper {
        max-width: 80px;
        min-width: unset;

        img {
          max-width: unset;
        }
      }
    }
  }
`;

interface ListProps {
  image: string | Object;
  text: string;
}

interface DataProps {
  list: ListProps[];
}

export const OperationalActivities: FC<DataProps> = ({ list }) => {
  return (
    <ContentWrapper>
      <Container>
        <Title>Организация операционной деятельности</Title>
        <Description>
          <p>
            GEMLINE использует распределенную структуру из юридических лиц, зарегистрированных в различных юрисдикциях
            по всему миру.
          </p>
          <p>
            Это позволяет эффективно организовать работу с учетом  особенностей местного законодательства, налоговых
            и таможенных норм, условиями ведения и лицензирования бизнеса.
          </p>
        </Description>
        <BoxContainerStyled
          title="Четкое разделение функций"
          text="Разные юридические лица из структуры GEMLINE созданы для легального ведения деятельности на конкретных этапах работы:"
          backgroundColor="graybg"
          className="smallTitle functionsSeparation"
        >
          <GridBoxesStyled data={list} className="functionsSeparation" />
        </BoxContainerStyled>
        <Row className="col-2">
          <BoxContainerStyled backgroundColor="graybg" title="Взаимодействие с партнерами" className="snippet">
            <SnippetText>
              Наличие отдельных юрлиц в каждом из регионов упрощает сотрудничество. Для них удобнее сотрудничать с
              компанией, которая зарегистрирована и работает в рамках местного законодательства.
            </SnippetText>
          </BoxContainerStyled>
          <BoxContainerStyled backgroundColor="graybg" title="Снижение рисков" className="snippet">
            <SnippetText>Распределенная структура минимизирует юридические и налоговые риски.</SnippetText>
          </BoxContainerStyled>
        </Row>
        <Row>
          <BoxContainerStyled backgroundColor="graybg" title="Обеспечение прозрачности" className="snippet">
            <SnippetText>
              <p>
                Главное преимущество — это возможность вести документацию и обеспечивать полную легальность камней на
                всех этапах работы. Это исключает риски интерпретации камней как контрабанды.
              </p>
              <p>
                Распределенная структура юрлиц позволяет GEMLINE эффективно функционировать в рамках правового поля во
                всех юрисдикциях.
              </p>
            </SnippetText>
          </BoxContainerStyled>
        </Row>
        <Row>
          <CardsStyled
            cardTheme="white"
            rowreverse={true}
            className="operationalActivities"
            list={[
              {
                icon: documentsImage,
                title:
                  'Организация операционной деятельности GEMLINE — многоуровневая система бизнес-процессов, интегрирующая работу на всех этапах работы с жизненным циклом камней в единый механизм',
                description:
                  '<p>Мы вынуждены воздержаться от раскрытия всех тонкостей того, каким образом выстроена работа GEMLINE в разных юрисдикциях.</p><p>Эти сведения представляют собой коммерческую тайну, раскрытие которой может нанести ущерб конкурентным позициям компании.</p><p>Мы лишь в общих чертах обрисовали ключевые принципы, чтобы продемонстрировать строгое следование правовым нормам и прозрачность бизнеса.</p><p>Более детальное раскрытие внутренних бизнес-процессов не представляется возможным в силу их конфиденциальности.</p>',
              },
            ]}
          />
        </Row>
      </Container>
    </ContentWrapper>
  );
};

import { FC } from 'react';
// import styled from '@emotion/styled';
import styled from 'styled-components';
import { Box } from '@mui/material';
import { colorFetch, media } from '@styles/index';

const ContentWrapper = styled(Box)`
  width: 100%;
  max-width: 1220px;
  margin: 100px auto 0;
  padding: 0 20px;

  .attention {
    display: inline-block;
    background-color: ${colorFetch('red')};
    color: ${colorFetch('white')};
    font-family: Gilroy600, sans-serif;
    font-size: 20px;
    line-height: 120%;
    letter-spacing: -0.4px;
    padding: 2px 5px;
    border-radius: 4px;

    ${media.tablet} {
      font-size: 15px;
    }
  }

  &.businessModel {
    margin-top: 60px;
    padding: 0;

    .image {
      max-width: 580px;
    }
  }

  &.investmentPortfolio {
    margin: 40px auto 0;
    padding: 0;

    .card:nth-of-type(2) {
      .image {
        max-width: 233px;
      }
    }
  }

  &.gray {
    .cardsWrapper {
      border-radius: 24px;
      background: ${colorFetch('graybg')};
      box-shadow: 0px -2px 4px 0px #e8e6ef inset;
      padding: 40px;
    }
    .imageWrapper {
      justify-content: flex-end;

      img {
        width: 100%;
        max-width: 180px;
      }
    }

    ${media.tablet} {
      .cardsWrapper {
        padding: 20px;
      }
      .card {
        gap: 16px;
        align-items: flex-start;
      }
      .imageWrapper {
        justify-content: flex-start;
        img {
          width: 100%;
          max-width: 100px;
        }
      }
      .description {
        margin-top: 16px;
      }
    }
  }

  &.operationalActivities {
    .description {
      max-width: 600px;
    }
  }

  &.globalActivities {
    padding: 0;

    .card {
      padding: 40px;
      background-color: ${colorFetch('graybg')};

      .title {
        max-width: 540px;
      }

      .description {
        color: ${colorFetch('black')};
        font-family: Gilroy600, sans-serif;
        font-size: 20px;
        line-height: 150%;
      }

      .imageWrapper {
        flex: 0 1 353px;
        margin-top: -76px;
        margin-right: 38px;

        img {
          max-width: unset;
        }
      }
    }
    ${media.laptop} {
      .card {
        .imageWrapper {
          margin-right: 0;
        }
      }
    }
    ${media.tablet} {
      margin-top: 100px;

      .card {
        .description {
          font-size: 18px;
        }
      }
    }
  }
  &.stableCoin {
    padding: 0;

    .card {
      gap: 40px;
      justify-content: space-between;
      background-color: unset;
      padding: 0;

      .attention {
        margin-top: 10px;
      }

      .description {
        p {
          &:last-of-type {
            color: ${colorFetch('black')};
            font-family: Gilroy500, sans-serif;
            font-size: 20px;
            line-height: 130%;
            letter-spacing: -0.4px;
            margin-top: 24px;
          }
        }
      }

      .content {
        max-width: 640px;
      }

      .imageWrapper {
        flex: 0 1 300px;
        margin-left: 40px;

        img {
          max-width: unset;
        }
      }
    }

    ${media.tablet} {
      .card {
        gap: 20px;

        .imageWrapper {
          flex: 1;
          max-width: 80px;
          min-width: unset;
          margin: 0 auto 0 0;
        }
        .description {
          p {
            &:last-of-type {
              font-size: 18px;
              margin-top: 18px;
            }
          }
        }
      }
    }
  }

  ${media.tablet} {
    margin-top: 60px;

    &.businessModel {
      margin-top: 30px;

      .card {
        gap: 12px 0;
      }

      .description {
        margin-top: 12px;
      }
    }
  }
`;

const CardsWrapper = styled.div<{
  theme?: string;
}>`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => (theme === 'white' ? '40px 0' : '60px 0')};

  ${media.tablet} {
    gap: 30px 0;
  }
`;

const Card = styled.div<{
  theme?: string;
  rowreverse: boolean;
}>`
  display: flex;
  align-items: center;
  gap: 20px;
  flex-direction: ${({ rowreverse }) => (rowreverse ? 'row-reverse' : 'row')};

  > * {
    flex: auto;
  }

  ${media.tablet} {
    flex-direction: column;
    gap: 20px;
  }

  &:first-of-type {
    margin-top: 0;
  }

  &:nth-of-type(even) {
    flex-direction: row-reverse;
    flex-direction: ${({ rowreverse }) => (rowreverse ? 'row' : 'row-reverse')};

    ${media.tablet} {
      flex-direction: column;
    }
  }

  ${({ theme }) =>
    theme === 'white' &&
    `
      padding: 28px 40px;
      background-color: ${colorFetch('white')};
      border-radius: 30px;
      ${media.tablet} {
        padding: 20px;
      }

      ${media.tablet} {
        ${ImageWrapper}{
          max-width: 120px;
          margin-right: auto;
        }
      }
  `}

  ${({ theme }) =>
    theme === 'gray' &&
    `
      padding: 40px;
      background-color: ${colorFetch('graybg')};
      box-shadow: 0px -2px 4px 0px ${colorFetch('greyShadow')} inset;
      border-radius: 24px;

      ${media.tablet} {
        padding: 20px;
        
        ${ImageWrapper}{
          max-width: 120px;
          margin-right: auto;
        }
      }
  `}
`;

const Image = styled.img`
  width: 100%;
  max-width: 320px;

  ${media.tablet} {
    width: 100%;
  }
`;

const ImageWrapper = styled.div<{
  imageKeppBottom: boolean;
}>`
  display: flex;
  justify-content: center;
  width: 100%;
  min-width: 180px;

  ${({ imageKeppBottom }) =>
    imageKeppBottom === true &&
    `
      margin-bottom: -28px;
        ${media.tablet} {
          margin-bottom: 0;
        }
    `}
`;

const Content = styled.div<{
  contentmaxwidth?: string;
}>`
  max-width: ${({ contentmaxwidth }) => (contentmaxwidth ? contentmaxwidth : 'unset')};
`;

const Title = styled.h3`
  color: ${colorFetch('altblack')};
  font-family: Gilroy600, sans-serif;
  font-size: 32px;
  line-height: 110%;
  letter-spacing: -0.64px;

  ${media.tablet} {
    font-size: 24px;
    letter-spacing: -0.48px;
  }
`;

const Description = styled.div`
  max-width: 700px;
  color: ${colorFetch('light_text_grey')};
  font-family: Gilroy500, sans-serif;
  font-size: 18px;
  line-height: 150%;
  margin-top: 32px;

  > p:not(:last-of-type) {
    margin-bottom: 15px;
  }

  ${media.tablet} {
    font-size: 15px;
    margin-top: 20px;
  }
`;

const Container = styled.div``;

interface Card {
  icon: string;
  title: string;
  description: string;
}

interface PropsType {
  cardTheme?: string;
  className?: string;
  rowreverse?: boolean;
  imageKeppBottom?: boolean;
  contentmaxwidth?: string;
  list: Object;
}

export const Cards: FC<PropsType> = ({ cardTheme, rowreverse, imageKeppBottom, contentmaxwidth, list, ...props }) => {
  return (
    <ContentWrapper {...props}>
      <Container>
        <CardsWrapper theme={cardTheme} className="cardsWrapper">
          {Object.values(list).map(({ icon, title, description }: Card, index: number) => {
            return (
              <Card theme={cardTheme} key={index} rowreverse={!!rowreverse} className="card">
                <ImageWrapper imageKeppBottom={!!imageKeppBottom} className="imageWrapper">
                  <Image src={icon} className="image" />
                </ImageWrapper>
                <Content contentmaxwidth={contentmaxwidth} className="content">
                  <Title className="title">{title}</Title>
                  <Description dangerouslySetInnerHTML={{ __html: description }} className="description" />
                </Content>
              </Card>
            );
          })}
        </CardsWrapper>
      </Container>
    </ContentWrapper>
  );
};

import { FC } from 'react';
import { colorFetch, media } from '@styles/index';
import { Helmet } from 'react-helmet';
import { Header } from '@components/header/Header';
import { Intro } from '@components/intro/Intro';
import { OfferBox } from '@components/offerBox/OfferBox';
import { Benefits } from '@components/benefits/benefits';
import { Mission } from '@components/mission/mission';
// import { Slider } from '@components/slider/Slider';
import { AffiliateProgram } from '@components/affiliateProgram/AffiliateProgram';
import { AffiliateTable } from '@components/affiliateTable/AffiliateTable';
import { Ranks } from '@components/ranks/Ranks';
import { CallToActionLine } from '@components/CallToActionLine/CallToActionLine';
import { Footer } from '@components/index';
// import styled from '@emotion/styled';
import styled from 'styled-components';
import { getPath, View } from '@routes/index';
import { useNavigate } from 'react-router-dom';

import { helpList } from './data';

import image from '@assets/images/intro/gem_dna.png';

const Wrapper = styled.div``;

const OfferBoxStyled = styled(OfferBox)`
  & {
    margin-top: 120px;

    .excerpt {
      max-width: 440px;
    }

    ${media.tablet} {
      margin-top: 60px;

      .excerpt {
        max-width: 220px;
      }
    }
  }
`;

const ContentWrapper = styled.div``;

const Container = styled.div``;

const GreyWrapper = styled.div`
  background-color: ${colorFetch('graybg')};
`;

const BenefitsStyled = styled(Benefits)`
  .title,
  .description {
    max-width: 540px;
  }
`;

interface PartnersProps {}

export const Partners: FC<PartnersProps> = ({}) => {
  const navigate = useNavigate();

  return (
    <>
      <Helmet>
        <title>Партнерская программа GEMLINE</title>
        <meta
          name="description"
          content="🔹 Присоединяйтесь к партнерской программе GEMLINE 🤝 и зарабатывайте на привлечении инвесторов 💰 в наш инновационный проект!"
        />
      </Helmet>
      <Wrapper>
        <ContentWrapper>
          <Container>
            <Header />
            <GreyWrapper>
              <Intro
                className="partners"
                title="<span>Присоединяйтесь</span> к нашей партнерской программе"
                description="<span>И зарабатывайте на продвижении</span> инновационного инвестиционного продукта!"
                image={image}
              />
            </GreyWrapper>
            <OfferBoxStyled
              contentmaxwidth="640px"
              imagekeepbottom={true}
              title="Мы предлагаем выгодные условия сотрудничества"
              description="<p>GEMLINE — инновационная компания, которая открыла доступ к высокодоходным инвестициям в редчайшие сокровища планеты.</p><p>Мы приглашаем партнеров присоединиться к нам и зарабатывать на привлечении новых инвесторов.</p>"
              excerpt="Это уникальная возможность получить <span>стабильный доход</span> от быстрорастущего рынка драгоценных камней!"
            />
            <BenefitsStyled
              type="join-us"
              title="Присоединяйтесь к команде GEMLINE!"
              description="Зарегистрируйтесь в качестве партнера уже сегодня и начните строить свою прибыльную сеть!"
            />
            <Mission list={helpList} title="Маркетинговая команда поможет:" />
            {/* <Slider /> */}
            <GreyWrapper>
              <AffiliateProgram />
              <AffiliateTable />
              <Ranks />
              <CallToActionLine
                containerwrapper={true}
                title="Присоединяйтесь к растущему сообществу партнеров GEMLINE!"
                description="Вместе мы сделаем инвестиции в драгоценные камни по-настоящему инклюзивными!"
                buttonLabel="Регистрация"
                ctaTitle="Присоединяйтесь!"
                onClick={() => navigate(getPath(View.AUTHENTICATION_REGISTRATION))}
              />
              <Footer />
            </GreyWrapper>
          </Container>
        </ContentWrapper>
      </Wrapper>
    </>
  );
};
